export default class WakilModel {
  status;
  cin; //
  firstNameAr;
  lastNameAr;
  fatherName; //
  email; //
  grandfatherName;
  grandfatherNameAr;
  motherFirstName;
  motherLastName;
  phoneNumber;
  documentNumber;
  governate;
  city;
  isPaid;
  confirmationDate;
  passportNumber; //
  documentConfirmation;
  flightNumber;
  firstName; //
  lastName; //
  birthDate; //
  gender;
  mrz; //

  embassyIndex;
  login; //
  password; //
  codeMoufa;
  moufaDate;
  errorCode;
  errorMessage;
  birthCity; //
  passportDeliveryDate; //
  passportExpirationDate;
  maritalStatusCode;

  passportDeliveryCity; //
  arrivalDate; //
  job = "nothing"; //
  groupName;
  embassy;
  nationality = "216";
  birthCountry = "216";
  addressCountry = "216";
  PPTypeCode;
}
