import { Input, Message, SelectPicker } from "rsuite";

function AddEdit({ _setmodel, error, model }) {
  return (
    <>
      <label>Nom:</label>
      <Input
        onChange={(name) => {
          _setmodel((prev) => {
            return { ...prev, name };
          });
        }}
        value={model.name}
      />
      {/* <label>Email Commun :</label>
      <Input
        type="email"
        value={model.commonEmail}
        onChange={(commonEmail) => {
          _setmodel((prev) => {
            return { ...prev, commonEmail };
          });
        }}
      />
      <label>Tél Commun</label>
      <Input
        type="phone"
        value={model.commonPhone}
        onChange={(commonPhone) => {
          _setmodel((prev) => {
            return { ...prev, commonPhone };
          });
        }}
      /> */}
      <label>Date d'arrivé :</label>
      <Input
        type="date"
        value={
          typeof model.dateArrival == "string"
            ? model.dateArrival.substring(0, 10)
            : model.dateArrival
        }
        onChange={(dateArrival) => {
          _setmodel((prev) => {
            return { ...prev, dateArrival };
          });
        }}
      />

      <label>Date de retour :</label>
      <Input
        type="date"
        value={
          typeof model.departDate == "string"
            ? model.departDate.substring(0, 10)
            : model.departDate
        }
        onChange={(departDate) => {
          _setmodel((prev) => {
            return { ...prev, departDate };
          });
        }}
      />
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
