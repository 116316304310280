import React from "react";

function SettingInvoice() {
  return <div>

    
  </div>;
}

export default SettingInvoice;
