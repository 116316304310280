import { useRecoilState, useRecoilValue } from "recoil";
import { PackageUmrahAtom } from "../../Atoms/packageUmrah.atom";
import { Edit } from "@rsuite/icons";
import TrashIcon from "@rsuite/icons/Trash";
import { DisponibilityUmrahModel } from "../../Models/packageUmrahModel";
import Responsive from "../../Components/Responsive";
import { Divider, IconButton, Input, SelectPicker } from "rsuite";
import { Airlines } from "../../Atoms/airlines.atom";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import { useEffect } from "react";

const Disponibilities = () => {
  const [model, setmodel] = useRecoilState(PackageUmrahAtom);
  const airlines = useRecoilValue(Airlines);
  useEffect(() => {
    setmodel((prev) => {
      let _prev = { ...prev };
      _prev.disponibilities = _prev.disponibilities.slice().sort((a, b) => {
        if (a && !b) return -1;

        return new Date(a.date) >= new Date(b.date) ? 1 : -1;
      });
      return _prev;
    });
  }, [model.id]);
  return (
    <>
      <h6>Vols </h6>
      <div>
        <label>Tarif Vol </label>
        {model.packageUmrahCosts.find(
          (el) => el.designation.toUpperCase() == "VOL"
        ) && (
          <Input
            value={
              model.packageUmrahCosts.find(
                (el) => el.designation.toUpperCase() == "VOL"
              ).cost
            }
            onChange={(cost) => {
              let index = model.packageUmrahCosts.findIndex(
                (el) => el.designation.toUpperCase() == "VOL"
              );
              setmodel((prev) => {
                let packageUmrahCosts = [...model.packageUmrahCosts];
                packageUmrahCosts[index] = {
                  ...packageUmrahCosts[index],
                  cost: parseFloat(cost),
                };
                return { ...prev, packageUmrahCosts };
              });
            }}
          />
        )}
      </div>
      {(!model.disponibilities || !model.disponibilities.length )&& <div><IconButton
                  onClick={() => {
                 
                    setmodel({
                      ...model,
                      disponibilities:[{
                        ...new DisponibilityUmrahModel(),
                        commission: model.commission,
                        b2Bcommission: model.b2Bcommission,
                      }],
                    });
                  }}
                  color="violet"
                  icon={<PlusRoundIcon />}
                >ajouter</IconButton></div>}
      {model.disponibilities &&
        model.disponibilities.map((d, i) => (
          <>
            {" "}
            <Responsive className="p-10" m={6} l={2} xl={2}>
              <label>Date: </label>
              <Input
                type="date"
                onChange={(date) =>
                  setmodel((pr) => {
                    let disponibilities = [...pr.disponibilities];
                    disponibilities[i] = { ...disponibilities[i], date };
                    return { ...pr, disponibilities };
                  })
                }
                value={
                  typeof d.date == "string" ? d.date.substring(0, 10) : d.date
                }
              ></Input>
            </Responsive>
            <Responsive s={2} m={1} l={0.5} xl={0.5} className="p-10">
              {" "}
              <br></br>
              {d.airlineId && (
                <img
                  alt=""
                  style={{ width: "50px" }}
                  src={
                    airlines.find((ai) => ai.id == d.airlineId) &&
                    airlines.find((ai) => ai.id == d.airlineId).logo
                  }
                ></img>
              )}{" "}
            </Responsive>
            <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
              <label>Airline : </label>
              <SelectPicker
                data={airlines.map((c) => {
                  return { label: c.name, value: c.id };
                })}
                block
                value={d.airlineId}
                onSelect={(airlineId) => {
                  setmodel((pr) => {
                    let disponibilities = [...pr.disponibilities];
                    disponibilities[i] = { ...disponibilities[i], airlineId };
                    return { ...pr, disponibilities };
                  });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
              <label>Nombre :</label>
              <Input
                type="number"
                step="1"
                value={d.onStock}
                onChange={(onStock) =>
                  setmodel((pr) => {
                    let disponibilities = [...pr.disponibilities];
                    disponibilities[i] = {
                      ...disponibilities[i],
                      onStock: parseInt(onStock),
                    };
                    return { ...pr, disponibilities };
                  })
                }
              />
            </Responsive>
            <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
              <label>Cout :</label>
              <Input
                type="number"
                step="0.1"
                value={d.cost}
                onChange={(cost) =>
                  setmodel((pr) => {
                    let disponibilities = [...pr.disponibilities];
                    disponibilities[i] = {
                      ...disponibilities[i],
                      cost: parseFloat(cost),
                    };
                    return { ...pr, disponibilities };
                  })
                }
              />
            </Responsive>
            <Responsive s={4} m={3} l={2} xl={2} className="p-10">
              <label>Commission : </label>
              <Input
                type="number"
                step="1"
                value={d.commission}
                onChange={(commission) => {
                  setmodel((pr) => {
                    let disponibilities = [...pr.disponibilities];
                    disponibilities[i] = {
                      ...disponibilities[i],
                      commission: parseFloat(commission),
                    };
                    return { ...pr, disponibilities };
                  });
                }}
              />
            </Responsive>
            <Responsive s={4} m={3} l={2} xl={2} className="p-10">
              <label>Commission B2B : </label>
              <Input
                type="number"
                step="1"
                value={d.b2Bcommission}
                onChange={(b2Bcommission) => {
                  setmodel((pr) => {
                    let disponibilities = [...pr.disponibilities];
                    disponibilities[i] = {
                      ...disponibilities[i],
                      b2Bcommission: parseFloat(b2Bcommission),
                    };
                    return { ...pr, disponibilities };
                  });
                }}
              />
            </Responsive>
            <Responsive s={2} m={1.6} l={1} xl={1}>
              <div style={{ height: "42px" }}> </div>
              <IconButton
                onClick={() => {
                  let disponibilities = [...model.disponibilities];
                  disponibilities = disponibilities.filter((_, j) => j != i);
                  setmodel({
                    ...model,
                    disponibilities,
                  });
                }}
                color="violet"
                icon={<TrashIcon />}
              ></IconButton>

              {i + 1 == model.disponibilities.length && (
                <IconButton
                  onClick={() => {
                    let disponibilities = [...model.disponibilities];
                    disponibilities.push({
                      ...new DisponibilityUmrahModel(),
                      commission: model.commission,
                      b2Bcommission: model.b2Bcommission,
                    });
                    setmodel({
                      ...model,
                      disponibilities,
                    });
                  }}
                  color="violet"
                  icon={<PlusRoundIcon />}
                ></IconButton>
              )}
            </Responsive>
            <hr></hr>
          </>
        ))}
      <hr />
    </>
  );
};
export default Disponibilities;
