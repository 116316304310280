import { CustomerVoModel } from "./SaleVoModel";

export default class TicketingModel {
  date = new Date();
  clientId;
  customer = new CustomerVoModel();
  totalPrice = 0;
  totalRefund = 0;
  commission = 0;
  fare = 0;
  note = "";
  ref = "";
  tag;
  clientName;
  clientCode;
  ticket = new TicketPostModel();
}
export class TicketPostModel {
  ticketNumber = "";
  pnr = "";
  emissionDate;
  note = "";
  refundDate;
  fare;
  tax;
  tranc = "TKTT";
  totalDoc = 0;
  refundTax = 0;
  commission = 0;
  emd = 0;
  emdNumber;
  serviceFeesTVA = 0;
  serviceFees = 0;
  riz = "";
  airlineId;
  totalRefund = 0;
  taxRefund = 0;
  commissionRefund = 0;
  refundPenalty = 0;
  airCreationDate;

  segments = []; //
}
export class Segment {
  departCity;
  departCountry;
  departAirport;
  departDate;
  arrivalCity;
  arrivalCountry;
  arrivalDate;
  arrivalAirport;

  flightNumber;

  class;
  bagages;

  duration;
}
