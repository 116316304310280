import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { Input, Pagination } from "rsuite";
import { APi } from "../../Api";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";

export default function AccountsFollowUp({ noHead = false }) {
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    clientId: 0,
    date: null,
  });
  const [state, setstate] = useRecoilState(exportAddAtom);
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(
      APi.ENDPOINTS.Ledger,
      {
        ...filterModel,
        date: filterModel.date
          ? moment(filterModel.date).format("DD MMM YYYY")
          : null,
      },
      "/GetAccountFollwoUp"
    )
      .customGet()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {});
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      {!noHead && (
        <>
          {" "}
          <Filter search={() => fetch()}>
            {/* <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Date du dépense: </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive> */}
          </Filter>
          <ExportAdd
            // excelData={multiDataSet(data)}
            nameExcel="FollowUp"
            noAdd
          />
        </>
      )}
      <Grid
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}
const columns = [
  {
    value: "account",
    name: "Client",
    render: (v) => {
      return (
        <div
          style={{
            maxWidth: "260px",
            padding: "10px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "#4667d1",
            fontSize: "16px",
            textTransform: "upperCase",
            fontWeight: "500",
          }}
        >
          {v.name}
        </div>
      );
    },
  },
  {
    name: "Mois Avant Précédent",
    value: "beforePreviousBalance",
    render: (v) => (
      <b style={{ color: v >= 0 ? "green" : "#e33", fontSize: "18px" }}>
        {v ? v.toFixed(3) + " " : 0}
      </b>
    ),
  },
  {
    value: "previousBalance",
    name: "Mois Précédent",
    render: (v) => (
      <b style={{ color: v >= 0 ? "green" : "#e33", fontSize: "18px" }}>
        {v ? v.toFixed(3) + " " : 0}
      </b>
    ),
  },

  {
    value: "actualBalance",
    name: "Ce Mois",
    render: (v) => (
      <b style={{ color: v >= 0 ? "green" : "#e33", fontSize: "18px" }}>
        {v ? v.toFixed(3) + " " : 0}
      </b>
    ),
  },
  //   {
  //     value: "account",
  //     name: "Actions",
  //     render: (v) => (
  //       <b style={{ color: v >= 0 ? "green" : "#e33", fontSize: "18px" }}>
  //         {v ? v.toFixed(3) + " " : 0}
  //       </b>
  //     ),
  //   },
];
