import { CustomerVoModel } from "./SaleVoModel";

export default class SaleBusModel {
  date = new Date();
  clientId;
  providerId;
  customer = new CustomerVoModel();
  totalPrice = 0;
  totalRefund = 0;
  commission = 0;
  fare = 0;
  commissionPercent = 0;
  note = "";
  service = 4;
  ref = "";
  tag;
  bus = new BusModel();
}
export class BusModel {
  items = [new BusItemModel()]; // array of BusItemModel
}
export class BusItemModel {
  departDate = new Date();
  arrivalDate = new Date();
  departCity = "";
  arrivalCity = "";
  driverName = "";
}
