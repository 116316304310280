export class CheckModel {
  number = 0;
  status = 1;
  amount = 0;
  depositDate = new Date();
  dueDate = new Date();
  //   date = new Date();
  depositBankId = null;
  emmissionBankId = null;
  notes = "";
  CheckPurpose = 1;
  clientId = null;
  providerId = null;
  PaymentEventType = 1;
  attachments = [];
  recoveryPointId=null

  // constructor(
  //   number,
  //   status,
  //   amount,
  //   depositDate,
  //   // date,
  //   depositBankId,
  //   emmissionBankId,
  //   checkPurpose,
  //   notes,
  //   clientId
  // ) {
  //   this.number = number;
  //   this.status = status;
  //   this.amount = amount;
  //   this.depositDate = depositDate;
  //   // this.date = date;
  //   this.depositBankId = depositBankId;
  //   this.emmissionBankId = emmissionBankId;
  //   this.notes = notes;
  //   this.checkPurpose = checkPurpose;
  //   this.clientId = clientId;
  // }
}
