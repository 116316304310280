export default class ForcastModel {
  optionDate;
  prices = [];
  disponibilityId;
  clients = []; //[ClientForcast]
  note = "";
  packageUmrahId;
}
export class ClientForcast {
  clientId;
  nbr = 0;
}
export class ForcastVoModel {
  optionDate;
  disponibilityId;
  clients = []; //[ClientForcast]
  note = "";
  packageId;
}
