import { useRecoilValue } from "recoil";
import { Input, Message, SelectPicker } from "rsuite";
import { CurrencyAtom } from "../../../Atoms/currency.atom";
import Responsive from "../../../Components/Responsive";

function AddEdit({ _setmodel, error, model }) {
  const currencies = useRecoilValue(CurrencyAtom);

  return (
    <>
      <Responsive s={3} m={3} l={3} xl={3} className="p-10">
        {" "}
        <label>Source:</label>
        <SelectPicker
          // onSearch={(q) => fetchClients(q)}
          data={currencies.map((c) => {
            return { label: c.name, value: c.id };
          })}
          block
          noSearch
          value={model.currencySourceId}
          onSelect={(currencySourceId) => {
            _setmodel((prev) => ({ ...model, currencySourceId }));
          }}
        />
      </Responsive>
      <Responsive s={3} m={3} l={3} xl={3} className="p-10">
        <label>Rate</label>
        <Input
          type="number"
          step="0.001"
          value={model.rate}
          onChange={(rate) => {
            _setmodel((prev) => {
              return { ...prev, rate };
            });
          }}
        />{" "}
      </Responsive>
      <Responsive s={3} m={3} l={3} xl={3} className="p-10">
        {" "}
        <label>Currency:</label>
        <SelectPicker
          // onSearch={(q) => fetchClients(q)}
          data={currencies.map((c) => {
            return { label: c.name, value: c.id };
          })}
          block
          noSearch
          value={model.currencyId}
          onSelect={(currencyId) => {
            _setmodel((prev) => ({ ...model, currencyId }));
          }}
        />
      </Responsive>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}

export default AddEdit;
