import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Button,
  ButtonToolbar,
  DatePicker,
  Divider,
  IconButton,
  Input,
  Message,
  SelectPicker,
  Steps,
  Tag,
  TagInput,
  TagPicker,
  Uploader,
} from "rsuite";
import { saleSate } from "../../Atoms/sale.atom";
import Responsive from "../../Components/Responsive";
import {
  AgeType,
  checkPurpose,
  checkStatus,
  Gender,
  paymentType,
  roomTypes,
  serviceTypes,
} from "../../Constants/types";
import {
  Guest,
  HotelReservationModel,
  PackageItemModel,
  PaymentModel,
  SaleModel,
  TicketModel,
} from "../../Models/SaleModels";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import Grid from "../../Components/Grid";
import boards from "../../Data/boards.json";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import TrashIcon from "@rsuite/icons/Trash";
import { APi } from "../../Api/";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { default as AddCheck } from "../Check/AddEdit.component";
import { CheckModel } from "../../Models/CheckModel";
import validate from "../../Helpers/validate";
import Swal from "sweetalert2";
import moment from "moment";
import { CurrencyAtom } from "../../Atoms/currency.atom";
import {
  PackagePurchaseItemModel,
  PaymentPurchaseModel,
} from "../../Models/PurchaseModel";
import { purchaseSate } from "../../Atoms/purchase.atom";
const styles = {
  // width: "200px",
  // display: "inline-table",
  verticalAlign: "top",
};
function AddEdit({
  error,
  clients,
  banks,
  fetchBanks,
  fetchClients,
  checkouts,
  fetchCheckouts,
  providers,
  fetchProviders,
  agentBookingFlights,
  fetchTags,
  tags,
}) {
  const [current, setcurrent] = useState(0);

  return (
    <>
      <Steps current={current} style={styles}>
        <Steps.Item title="Infos Génerales" onClick={() => setcurrent(0)} />
        <Steps.Item title="Services Achetées" onClick={() => setcurrent(1)} />
        <Steps.Item onClick={() => setcurrent(2)} title="Encaissement" />
        <Steps.Item title="Attachments" onClick={() => setcurrent(3)} />
      </Steps>

      <div style={{ padding: "40px 10px" }}>
        {!current ? (
          <General
            fetchProviders={fetchProviders}
            providers={providers}
            tags={tags}
            fetchTags={fetchTags}
          />
        ) : current == 1 ? (
          <Package
            agentBookingFlights={agentBookingFlights}
            // fetchAgentBookingFlights={fetchAgentBookingFlights}
          />
        ) : current == 2 ? (
          <Payment />
        ) : (
          <Attachments />
        )}
      </div>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
      <Divider />
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;

const General = ({ providers, fetchProviders, tags, fetchTags }) => {
  const [selectedTags, setselectedTags] = useState([]);
  const [model, _setmodel] = useRecoilState(purchaseSate);
  const currencies = useRecoilValue(CurrencyAtom);

  return (
    <>
      <label>Fournisseur: </label>
      <SelectPicker
        onSearch={(q) => fetchProviders(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          providers.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.providerId}
        onSelect={(providerId) => {
          _setmodel((prev) => {
            return { ...prev, providerId };
          });
        }}
      />
      <label>Date D'Achat': </label>
      <Input
        type="date"
        value={model.date}
        onChange={(date) => {
          console.log(date);
          _setmodel((prev) => {
            return { ...prev, date };
          });
        }}
        // onSelect={(date) =>
        //   _setmodel((prev) => {
        //     return { ...prev, date };
        //   })
        // }
        // block
      />

      <label>Mots Clés: </label>
      <TagPicker
        data={
          tags
            ? tags.map((c) => {
                return { label: c.name, value: c.id };
              })
            : []
        }
        block
        onSearch={fetchTags}
        onSelect={(s) => {
          let existed = selectedTags.filter((el) =>
            s.find((_id) => _id == el.id)
          );
          let newTags = tags.filter(
            (item) =>
              s.find((_id) => _id == item.id) &&
              !selectedTags.find((el) => el.id == item.id)
          );
          setselectedTags((prev) => {
            return [...existed, ...newTags];
          });

          _setmodel((prev) => {
            return { ...prev, tags: [...existed, ...newTags] };
          });
        }}
      />
      <label>Devise: </label>
      <SelectPicker
        // onSearch={(q) => fetchClients(q)}
        data={currencies.map((c) => {
          return { label: c.name, value: c.id };
        })}
        block
        noSearch
        value={model.currencyId}
        onSelect={(currencyId) => {
          _setmodel((prev) => {
            return { ...prev, currencyId };
          });
        }}
      />
    </>
  );
};
const Package = ({}) => {
  const [model, _setmodel] = useRecoilState(purchaseSate);
  const [state, setstate] = useState(new PackagePurchaseItemModel());
  const save = () => {
    _setmodel((prev) => {
      console.log(prev);
      let m = { ...prev };
      if (typeof m.package.packageItems != "object")
        m.package.packageItems = [];
      if (typeof state.index == "number") {
        let packageItems = [...m.package.packageItems];
        packageItems[state.index] = state;

        m.package = { ...m.package, packageItems };
      } else {
        let packageItems = [...m.package.packageItems];
        packageItems.push(state);
        m.package = { ...m.package, packageItems };
      }
      return m;
    });
    setstate(new PackagePurchaseItemModel());
  };
  const _delete = (index) => {
    _setmodel((prev) => {
      let m = { ...prev };
      let packageItems = [...m.package.packageItems];
      packageItems.splice(index, 1);
      m.package = { ...m.package, packageItems };
      return m;
    });
  };
  const setEditedElement = (index) => {
    setstate({ ...model.package.packageItems[index], index });
  };
  return (
    <>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Service : </label>
        <SelectPicker
          data={serviceTypes}
          block
          noSearch
          value={state.service}
          onSelect={(service) => {
            setstate((prev) => {
              return { ...prev, service };
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={3} xl={3} className="p-10">
        <label>Coût Unitaire:</label>
        <Input
          type="number"
          step="0.1"
          value={state.unitCost}
          onChange={(unitCost) => {
            setstate((prev) => {
              return { ...prev, unitCost };
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={2} xl={2} className="p-10">
        <label>Commission Unitaire:</label>
        <Input
          type="number"
          step="0.1"
          value={state.unitFee}
          onChange={(unitFee) => {
            setstate((prev) => {
              return { ...prev, unitFee };
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={2} xl={2} className="p-10">
        <label>Quantité:</label>
        <Input
          // disabled={state.service == 1}
          type="number"
          step="1"
          value={state.qty}
          onChange={(qty) => {
            setstate((prev) => {
              return { ...prev, qty };
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={2} xl={2} className="p-10">
        <label>Commission ( % ):</label>
        <Input
          type="number"
          step="0.1"
          value={state.commission}
          onChange={(commission) => {
            setstate((prev) => {
              return { ...prev, commission };
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={4} xl={4} className="p-10">
        <label>Coût Total:</label>
        <div>
          {" "}
          <b style={{ color: "darkcyan", fontSize: "25px" }}>
            {(
              (1 + parseFloat(state.commission) / 100) *
              parseInt(state.qty) *
              (parseFloat(state.unitFee) + parseFloat(state.unitCost))
            ).toFixed(3)}
          </b>
        </div>
      </Responsive>
      <div
        style={{
          background: "rgba(0,143,150,0.1)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <>
          <label>Désignation:</label>
          <Input as="textarea" rows={2} placeholder="Notes" />
        </>
      </div>
      <Divider />
      <div style={{ textAlign: "right" }}>
        <IconButton onClick={save} color="violet" icon={<CreditCardPlusIcon />}>
          Ajouter
        </IconButton>
      </div>
      <Divider />
      <Grid
        editAction={setEditedElement}
        deleteAction={_delete}
        actionKey="index"
        noAdvancedActions // for custom advanced actions
        columns={columnsPackageItems}
        rows={model.package.packageItems.map((el, index) => {
          return { ...el, index };
        })}
      />
      <h5>
        Cout Total:{" "}
        <i>
          {columnsPackageItems.reduce((a, b) => {
            return (
              a +
              parseInt(b.qty) *
                (1 + parseFloat(b.commission) / 100) *
                (parseFloat(b.unitFee) + parseFloat(b.unitCost))
            );
          }, 0)}
        </i>
      </h5>
    </>
  );
};

const columnsPackageItems = [
  {
    value: "service",
    name: "Service",
    render: (s) => (
      <b>
        <a>{serviceTypes.find((el) => el.value == s).label}</a> <br></br>
      </b>
    ),
  },
  {
    value: "commission",
    value2: "unitFee",
    value3: "unitCost",
    value4: "qty",
    name: "Coût Total",
    render: (commission, unitFee, unitCost, qty) => (
      <b>
        {(
          parseInt(qty) *
          (1 + parseFloat(commission) / 100) *
          (parseFloat(unitFee) + parseFloat(unitCost))
        ).toFixed(3)}
      </b>
    ),
  },
];

// -----------------------------------

const columnsPyamentItems = [
  {
    value: "amount",
    name: "Montant",
    render: (v) => <b>{v}</b>,
  },

  {
    value: "type",
    name: "Type",
    render: (v) => (
      <Tag
        color={
          v == 1
            ? "blue"
            : v == 2
            ? "green"
            : v == 3
            ? "violet"
            : v == 4
            ? "black"
            : "yellow"
        }
      >
        {paymentType.find((el) => el.value == v) &&
          paymentType.find((el) => el.value == v).label}
      </Tag>
    ),
  },

  {
    value: "notes",
    name: "Notes",
    render: (v) => {
      return <div style={{ maxWidth: "260px", overflow: "auto" }}>{v}</div>;
    },
  },
];

const Payment = () => {
  const [checks, setchecks] = useState([]);
  const [model, setmodel] = useRecoilState(purchaseSate);
  const [banks, setbanks] = useState([]);
  const [checkouts, setcheckouts] = useState([]);
  const [AddElement, showAddElement] = useState(false);
  const [_checkModel, set_checkModel] = useState(new CheckModel());
  const [errorcheck, seterrorcheck] = useState("");
  const [files, setfiles] = useState([]);
  const [loading, setloading] = useState(false);
  const [state, setstate] = useState(new PaymentPurchaseModel());
  const savePayment = () => {
    setmodel((prev) => {
      console.log(prev);
      let m = { ...prev };
      if (typeof m.payments != "object") m.payments = [];
      if (typeof state.index == "number") {
        let payments = [...m.payments];
        payments[state.index] = state;

        m.payments = [...payments];
      } else {
        let payments = [...m.payments];
        payments.push(state);
        m.payments = [...payments];
      }
      return m;
    });
    resetPayment();
  };
  const _deletePayment = (index) => {
    setmodel((prev) => {
      let m = { ...prev };
      let payments = [...m.payments];
      payments.splice(index, 1);
      m.payments = [...payments];
      return m;
    });
    resetPayment();
  };
  const setEditedElement = (index) => {
    setstate({ ...model.payments[index], index });
  };
  // HELPERS
  const resetCheckModel = () => {
    set_checkModel(new CheckModel());
    seterrorcheck("");
  };
  const resetPayment = () => {
    setstate(new PaymentPurchaseModel());
  };
  // UPLOAD ACTIONS
  const upload = (list, file, fileKey) => {
    setloading(true);
    APi.createAPIEndpoint(APi.ENDPOINTS.File)
      .upload(file)
      .then((res) => {
        setloading(false);

        let i = list.findIndex((el) => el.fileKey == fileKey);
        setfiles((prev) => {
          let items = [...list];
          items[i].status = "finished";
          return items;
        });
        let attachments = [..._checkModel.attachments];
        attachments.push(res.data[0].fileName);
        set_checkModel((prev) => {
          return { ..._checkModel, attachments };
        });
      })
      .catch((error) => {
        setloading(false);

        let i = list.findIndex((el) => el.fileKey == fileKey);
        setfiles((prev) => {
          let items = [...list];
          items[i].status = "error";
          return items;
        });
      });
  };
  const _delete = (fileKey) => {
    let i = files.findIndex((el) => el.fileKey == fileKey);
    APi.createAPIEndpoint(APi.ENDPOINTS.File)
      .delete(files[i].name)
      .then((res) => {
        setfiles((prev) => {
          let items = [...prev];
          items.splice(i);
          return items;
        });

        let attachments = [..._checkModel.attachments];
        attachments.splice(i);
        set_checkModel((prev) => {
          return { ..._checkModel, attachments };
        });
      })
      .catch((error) => {});
  };
  //------------------ Saving Events -------------------------
  const save = () => {
    let msg = validate(_checkModel, [
      { number: "Numéro" },
      { status: "Status" },
      { amount: "Montant" },
      { depositDate: "Date de Dépôt" },
      // { date: "Date" },

      // { checkPurpose: "Type de Chéque" },
      // { clientId: "Client" },
    ]);
    if (msg) seterrorcheck(msg);
    else {
      setchecks((prev) => [
        {
          value: -1,
          label:
            _checkModel.number +
            "(" +
            (banks.find((b) => b.value == _checkModel.depositBankId)
              ? banks.find((b) => b.value == _checkModel.depositBankId).label
              : "") +
            ")",
        },
        ...prev,
      ]);
      setstate((prev) => {
        return {
          ...prev,
          check: {
            ..._checkModel,
            amount: parseFloat(_checkModel.amount),
            attachments:
              _checkModel.attachments && _checkModel.attachments.join(),
            clientId: model.clientId,
          },
          checkId: -1,
        };
      });
      resetCheckModel();
      showAddElement(false);
    }
  };
  //___________________________________
  const fetchChecks = (q = "") => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Check, { q }, "/autocomplete")
      .customGet()
      .then((res) =>
        setchecks(
          res.data.map((el) => {
            return { value: el.id, label: el.name };
          })
        )
      );
  };
  const fetchBanks = (q = "") => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Bank, { q }, "/autocomplete")
      .customGet()
      .then((res) =>
        setbanks(
          res.data.map((el) => {
            return { value: el.id, label: el.name };
          })
        )
      );
  };
  const fetchCheckouts = (q = "") => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Checkout, { q }, "/autocomplete")
      .customGet()
      .then((res) =>
        setcheckouts(
          res.data.map((el) => {
            return { value: el.id, label: el.name };
          })
        )
      );
  };
  useEffect(() => {
    fetchChecks();
    fetchBanks();
    fetchCheckouts();
  }, []);
  return (
    <>
      <div
        style={{
          background: "rgba(0,143,150,0.02)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Montant:</label>
          <Input
            type="number"
            step="0.1"
            value={state.amount}
            onChange={(amount) => {
              setstate((prev) => {
                return { ...prev, amount };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Type De Paiment : </label>
          <SelectPicker
            data={paymentType}
            block
            noSearch
            value={state.type}
            onSelect={(type) => {
              setstate((prev) => {
                return { ...prev, type };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Date:</label>
          <Input
            type="date"
            value={model.date}
            onChange={(date) => {
              setstate((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Réf Réglement:</label>
          <Input
            value={state.refReglement}
            onChange={(refReglement) => {
              setstate((prev) => {
                return { ...prev, refReglement };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={9} xl={9} className="p-10">
          <label>Notes:</label>
          <Input
            value={state.notes}
            onChange={(notes) => {
              setstate((prev) => {
                return { ...prev, notes };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>
            {state.type == 1 ? "Chéque" : state.type == 4 ? "Banque" : "Caisse"}
          </label>
          <div
            style={{
              display: "inline-block",
              width: state.type == 1 ? "calc(100% - 40px)" : "100%",
              paddingRight: "5px",
            }}
          >
            {" "}
            <SelectPicker
              block
              data={
                state.type == 1 ? checks : state.type == 4 ? banks : checkouts
              }
              noSearch
              value={
                state[
                  state.type == 1
                    ? "checkId"
                    : state.type == 4
                    ? "bankId"
                    : "checkoutId"
                ]
              }
              onSelect={(v) => {
                setstate((prev) => {
                  return {
                    ...prev,

                    [state.type == 1
                      ? "checkId"
                      : state.type == 4
                      ? "bankId"
                      : "checkoutId"]: v,
                  };
                });
              }}
            />
          </div>
          {state.type == 1 && (
            <IconButton
              size="sm"
              onClick={() => showAddElement(true)}
              icon={<PlusRoundIcon />}
            ></IconButton>
          )}
        </Responsive>
        {state.type == 4 && (
          <Responsive s={6} m={6} l={10} xl={10} className="p-10">
            <label>Banque d'émission :</label>
            <Input
              value={state.EmissionBank}
              onChange={(EmissionBank) => {
                setstate((prev) => {
                  return {
                    ...prev,
                    EmissionBank,
                  };
                });
              }}
            />
          </Responsive>
        )}
        {AddElement && state.type == 1 && (
          <div
            style={{
              background: "rgba(0,143,150,0.1)",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <AddCheck
              _delete={_delete}
              upload={upload}
              error={errorcheck}
              model={_checkModel}
              banks={banks}
              fetchBanks={fetchBanks}
              _setmodel={set_checkModel}
            />
            <Button onClick={save} appearance="primary">
              {/* {state.loading ? <Loader size="sm" /> : "Enregistrer"} */}
              Enregistrer
            </Button>
          </div>
        )}

        <Divider />
        <div style={{ textAlign: "right" }}>
          <ButtonToolbar>
            <IconButton
              onClick={savePayment}
              appearance="primary"
              color="blue"
              icon={<CreditCardPlusIcon />}
            >
              Ajouter
            </IconButton>
          </ButtonToolbar>
        </div>
      </div>
      <Divider />
      <Grid
        editAction={setEditedElement}
        deleteAction={_deletePayment}
        actionKey="index"
        noAdvancedActions // for custom advanced actions
        columns={columnsPyamentItems}
        rows={
          model.payments
            ? model.payments.map((el, index) => {
                return { ...el, index };
              })
            : []
        }
      />
    </>
  );
};
const Attachments = () => {
  const [model, _setmodel] = useRecoilState(saleSate);
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(false);
  const upload = (list, file, fileKey) => {
    setloading(true);
    APi.createAPIEndpoint(APi.ENDPOINTS.File)
      .upload(file)
      .then((res) => {
        setloading(false);

        let i = list.findIndex((el) => el.fileKey == fileKey);
        setstate((prev) => {
          let items = [...list];
          items[i].status = "finished";
          return items;
        });
        let attachments = [...model.attachments];
        attachments.push({ name: res.data[0].fileName });
        _setmodel((prev) => {
          return { ...model, attachments };
        });
      })
      .catch((error) => {
        setloading(false);

        let i = list.findIndex((el) => el.fileKey == fileKey);
        setstate((prev) => {
          let items = [...list];
          items[i].status = "error";
          return items;
        });
      });
  };
  const _delete = (fileKey) => {
    let i = state.findIndex((el) => el.fileKey == fileKey);
    APi.createAPIEndpoint(APi.ENDPOINTS.File)
      .delete(model.attachments[i].name)
      .then((res) => {
        setstate((prev) => {
          let items = [...prev];
          items.splice(i);
          return items;
        });
        let attachments = [...model.attachments];
        attachments.splice(i);
        _setmodel((prev) => {
          return { ...model, attachments };
        });
      })
      .catch((error) => {});
  };
  return (
    <>
      {" "}
      <Uploader
        loading={loading}
        autoUpload={false}
        fileList={state}
        action="#"
        draggable
        onRemove={(f) => _delete(f.fileKey)}
        onChange={(list) => {
          // setstate((prev) => list);
          console.log(list);
          let file = list.filter((el) => el.status != "finished").reverse()[0];
          if (file) upload(list, file.blobFile, file.fileKey);
        }}
      >
        <div style={{ background: "rgba(200,200,200,0.1)" }}>
          <div style={{ fontSize: "50px" }}>
            {" "}
            <FileUploadIcon color="#3598ff"></FileUploadIcon>
          </div>
          <h4 style={{ color: "#bbb", fontWeight: "400" }}>
            Clicker ou Faites glisser les fichiers vers cette zone
            {/* pour les télécharger */}
          </h4>
        </div>
      </Uploader>
      {loading && <></>}
    </>
  );
};
const fileList = [
  {
    name: "a.png",
    fileKey: 1,
    url: "https://user-images.githubusercontent.com/1203827/47638792-92414e00-db9a-11e8-89c2-f8f430a23cd3.png",
  },
  {
    name: "b.png",
    fileKey: 2,
    url: "https://user-images.githubusercontent.com/1203827/47638807-9d947980-db9a-11e8-9ee5-e0cc9cd7e8ad.png",
  },
];
