import { checkStatus, checkPurpose, serviceTypes } from "../../Constants/types";
import moment from "moment";
import format_number from "../../Helpers/number_formatter";

export const multiDataSet = (data) => {
  return [
    {
      columns: [
        { title: "N° Billet", width: { wpx: 120 } },
        { title: "Client", width: { wpx: 120 } },
        { title: "Voyageur", width: { wpx: 150 } }, //char width
        { title: "Prix Total", width: { wpx: 100 } }, //pixels width
        { title: "Montant Remboursé", width: { wpx: 100 } }, //pixels width
        { title: "date", width: { wpx: 100 } }, //char width
        { title: "Status", width: { wpx: 100 } }, //char width
        { title: "agent Amadeus", width: { wpx: 100 } }, //char width
        // { title: "Destinations/Note", width: { wpx: 100 } }, //pixels width
      ],
      data: data.map((item) => {
        let item2 = [
          {
            value: item.ticket ? item.ticket.ticketNumber : "",
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },
              font: { sz: "13", color: { rgb: "2590b5" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.client
              ? item.client.name
                ? item.client.name
                : "-"
              : "-",
            style: {
              font: { sz: "14", color: { rgb: "4c67c2" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.customer.name ? item.customer.name : "",
            style: {
              font: { sz: "14", color: { rgb: "4c67c2" } },
              alignment: { horizontal: "center" },
            },
          },

          {
            value: item.totalPrice ? format_number(item.totalPrice) : "",
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.totalRefund ? format_number(item.totalRefund) : "",
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.ticket
              ? item.ticket.emissionDate
                ? moment(item.ticket.emissionDate).format("DD MMM YYYY")
                : "-"
              : "",
            style: {
              font: { sz: "13", color: { rgb: "2590b5" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value:
              item.ticket && !item.ticket.tranc
                ? "TKTT"
                : item.ticket
                ? item.ticket.tranc
                : "",
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },

              font: {
                sz: "13",
                color: {
                  rgb: item.ticket
                    ? item.ticket.tranc == "EMDS"
                      ? "blue"
                      : item.ticket.tranc == "RFND" ||
                        item.ticket.tranc == "RFBSP"
                      ? "green"
                      : item.ticket.tranc == "EMDA"
                      ? "violet"
                      : item.ticket.tranc == "ADM"
                      ? "orange"
                      : item.ticket.tranc == "CANX"
                      ? "red"
                      : "yellow"
                    : "yellow",
                },
              },
              alignment: { horizontal: "center" },
            },
          },

          // {
          //   value: item.note ? item.note : "",
          //   style: {
          //     font: {
          //       sz: "14",
          //     },
          //     alignment: { horizontal: "center" },
          //   },
          // },
          {
            value:
              item.ticket && item.ticket.agent && item.ticket.agent.name
                ? item.ticket.agent.name
                : "",
            style: {
              font: { sz: "14", color: { rgb: "4c67c2" } },
              alignment: { horizontal: "center" },
            },
          },
        ];
        return item2;
      }),
    },
  ];
};
