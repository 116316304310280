import React, { useEffect, useState } from "react";

import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  Button,
  Checkbox,
  DatePicker,
  DateRangePicker,
  Input,
  Modal,
  SelectPicker,
  Tag,
} from "rsuite";
import { MdAirplaneTicket } from "react-icons/md";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import VisibleIcon from "@rsuite/icons/Visible";
import TextImageIcon from "@rsuite/icons/TextImage";
import { dateTypes, ticketType } from "../../Constants/types";
import validate from "../../Helpers/validate";
import { SaleModel } from "../../Models/SaleModels";
import AddEdit from "./AddEdit.component";
import { multiDataSet } from "./excel_data";
import moment from "moment";
import airlines from "../../Data/airlines.json";

import { ticketSate } from "../../Atoms/ticket.atom";
import { InvoiceService } from "../../Api/invoice.api";
import { agencyAtom } from "../../Atoms/agency.atom";

import TicketModel, {
  TicketRSModel,
  tickets_fake,
} from "../../Models/Ticket.model";
import { read, utils, writeFile } from "xlsx";

import OverViewWrapper from "./OverView";
import AddTicketModel from "../../Models/AddTikcketModel";
import axios from "axios";
import { ticketingSate } from "../../Atoms/ticketing.atom";
import TicketingModel, { TicketPostModel } from "../../Models/TicketingModel";
import { CustomerVoModel } from "../../Models/SaleVoModel";
import { ENDPOINTS } from "../../Api/enpoints";
import ResumeCard from "../../Components/ResumeCard";
import { AgentsAtom } from "../../Atoms/agents.atom";
export default function Ticketing(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [clients, setclients] = useState([]);
  const [clients2, setclients2] = useState([]);
  const [banks, setbanks] = useState([]);
  const [agentBookingFlights, setAgentBookingFlights] = useState([]);
  const [tags, setTags] = useState([]);
  const [id_overview, setid_overview] = useState(0);
  const agency = useRecoilValue(agencyAtom);
  const [checkeds, setcheckeds] = useState([]);
  const [selectedtClient, setselectedtClient] = useState(null);
  const [rfndId, setrfndId] = useState(null);
  const [totalCount, settotalCount] = useState(0);
  const [totalSale, settotalSale] = useState(0);
  const [totalRefund, settotalRefund] = useState(0);
  const [totalCommission, settotalCommission] = useState(0);
  const [id_commission, setid_commission] = useState(0);
  const agents = useRecoilValue(AgentsAtom);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    clientId: 0,
    tranc: "All",
    date: null,
    dateTo: null,
    dateFrom: null,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(ticketingSate);
  const [airlines, setairlines] = useState([]);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new TicketingModel());
    setError("");
  };
  // API CALLS
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) =>
          forFilter ? setclients(res.data) : setclients2(res.data)
        );
    }
  };

  const fetchAgentBookingFlights = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(
        APi.ENDPOINTS.AgentBookingFlight,
        { q },
        "/autocomplete"
      )
        .customGet()
        .then((res) => setAgentBookingFlights(res.data));
    }
  };

  const fetchTags = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Tag, { q }, "/autocomplete")
        .customGet()
        .then((res) => setTags(res.data));
    }
  };
  const fetchAirlines = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(
        APi.ENDPOINTS.Common,
        { q },
        "/autocompleteAirlines"
      )
        .customGet()
        .then((res) => setairlines(res.data));
    }
  };
  const fetch = (page = null) => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/ticket", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
      page: page ? page : filterModel.page,
    })
      .fetchAll()
      .then((res) => {
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        setcheckeds([]);

        console.log(res.data.data.filter((el) => el.ticket.emd));
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
        settotalSale(res.data.totalSale);
        settotalRefund(res.data.totalRefund);
        settotalCommission(res.data.totalCommission);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      })
      .catch((e) => setError(e.Message));
  };

  const save = () => {
    console.log(model);

    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = {
      ...model,
    };

    delete m.tags;

    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/ticket")
        .update(m.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      console.log(m);

      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/ticket")
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
    // }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  //
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.Order)
      .fetchById(id)
      .then((res) => {
        setmodel({
          ...res.data,
          //    doEmmission: res.data.doEmmission.substring(0, 10),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const cancel = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.Order, {}, "/Cancel/" + id)
      .customGet()
      .then((res) => {
        fetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // LIFE CYCLES
  useEffect(() => {
    fetchClients();
    fetchAirlines();
    fetchTags();
    fetchAgentBookingFlights();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  const columns = [
    {
      value: "ticket",
      value2: "tag",
      value3: "id",
      name: "N°",
      render: (v, v2, id) => (
        <b style={{ fontSize: "14px" }}>
          <img
            alt=""
            src={
              v &&
              v.airlineCode &&
              airlines.find((el) => el.id == v.airlineCode)
                ? airlines.find((el) => el.id == v.airlineCode).logo
                : ""
            }
          ></img>
          <Checkbox
            onChange={(v) => {
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => [...prev, id]);
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>
          <a
            style={{ color: "#232323" }}
            onClick={(e) => {
              setid_overview(id);
              getBYId(id);
            }}
          >
            {v ? v.ticketNumber : ""}
          </a>
          <br></br>
          {v2 && <Tag color={v ? "blue" : "black"}>#{v2}</Tag>}
        </b>
      ),
    },
    {
      value: "client",
      name: "Client",
      render: (v) => <a>{v ? (!v.name ? "" : v.name.toUpperCase()) : ""}</a>,
    },
    {
      value: "ticket",
      name: "PNR",
      render: (v) => (
        <b style={{ color: "#5a2c3e", fontSize: "13px" }}>{v ? v.pnr : ""}</b>
      ),
    },
    {
      value: "customer",
      value2: "tag",
      name: "Voyageur",
      render: (v, v2) => (
        <a
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100px" /* some width */,
          }}
        >
          {v ? v.name.toUpperCase() : ""}
          <br></br> <i style={{ color: "#aaa" }}>{v ? v.passportNumber : ""}</i>
        </a>
      ),
    },
    // {
    //   value: "tag",
    //   name: "Tag",
    //   render: (v) =>
    //     v ? <Tag color={v ? "blue" : "black"}>#{v}</Tag> : <span>-</span>,
    // },

    {
      value: "totalPrice",
      value2: "currency",
      value3: "status",
      name: "Prix",
      render: (v, v2, v3) => (
        <b
          style={{
            color: "#53af50",
            fontSize: "16px",
            textDecoration: v3 == 5 ? "line-through" : "",
          }}
        >
          {v.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {v2.code}
          </span>
        </b>
      ),
    },
    {
      value: "commission",
      value3: "commissionRefund",
      value2: "currency",
      name: "Com.",
      render: (v, v2, v3) => (
        <b style={{ color: "#5a2c3e", fontSize: "15px" }}>
          {(v + v3).toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {v2.code}
          </span>
        </b>
      ),
    },
    {
      value: "ticket",
      name: "Statut",
      render: (v) => (
        <>
          <Tag
            color={
              v
                ? v.tranc == "EMDS"
                  ? "blue"
                  : v.tranc == "RFND" || v.tranc == "RFBSP"
                  ? "green"
                  : v.trancv == "EMDA"
                  ? "violet"
                  : v.tranc == "ADM"
                  ? "orange"
                  : v.tranc == "CANX"
                  ? "red"
                  : "yellow"
                : "yellow"
            }
          >
            {v && v.tranc}
          </Tag>
        </>
      ),
    },
    {
      value: "ticket",
      name: "Emd T.",
      render: (v) => <>{v && <Tag color={"violet"}>{v && v.emdNumber}</Tag>}</>,
    },
    {
      value: "ticket",
      value2: "currency",

      name: "EMD",
      render: (v, v2) => (
        <b style={{ color: "rgb(67,55,160)", fontSize: "16px" }}>
          {v ? v.emd.toFixed(3) : "-"}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {v ? v2.code : ""}
          </span>
        </b>
      ),
    },
    {
      value: "totalRefund",
      value2: "currency",
      name: "M.Remb.",
      render: (v, v2) => (
        <b style={{ color: "rgb(67,55,160)", fontSize: "16px" }}>
          {v ? v.toFixed(3) : "-"}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {v ? v2.code : ""}
          </span>
        </b>
      ),
    },
    {
      value: "ticket",
      name: "Date",
      render: (v) => (
        <strong>
          {!v ? "-" : moment(v.emissionDate).format("DD MMM YYYY")}
        </strong>
      ),
    },
    {
      name: "détails",
      value: "id",
      render: (v) => (
        <button
          onClick={(e) => {
            setid_overview(v);
            getBYId(v);
          }}
          style={{
            color: "rgba(67,55,160,1)",
            padding: "6px 10px",
            fontSize: "12px",
            background: "rgba(67,55,160,0.1)",
            borderRadius: "4px",
          }}
        >
          <VisibleIcon />
        </button>
      ),
    },
  ];
  const format_date = (str) => {
    let d = new Date(
      2000 + parseInt(str.slice(-2)),
      getMonthFromString(str.slice(2, 5)),

      str.slice(0, 2)
    );
    // str.slice(4, 6) + "-" + +"-20" + str.slice(0, 2);
    return moment(d).format("YYYY-MM-DDTHH:mm:ss");
  };
  function getMonthFromString(mon) {
    var month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return month.findIndex((el) => el.toUpperCase() == mon);
  }
  const readfile2 = ($event) => {
    const _wb = utils.book_new();

    const files = $event.target.files;
    let _rows = [];
    let keys = [
      "Nom Agence",
      "AirLineCode", //
      "Numero ticket",
      "Nom Client",
      "Date Emission", //
      "Statut", //
      "PNR", //
      "Destination", // seg
      "Prix billet", //
      "Commissions", //
      "Prix vente", //
      "Montant Remboursé", //
      "DepartureDate", // seg
      "DepartureTime", // seg
      "ReturnDate", // seg
      "ReturnTime", // seg
      "Airline", //
      "SegmentNbr", //
      "Fare", //
      "Tax", //
      "Com .AirLine", //
      "Date de  Remboursement", //
      "Code Agence", //
      "Agent Amadeus",
      "passportNumber", //.
      "gender", //.
      "birthdate", //.
      "nationality", //.
      "passportExpirationDate", //.
    ];
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result, {
          cellDates: true,
          dateNF: "YYYY-MM-DD",
          type: "array",
        });
        const sheets = wb.SheetNames;
        console.log(wb.Sheets[sheets[0]]);

        if (sheets.length) {
          let l = [];
          console.log(wb.Sheets[sheets[0]]);
          let rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
            blankrows: false,
            raw: true,
            /** Default value for null/undefined values */
            /** if true, skip hidden rows and columns */
            //skipHidden: false,
          });
          console.log(rows.length);
          let elems = rows.map((r, i) => {
            let _t = new TicketingModel();
            _t.clientName = r["Nom Agence"];
            _t.tag =
              r["Nom Agence"].startsWith("UMRAH ") ||
              r["Nom Agence"].startsWith("VO ")
                ? r["Nom Agence"]
                : "";
            let _c = new CustomerVoModel();
            _t.totalPrice = r["Prix vente"];
            _c.birthDate = r["birthdate"] ? format_date(r["birthdate"]) : null;
            _c.name = r["Nom Client"];
            _c.passportNumber = r["passportNumber"];
            _c.expirationPassportDate = r["passportExpirationDate"]
              ? format_date(r["passportExpirationDate"])
              : null;
            _c.gender = r["gender"] == "M" ? 1 : 2;
            _t.customer = _c;
            let _tt = new TicketPostModel();
            _tt.ticketNumber = r["Numero ticket"];
            _tt.commission = r["Commissions"];
            _t.commission = r["Commissions"];
            _tt.pnr = r["PNR"];
            _tt.tranc = r["Statut"];
            _tt.emissionDate = r["Date Emission"];
            _tt.totalRefund = r["Montant Remboursé"];
            _t.totalRefund = r["Montant Remboursé"];
            //_tt.refundDate = r["Numero ticket"];
            _tt.tax = r["Tax"];
            _tt.fare = r["Fare"];
            _t.fare = r["Prix billet"];
            _tt.totalDoc = r["Prix billet"];
            _tt.airlineCode = r["Airline"];
            _tt.refundDate = r["Date de  Remboursement"];
            _t.dateRefund = r["Date de  Remboursement"];
            if (
              r["Agent Amadeus"] &&
              r["Agent Amadeus"].length > 2 &&
              agents.find((ag) => ag.name == r["Agent Amadeus"].trim())
            ) {
              _tt.agentId = agents.find(
                (ag) => ag.name == r["Agent Amadeus"]
              ).id;
            }
            _t.ticket = _tt;
            //if (i > 110) {

            return _t;

            // }
          });
          console.log(elems.length);
          send_recursive(0, elems);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const send_recursive = (i, els) => {
    APi.createAPIEndpoint(ENDPOINTS.Order + "/Ticket")
      .create(els[i])
      .then((res) => {
        if (i < els.length - 1) {
          send_recursive(i + 1, els);
          if (!(i % 100)) fetch();
        }
      })
      .catch((e) => {
        console.log(e);
        console.log("index: ", i);

        if (i < els.length - 1) {
          send_recursive(i + 1, els);
        }
      });
  };

  return (
    <div>
      <Filter search={() => fetch(1)}>
        <Responsive m={6} l={3} xl={2.5} className="p-5">
          <label>Recherche Multiple </label>
          <Input
            placeholder="voyageur / pnr / n° ticket"
            value={filterModel.q}
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q: q.trim() };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={2.5} className="p-5">
          <label>Client: </label>
          <SelectPicker
            onSearch={(q) => fetchClients(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              clients.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.clientId}
            onSelect={(clientId) => {
              setfilterModel((prev) => {
                return { ...prev, clientId };
              });
            }}
          />
        </Responsive>

        <Responsive m={6} l={2} xl={1.5} className="p-5">
          <label>Status: </label>
          <SelectPicker
            searchable={false}
            data={ticketType}
            block
            noSearch
            value={filterModel.tranc}
            onSelect={(tranc) => {
              setfilterModel((prev) => {
                return { ...prev, tranc };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.5} xl={2} className="p-5">
          <label>Dates: </label>
          <SelectPicker
            data={dateTypes}
            block
            noSearch
            value={filterModel.dateType}
            onSelect={(dateType) => {
              let today = new Date(moment(Date.now()).format("yyyy-MM-DD"));
              console.log(
                //
                today
              );
              setfilterModel((prev) => {
                return {
                  ...prev,
                  dateType,
                  date:
                    dateType == 7 || dateType == 1
                      ? today
                      : dateType == 2
                      ? moment(moment(Date.now()).add(-1, "d")).format(
                          "yyyy-MM-DD"
                        )
                      : null,
                  dateFrom:
                    dateType == 6
                      ? today
                      : dateType == 3
                      ? moment().startOf("month").format("yyyy-MM-DD")
                      : dateType == 4
                      ? moment(Date.now())
                          .subtract(1, "months")
                          .startOf("month")
                          .format("yyyy-MM-DD")
                      : dateType == 5
                      ? moment().startOf("year").format("yyyy-MM-DD")
                      : null,
                  dateTo:
                    dateType == 6
                      ? new Date(
                          moment(moment(Date.now()).add(1, "d")).format(
                            "yyyy-MM-DD"
                          )
                        )
                      : dateType == 3
                      ? today
                      : dateType == 4
                      ? moment(Date.now())
                          .subtract(1, "months")
                          .endOf("month")
                          .format("yyyy-MM-DD")
                      : null,
                };
              });
            }}
          />
        </Responsive>
        {filterModel.dateType == 7 && (
          <Responsive m={6} l={3} xl={2} className="p-5">
            <label>Date: </label>
            <Input
              type="date"
              value={filterModel.date}
              onChange={(date) => {
                setfilterModel((prev) => {
                  return { ...prev, date };
                });
              }}
            />
          </Responsive>
        )}
        {filterModel.dateType == 6 && (
          <Responsive m={6} l={3} xl={3} className="p-5">
            <label>Plage du temps: </label>
            <DateRangePicker
              block
              value={[filterModel.dateFrom, filterModel.dateTo]}
              onChange={(vs) => {
                setfilterModel((prev) => ({
                  ...prev,
                  dateFrom: vs[0],
                  dateTo: vs[1],
                }));
              }}
            />
          </Responsive>
        )}
      </Filter>
      <ExportAdd
        excelData={multiDataSet(data)}
        nameExcel="sale"
        additionalBtn={
          <>
            <input
              id="hidden"
              style={{
                visibility: "hidden",
                width: "0",
                height: 0,
                overflow: "hidden",
              }}
              type="file"
              onChange={readfile2}
              accept=".xlsx,.xls"
            />
            <div style={{ textAlign: "right" }}>
              <button
                onClick={() => {
                  const el = document.querySelector("input#hidden");
                  console.log(el);
                  el.click();
                }}
                style={{
                  padding: "7px 10px",
                  background: "#330033",
                  color: "#fff",
                  fontSize: "14px",
                  margin: "0 5px",
                  border: "0 none ",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Choisir fichier{" "}
              </button>
            </div>
          </>
        }
        size="lg"
        save={save}
        AddComponent={
          <AddEdit
            tags={tags}
            fetchTags={fetchTags}
            error={error}
            clients={clients}
            airlines={airlines}
            fetchAirlines={fetchAirlines}
            fetchClients={(q) => fetchClients(q, false)}
            fetchAgentBookingFlights={fetchAgentBookingFlights}
            agentBookingFlights={agentBookingFlights}
          />
        }
      />

      <div>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard
            icon={<MdAirplaneTicket />}
            notAmount
            text="Total Billets"
            color="245,195,35"
            amount={totalCount}
          />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total Vente" color="0,169,141" amount={totalSale} />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard
            text="Total Remboursement"
            amount={totalRefund}
            color="229,57,53"
          />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total Commission" amount={totalCommission} />
        </Responsive>
      </div>
      <div>
        <div
          onClick={(e) =>
            setcheckeds((prev) => (prev.length ? [] : data.map((el) => el.id)))
          }
          style={{
            display: "inline-block",
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          <Checkbox checked={checkeds.length > 0}></Checkbox> Sélectionner Tout
        </div>
        {checkeds.length ? (
          <>
            <Responsive m={6} l={3} xl={3} className="p-10">
              <SelectPicker
                searchable={false}
                data={clients.map((ag) => ({ value: ag.id, label: ag.name }))}
                block
                noSearch
                value={selectedtClient}
                onSelect={(id) => {
                  setselectedtClient(id);
                  alert(id);
                }}
              />
            </Responsive>{" "}
            <button
              onClick={() => {
                checkeds.map((id, i) =>
                  APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/ChangeClient")
                    .create({ id, clientId: selectedtClient })
                    .then((res) => {
                      if (i == checkeds.length - 1) fetch();
                    })
                    .catch((e) => {
                      console.log(e);
                    })
                );
              }}
              style={{
                color: "rgba(67,55,160,1)",
                padding: "6px 10px",
                fontSize: "12px",
                background: "rgba(67,55,160,0.1)",
                borderRadius: "4px",
              }}
            >
              <TextImageIcon /> {"  "}
              enregistrer
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      <Grid
        loading={state.loading}
        // editAction={(id) => {
        //   getBYId(id);

        //   setstate((prev) => {
        //     return { ...prev, open: true };
        //   });
        // }}
        // deleteAction={deleteAction}
        actionKey="id"
        // noAdvancedActions // for custom advanced actions
        actions={[
          {
            label: "rembourser",
            action: (dataKey) => {
              data.find((el) => el.id === dataKey).ticket.tranc === "TKTT" &&
                setrfndId(dataKey);
            },
            render: (v, id) => (
              <button
                disabled={
                  !["TKTT", "EMDS"].includes(
                    data.find((el) => el.id === id).ticket.tranc
                  )
                }
                style={{
                  color: "rgb(84,159,10)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(84,159,10,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
          {
            label: "annuler",
            action: (dataKey) => {
              data.find((el) => el.id === dataKey).ticket.tranc === "TKTT" &&
                cancel(dataKey);
            },
            render: (v, id) => (
              <button
                disabled={
                  !["TKTT", "EMDS"].includes(
                    data.find((el) => el.id === id).ticket.tranc
                  )
                }
                style={{
                  color: "rgb(236,71,40)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(236,71,40,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
          {
            label: "Changer Commission",
            action: (dataKey) => {
              setid_commission(dataKey);
            },
            render: (v, id) => (
              <button
                disabled={["CANX"].includes(
                  data.find((el) => el.id === id).ticket.tranc
                )}
                style={{
                  color: "rgb(0,169,141)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(0,169,141,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
        ]}
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take };
            });
          }}
        />
      </div>
      <OverViewWrapper
        model={model}
        open={id_overview != 0}
        handleClose={() => setid_overview(0)}
      />
      {/* <Document file={"http://localhost:3003/files/1660293924634.pdf"} /> */}
      <RFND
        refresh={fetch}
        open={rfndId}
        handleClose={() => {
          setrfndId(null);
          fetch();
        }}
      />
      <ChangeCommission
        clientId={
          data.find((el) => el.id == id_commission) &&
          data.find((el) => el.id == id_commission).clientId
        }
        id={id_commission}
        open={id_commission != 0}
        handleClose={() => setid_commission(0)}
        refresh={fetch}
      />
    </div>
  );
}
const RFND = ({ size, open, handleClose, clientId, refresh }) => {
  const [m, setm] = useState({
    id: 0,
    date: "2022-08-11T10:06:09.649Z",

    totalRefund: 0,

    commissionRefund: 0,
  });
  useEffect(() => {
    setm((p) => ({
      date: "2022-08-11T10:06:09.649Z",

      totalRefund: 0,

      commissionRefund: 0,
      id: open,
    }));
  }, [open]);
  const save = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/RFND")
      .create(m)
      .then((res) => {
        handleClose();
        refresh();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Modal
      size={size ? size : "lg"}
      overflow={false}
      open={open}
      onClose={handleClose}
    >
      <Modal.Header>
        <Modal.Title>Rembourser Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Responsive s={6} m={6} l={6} xl={6} className="p-10">
          <label>Date de remboursement:</label>
          <Input
            type="date"
            value={m.date}
            onChange={(date) => setm({ ...m, date })}
          />
        </Responsive>
        <h6>Remboursement</h6>

        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Frais de service :</label>
          <Input
            type="number"
            step="0.1"
            value={m.commissionRefund}
            onChange={(commissionRefund) => {
              setm((prev) => {
                return {
                  ...prev,
                  commissionRefund: parseFloat(commissionRefund),
                };
              });
            }}
          />
        </Responsive>

        <Responsive s={6} m={6} l={4} xl={4} className="p-10">
          <label>Montant Total à rembourser:</label>
          <Input
            type="number"
            step="0.1"
            value={m.totalRefund}
            onChange={(totalRefund) => {
              setm((prev) => {
                return {
                  ...prev,
                  totalRefund: parseFloat(totalRefund),
                };
              });
            }}
          />
        </Responsive>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={save} appearance="primary">
          rembourser
        </Button>{" "}
        <Button onClick={handleClose} appearance="subtle">
          fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const ChangeCommission = ({ open, handleClose, id, clientId, refresh }) => {
  const [commission, setcommission] = useState(0);
  const save = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/CommissionClient")
      .create({ id, commission, clientId })
      .then((res) => {
        handleClose();
        refresh();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    setcommission(0);
  }, [id]);
  return (
    <Modal size="sm" overflow={false} open={open} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Changer Commission Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Responsive s={6} m={6} l={6} xl={6} className="p-10">
          <label>Montant :</label>
          <Input
            type="number"
            value={commission}
            onChange={(commission) => setcommission(parseFloat(commission))}
          />
        </Responsive>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={save} appearance="primary">
          modifier
        </Button>{" "}
        <Button onClick={handleClose} appearance="subtle">
          fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
