import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { PackageUmrahAtom } from "../../Atoms/packageUmrah.atom";
import { useEffect } from "react";
import { PriceUmrah } from "../../Models/packageUmrahModel";
import Responsive from "../../Components/Responsive";
import { Checkbox, Input, Tag } from "rsuite";
import { roomTypes } from "../../Constants/types";
import format_number from "../../Helpers/number_formatter";

export default function Prices() {
  const [model, _setmodel] = useRecoilState(PackageUmrahAtom);
  const [data, setdata] = useState(null);

  useEffect(() => {
    let prices = [];

    let addFees = model.differentFees
      ? model.differentFees.reduce((a, b) => a + b.cost, 0)
      : 0;
    let basecost = model.packageUmrahCosts.reduce(
      (a, b) => a + b.cost * b.exchange,
      0
    );
    let oldPrices = [...model.prices];
    if (model.hotels && model.packageUmrahCosts) {
      model.hotels
        .filter((h) => !h.isMakkah)
        .forEach((hMadinah) => {
          hMadinah.arrangements.map((arr) => {
            model.hotels
              .filter((h) => h.isMakkah)
              .map((hMakkah) => {
                let p = new PriceUmrah();
                p.hotelMadinahName = hMadinah.name;
                p.hotelMakkahName = hMakkah.name;
                p.arrangementMadinahDesignation = arr.designation;
                let arr2 = hMakkah.arrangements.find(
                  (_arr) => _arr.designation == arr.designation
                );
                if (arr2) {
                  p.arrangementMakkahDesignation = arr2.designation;
                  p.baseCost =
                    addFees +
                    basecost +
                    arr.unitCost * hMadinah.duration * hMadinah.exchange +
                    arr2.unitCost * hMakkah.duration * hMakkah.exchange;
                  if (model.nbr_for_free_place)
                    p.baseCost =
                      p.baseCost * (1 + 1 / model.nbr_for_free_place);
                  let __p = oldPrices.find(
                    (_p) =>
                      _p.hotelMadinahName == p.hotelMadinahName &&
                      _p.hotelMakkahName == p.hotelMakkahName &&
                      _p.arrangementMadinahDesignation ==
                        p.arrangementMadinahDesignation &&
                      _p.arrangementMakkahDesignation ==
                        p.arrangementMakkahDesignation
                  );
                  if (__p) {
                    p.price = __p.price;
                    p.id = __p.id;
                    p.packageUmrahId = __p.packageUmrahId;
                    p.isHidden = __p.isHidden;
                  }
                  prices.push(p);
                }
              });
          });
        });
    }
    if (prices.length) groupped_by(prices);

    // groupped_by(oldPrices);
    _setmodel((prev) => ({ ...prev, prices }));
  }, [
    model.hotels,
    model.nbr_for_free_place,
    model.packageUmrahCosts,
    model.b2Bcommission,
    model.commission,
    model.differentFees,
    model.disponibilities,
  ]);
  const groupped_by = (arr) => {
    arr = arr.map((el, i) => {
      return {
        ...el,
        h: el.hotelMakkahName + " - " + el.hotelMadinahName,
      };
    });
    let eles = new Set(arr.map((el) => el.h));
    console.log(eles);
    let res = {};
    eles.forEach((el) => {
      res[el] = arr
        .filter((p) => p.h == el)
        .map((_el) => {
          delete _el.h;
          return _el;
        });
    });
    console.log(res);
    setdata(res);
  };
  return (
    <div>
      {1 == 1 && (
        <table>
          <thead>
            <tr>
              <th>-</th>
              <th>Designation</th>

              {/* <th>Cout</th> */}
              <th>Commission</th>
              {/* <th>Prix Estimé</th> */}
              {/* <th>Prix Final</th> */}
              <th>QUAD</th>
              <th>TRIPLE</th>
              <th>DOUBLE</th>
            </tr>
          </thead>
          <tbody>
            {model.hotels &&
              model.hotels.length &&
              data &&
              Object.keys(data).map((key, _j) => (
                <tr
                  style={{
                    background:
                      _j % 2 ? "rgb(244,81,108,0)" : "rgb(70,103,209,0.2)",
                    borderBottom: "3px solid #eee",
                  }}
                >
                  {/* <td>
                  {
                    <Tag
                      color={
                        ["red", "green", "yellow"][
                          roomTypes.findIndex(
                            (__el) =>
                              __el.label == p.arrangementMakkahDesignation
                          )
                        ]
                      }
                    >
                      {p.arrangementMakkahDesignation}
                    </Tag>
                  }
                </td> */}
                  <td>
                    <Checkbox
                      onChange={(v) => {
                        let prices = [...model.prices];
                        data[key].map((el) => {
                          let _index = prices.findIndex(
                            (_el) => JSON.stringify(el) == JSON.stringify(_el)
                          );
                          console.log(_index);
                          prices[_index] = {
                            ...prices[_index],
                            isHidden: !prices[_index].isHidden,
                          };
                        });
                        groupped_by(prices);
                        _setmodel((prev) => ({ ...prev, prices }));
                      }}
                      checked={
                        !model.prices.filter((el) =>
                          data[key].find(
                            (_el) => JSON.stringify(el) == JSON.stringify(_el)
                          )
                        )[0] ||
                        !model.prices.filter((el) =>
                          data[key].find(
                            (_el) => JSON.stringify(el) == JSON.stringify(_el)
                          )
                        )[0].isHidden
                        // !model.prices[data[key][0].index] ||
                        // !model.prices[data[key][0].index].isHidden
                      }
                    />
                  </td>
                  <td>
                    <h6>{key}</h6>
                  </td>

                  <td>
                    {format_number(model.b2Bcommission + model.commission)}
                  </td>
                  <td>
                    {data[key].find((el) =>
                      el.arrangementMadinahDesignation
                        .toUpperCase() 
                        .includes("QUAD") &&   el.arrangementMakkahDesignation
                        .toUpperCase()
                        .includes("QUAD")
                    ) ? (
                      <>
                        <b>
                          {" "}
                          {format_number(
                            data[key].find((el) =>
                              el.arrangementMadinahDesignation
                                .toUpperCase()
                                .includes("QUAD")
                            ).baseCost +
                              model.b2Bcommission +
                              model.commission
                          )}
                        </b>{" "}
                        <Input
                          value={
                            model.prices.find((__el) => {
                              let el = data[key].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("QUAD")
                              );
                              return (
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                              );
                            })? model.prices.find((__el) => {
                              let el = data[key].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("QUAD")
                              );
                              return (
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                              );
                            }).price:0
                          }
                          onChange={(price) => {
                            let prices = [...model.prices];
                            let el = data[key].find((el) =>
                              el.arrangementMadinahDesignation
                                .toUpperCase()
                                .includes("QUAD")
                            );
                            let _index = prices.findIndex(
                              (__el) =>
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                            );
                            prices[_index] = {
                              ...prices[_index],
                              price: parseFloat(price),
                            };

                            _setmodel((prev) => ({ ...prev, prices }));
                          }}
                        />
                      </>
                    ) : (
                      0
                    )}
                  </td>
                  <td>
                    {data[key].find((el) =>
                      el.arrangementMadinahDesignation
                        .toUpperCase()
                        .includes("TRIPLE") &&  el.arrangementMakkahDesignation
                        .toUpperCase()
                        .includes("TRIPLE")
                    ) ? (
                      <>
                        <b>
                          {format_number(
                            data[key].find((el) =>
                              el.arrangementMadinahDesignation
                                .toUpperCase()
                                .includes("TRIPLE")
                            ).baseCost +
                              model.b2Bcommission +
                              model.commission
                          )}{" "}
                        </b>
                        <Input
                          value={
                            model.prices.find((__el) => {
                              let el = data[key].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("TRIPLE")
                              );
                              return (
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                              );
                            })? model.prices.find((__el) => {
                              let el = data[key].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("TRIPLE")
                              );
                              return (
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                              );
                            }).price:0
                          }
                          onChange={(price) => {
                            let prices = [...model.prices];
                            let el = data[key].find((el) =>
                              el.arrangementMadinahDesignation
                                .toUpperCase()
                                .includes("TRIPLE")
                            );
                            let _index = prices.findIndex(
                              (__el) =>
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                            );
                            prices[_index] = {
                              ...prices[_index],
                              price: parseFloat(price),
                            };

                            _setmodel((prev) => ({ ...prev, prices }));
                          }}
                        />
                      </>
                    ) : (
                      0
                    )}
                  </td>
                  <td>
                    {data[key].find((el) =>
                      el.arrangementMadinahDesignation
                        .toUpperCase()
                        .includes("DOUBLE")
                    ) ? (
                      <>
                        <b>
                          {" "}
                          {format_number(
                            data[key].find((el) =>
                              el.arrangementMadinahDesignation
                                .toUpperCase()
                                .includes("DOUBLE")
                            ).baseCost +
                              model.b2Bcommission +
                              model.commission
                          )}{" "}
                        </b>
                        <Input
                          value={
                            model.prices.find((__el) => {
                              let el = data[key].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("DOUBLE")
                              );
                              return (
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                              );
                            })?  model.prices.find((__el) => {
                              let el = data[key].find((el) =>
                                el.arrangementMadinahDesignation
                                  .toUpperCase()
                                  .includes("DOUBLE")
                              );
                              return (
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                              );
                            }).price:0
                          }
                          onChange={(price) => {
                            let prices = [...model.prices];
                            let el = data[key].find((el) =>
                              el.arrangementMadinahDesignation
                                .toUpperCase()
                                .includes("DOUBLE")
                            );
                            let _index = prices.findIndex(
                              (__el) =>
                                __el.hotelMadinahName == el.hotelMadinahName &&
                                __el.hotelMakkahName == el.hotelMakkahName &&
                                __el.arrangementMadinahDesignation ==
                                  el.arrangementMadinahDesignation &&
                                __el.arrangementMakkahDesignation ==
                                  el.arrangementMakkahDesignation
                            );
                            prices[_index] = {
                              ...prices[_index],
                              price: parseFloat(price),
                            };

                            _setmodel((prev) => ({ ...prev, prices }));
                          }}
                        />
                      </>
                    ) : (
                      0
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {1 == 3 && (
        <table>
          <thead>
            <tr>
              <th>Designation</th>
              <th>hotel Makkah</th>
              <th>hotel Madinah</th>
              <th>Cout</th>
              <th>Commission</th>
              <th>Prix Estimé</th>
              <th>Prix Final</th>
            </tr>
          </thead>
          <tbody>
            {model.hotels &&
              model.hotels.length &&
              model.prices.map((p, i) => (
                <tr>
                  <td>
                    {
                      <Tag
                        color={
                          ["red", "green", "yellow"][
                            roomTypes.findIndex(
                              (__el) =>
                                __el.label == p.arrangementMakkahDesignation
                            )
                          ]
                        }
                      >
                        {p.arrangementMakkahDesignation}
                      </Tag>
                    }
                  </td>
                  <td>{p.hotelMakkahName}</td>
                  <td>{p.hotelMadinahName}</td>
                  <td>{format_number(p.baseCost)}</td>
                  <td>
                    {format_number(model.b2Bcommission + model.commission)}
                  </td>
                  <td>
                    {format_number(
                      model.b2Bcommission + model.commission + p.baseCost
                    )}
                  </td>
                  <td>
                    <Input
                      value={p.price}
                      onChange={(price) => {
                        let prices = [...model.prices];
                        prices[i] = { ...prices[i], price: parseFloat(price) };
                        _setmodel((prev) => ({ ...prev, prices }));
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      <style jsx>{`
        thead {
          background: rgb(70, 103, 209);
          color: #fff;
        }
        th,
        td {
          padding: 5px 8px;
        }
        th {
          text-align: left;
        }
      `}</style>
    </div>
  );
}
