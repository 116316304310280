import ImageIcon from "@rsuite/icons/Image";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button, Checkbox, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import AddEdit from "./AddEdit.component";

import { ENDPOINTS } from "../../Api/enpoints";
import { agencyAtom } from "../../Atoms/agency.atom";
import { SaleUmrahAtom } from "../../Atoms/saleOmrah.atom";
import { BASE_URL } from "../../Config/api.config";
import { ConfirmationStatus } from "../../Constants/types";
import { OrderUmrahModel } from "../../Models/orderUmrahModel";
import { multiDataSet } from "./excel_data";
import { ImCheckboxChecked } from "react-icons/im";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
import { groupsAtoms } from "../../Atoms/groups.atoms";
export default function SaleUmrah(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [clients, setclients] = useState([]);
  const [clients2, setclients2] = useState([]);
  const [tags, setTags] = useState([]);
  const [packages, setpackages] = useState([]);
  const [vols, setvols] = useState([]);
  const [vol, setvol] = useState(null);
  const [providers, setproviders] = useState([]);
  const agency = useRecoilValue(agencyAtom);
  const [data2, setdata2] = useRecoilState(groupsAtoms);
  const [checkeds, setcheckeds] = useState([]);
  const [changedisponibilityModel, setchangedisponibilityModel] = useState({
    disponibilityId: null,
    orders: [],
  });
  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    clientId: 0,
    status: 0,
    date: null,
    tagId: 0,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(SaleUmrahAtom);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new OrderUmrahModel());
    setError("");
  };
  // API CALLS
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) =>
          forFilter ? setclients(res.data) : setclients2(res.data)
        );
    }
  };

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };

  const fetchPackages = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", { q })
        .customGet()
        .then((res) => {
          setpackages(res.data.data);

          let _vols = res.data.data.reduce(
            (a, b) =>
              a.concat(
                b.disponibilities.map((el) => ({ packageUmrahId: b.id, ...el }))
              ),
            []
          );
          console.log(_vols);
          setvols(_vols.filter((d) => d.onStock));
        });
    }
  };
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
      // onlyHasSurvey: true,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    // let msg = validate(model, [
    //   { number: "Numéro" },
    //   { status: "Status" },
    //   { amount: "Montant" },
    //   { depositDate: "Date de Dépôt" },
    //   // { date: "Date" },

    //   { checkPurpose: "Type de Chéque" },
    //   // { clientId: "Client" },
    // ]);
    // if (msg) setError(msg);
    // else {
    console.log(model);

    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = {
      ...model,
    };

    delete m.client;
    delete m.disponibility;
    delete m.packageUmrah;
    delete m.price;
    if (m.id) {
      setError("");

      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
        .update2(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          if (e.response) {
            setError(e.response.data);
            Swal.fire({
              position: "top-center",
              icon: "warning",
              title: e.response.data,
              showConfirmButton: false,
              timer: 1500,
            });
          }
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      setError("");

      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          if (e.response) {
            setError(e.response.data);
            Swal.fire({
              position: "top-center",
              icon: "warning",
              title: e.response.data,
              showConfirmButton: false,
              timer: 1500,
            });
          }
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
    //}
    // }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  //
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
      .fetchById(id)
      .then((res) => {
        if (
          res.data.client &&
          !clients2.find((c) => c.id == res.data.client.id)
        ) {
          let arr = [...clients2];
          arr.unshift({ id: res.data.client.id, name: res.data.client.name });
          setclients2(arr);
        }

        setmodel((prev) => {
          let m = { ...res.data };
          m.date = m.date.substring(0, 10);
          let customer = { ...m.customer };
          // customer.expirationPassportDate =
          //   customer.expirationPassportDate.substring(0, 10);
          // customer.birthDate = customer.birthDate.substring(0, 10);
          return {
            ...m,

            customer,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // LIFE CYCLES
  useEffect(() => {
    fetchClients();
    fetchProviders();
    fetchPackages();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  const columns = [
    {
      value: "id",
      name: " ",
      render: (id) => (
        <b style={{ color: "green", display: "flex", alignItems: "center" }}>
          <Checkbox
            onChange={(v) => {
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => [...prev, id]);
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>
        </b>
      ),
    },
    {
      value: "packageUmrah",
      value2: "onlyTicket",
      value3: "withoutVisa",
      value4: "withoutTicket",
      name: "Voyage",
      render: (v, onlyTicket, withoutVisa, withoutTicket) => (
        <b style={{ color: "#549f0a" }}>
          {v ? v.name.toUpperCase() : ""}{" "}
          {onlyTicket ? <Tag color="blue">Billet</Tag> : ""}{" "}
          {withoutVisa ? <Tag color="orange">Sans Visa</Tag> : ""}
          {withoutTicket ? <Tag color="violet">Sans Billet</Tag> : ""}
        </b>
      ),
    },
    {
      value: "groupId",
      name: "Groupe",
      render: (v) => (
        <a>
          <b>
            {v && data2 && data2.find((el) => el.id == v)
              ? data2.find((el) => el.id == v).name
              : ""}
          </b>
        </a>
      ),
    },
    {
      value: "disponibility",
      name: "Vol",
      render: (c) =>
        c ? (
          <b
            style={{
              display: "flex",
              alignItems: "center",
              padding: "3px",

              borderRadius: "5px",
            }}
          >
            <img
              alt=""
              style={{ width: "30px", height: "30px", margin: "0 6px" }}
              src={c.airline ? c.airline.logo : ""}
            ></img>
            <b>
              {" "}
              <div style={{ color: "#232323" }}>
                {moment(c.date).format("DD MMM YYYY")}
              </div>
              <b style={{ color: "#888", fontWeight: "lighter" }}>
                {" "}
                {c.airline ? c.airline.name : ""}
              </b>
            </b>
          </b>
        ) : (
          <b>-</b>
        ),
    },
    {
      value: "confirmationStatus",
      name: "Etat",
      render: (v) => (
        <Tag
          color={
            v === 2 ? "violet" : v === 3 ? "green" : v === 4 ? "red" : "yellow"
          }
        >
          {ConfirmationStatus.find((el) => v === null || el.value === v)
            ? ConfirmationStatus.find((el) => v === null || el.value === v)
                .label
            : ConfirmationStatus.find((el) => el.value === 1).label}
        </Tag>
      ),
    },
    {
      value: "fingerprint",
      name: "Empreinte",
      render: (v) => (v ? <ImCheckboxChecked color="green" /> : <b>-</b>),
    },
    {
      value: "client",
      name: "Client B2B",
      render: (v) => <a>{v ? v.name.toUpperCase() : ""}</a>,
    },
    {
      value: "customer",
      name: "Client B2C",
      render: (v) => (
        <a style={{ display: "flex", alignItems: "center" }}>
          {v.picture ? (
            <img
              alt=""
              src={
                BASE_URL +
                "Uploads/CustomerPictures/" +
                v.picture +
                "?" +
                Date.now()
              }
              width="43"
              height="43"
              style={{
                border: "1px solid #eee",
                borderRadius: "50%",
                boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
              }}
            />
          ) : (
            <div
              style={{
                fontSize: "15px",
                border: "1px dashed #888",
                borderRadius: "50%",
                width: "43px",
                height: "43px",
                textAlign: "center",
                lineHeight: "42px",
              }}
            >
              <ImageIcon color="#3598ff"></ImageIcon>
            </div>
          )}
          <div style={{ padding: "0 5px" }}>
            {v.firstName + "  " + v.lastName}
            {"  "}
            <b
              style={{
                color: "#111",
                fontSize: "15px",
              }}
            >
              {v ? v.passportNumber : ""}
            </b>
            <br></br>
            <b style={{ color: "#330033" }}>
              {v.firstNameAr + "  " + v.lastNameAr}
            </b>
          </div>
        </a>
      ),
    },

    {
      value: "totalPrice",
      name: "Prix Total",
      render: (v) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {v && v.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            TND
          </span>
        </b>
      ),
    },
    {
      value: "disponibility",
      value2: "onlyTicket",
      name: "Commission B2B",
      render: (v, onlyTicket) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {!onlyTicket && v && v.b2Bcommission.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {!onlyTicket && "TND "}
          </span>
        </b>
      ),
    },
    {
      value: "date",
      name: "Date De Vente",
      render: (v) => <strong>{moment(v).format("DD MMM YYYY")}</strong>,
    },
  ];

  return (
    <div>
      <Filter search={() => fetch()}>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Période: </label>
          {/* <DatePicker
            placement="bottomStart"
            value={filterModel.date}
            onChange={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            onSelect={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            block
          /> 
        </Responsive> */}
        <Responsive m={3} l={2.4} xl={2.4} className="p-10">
          <label>Recherche: </label>
          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Client: </label>
          <SelectPicker
            onSearch={(q) => fetchClients(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              clients.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.clientId}
            onSelect={(clientId) => {
              setfilterModel((prev) => {
                return { ...prev, clientId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Status : </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(ConfirmationStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Nom Omrah: </label>
          <SelectPicker
            onSearch={fetchPackages}
            data={
              packages
                ? [{ label: "Tout", value: 0 }].concat(
                    packages.map((el) => {
                      return { label: el.name, value: el.id };
                    })
                  )
                : [{ label: "Tout", value: 0 }]
            }
            block
            noSearch
            value={filterModel.packageUmrahId}
            onSelect={(packageUmrahId) => {
              setfilterModel((prev) => {
                return { ...prev, packageUmrahId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Vol: </label>
          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              vols
                ? vols.map((el) => {
                    return {
                      label: (
                        <div style={{ color: "#232323" }}>
                          {moment(el.date).format("DD MMM YYYY")} ({" "}
                          <i style={{ color: "#999" }}>
                            {packages &&
                              packages.find((l) => l.id == el.packageUmrahId) &&
                              packages.find((l) => l.id == el.packageUmrahId)
                                .name}
                          </i>
                          )
                        </div>
                      ),
                      value: el.id,
                    };
                  })
                : []
            )}
            block
            noSearch
            value={filterModel.disponibilityId}
            onSelect={(disponibilityId) => {
              createAPIEndpoint(ENDPOINTS.Disponibility)
                .fetchById(disponibilityId)
                .then((res) => {
                  console.log(res.data);
                  setvol(res.data);
                });
              setfilterModel((prev) => {
                return { ...prev, disponibilityId };
              });
            }}
          />
        </Responsive>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Date du Vente: </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive> */}
      </Filter>
      <ExportAdd
        excelData={multiDataSet(
          data
          //.filter((el) => el.customer && el.customer.survey)
        )}
        ActionOnClose={reset}
        nameExcel="saleUmrah"
        size="lg"
        save={save}
        AddComponent={
          <AddEdit
            tags={tags}
            providers={providers}
            fetchProviders={fetchProviders}
            error={error}
            clients={clients2}
            fetchClients={(q) => fetchClients(q, false)}
            vols={vols}
            packs={packages}
          />
        }
      />{" "}
      <div
        onClick={(e) =>
          setcheckeds((prev) => (prev.length ? [] : data.map((el) => el.id)))
        }
        style={{
          display: "inline-block",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <Checkbox checked={checkeds.length > 0}></Checkbox> Sélectionner Tout
      </div>
      <div className="p-10">
        <Responsive s={6} m={6} l={4} xl={3}>
          <SelectPicker
            data={[{ label: "Selectionner", value: 0 }].concat(
              vols.map((c) => {
                return {
                  label: (
                    <b
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "3px",

                        borderRadius: "5px",
                      }}
                    >
                      <img
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          margin: "0 6px",
                        }}
                        src={c.airline ? c.airline.logo : ""}
                      ></img>
                      <b>
                        {" "}
                        <div style={{ color: "#232323" }}>
                          {moment(c.date).format("DD MMM YYYY")}
                        </div>
                        <b style={{ color: "#888", fontWeight: "lighter" }}>
                          {" "}
                          {c.airline ? c.airline.name : ""}
                        </b>
                      </b>
                    </b>
                  ),
                  value: c.id,
                };
              })
            )}
            block
            noSearch
            value={changedisponibilityModel.disponibilityId}
            onSelect={(disponibilityId) => {
              setchangedisponibilityModel((prev) => {
                return { ...prev, disponibilityId };
              });
            }}
          />
        </Responsive>{" "}
        <Button
          appearance="primary"
          color="blue"
          onClick={() => {
            console.log(checkeds);
            APi.createAPIEndpoint(ENDPOINTS.Order + "/changeDisponibility")
              .create({ ...changedisponibilityModel, orders: checkeds })
              .then((res) => {
                fetch();
                alert("success");
              });
          }}
        >
          changer
        </Button>
      </div>
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        actions={[
          {
            label: "Confirmer",
            action: (dataKey) => {
              APi.createAPIEndpoint(
                ENDPOINTS.Order + "/Umrah/confirm/" + dataKey,
                {}
              )
                .customGet()
                .then((res) => fetch());
            },
            render: (v, id) => (
              <button
                style={{
                  color: "rgb(0,169,141)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(0,169,141,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
          {
            label: "Refuser",
            action: (dataKey) => {
              APi.createAPIEndpoint(
                ENDPOINTS.Order + "/Umrah/revoke/" + dataKey,
                {}
              )
                .customGet()
                .then((res) => fetch());
            },
            render: (v, id) => (
              <button
                style={{
                  color: "rgb(229,57,53)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(229,57,53,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
          {
            label: "Rendre En Attente",
            action: (dataKey) => {
              APi.createAPIEndpoint(
                ENDPOINTS.Order + "/Umrah/pending/" + dataKey,
                {}
              )
                .customGet()
                .then((res) => fetch());
            },
            render: (v, id) => (
              <button
                style={{
                  color: "rgb(219,157,53)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(219,157,53,0.1)",
                  borderRadius: "4px",
                }}
              >
                {v}
              </button>
            ),
          },
        ]}
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
        {vol && filterModel.disponibilityId && (
          <div
            style={{ display: "inline-flex", alignItems: "center", gap: "2px" }}
          >
            <Input
              type="number"
              value={vol.adddedNumber}
              width={120}
              onChange={(adddedNumber) =>
                setvol((prev) => ({
                  ...prev,
                  adddedNumber: parseInt(adddedNumber),
                }))
              }
            />
            <Button
              style={{ width: "120px" }}
              onClick={(e) =>
                createAPIEndpoint(ENDPOINTS.Disponibility)
                  .update(vol.id, vol)
                  .then((res) => alert("Enregistré"))
              }
              appearance="primary"
              color="yellow"
            >
              Enregistrer
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
