import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Edit } from "@rsuite/icons";
import TrashIcon from "@rsuite/icons/Trash";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Divider,
  IconButton,
  Input,
  Message,
  SelectPicker,
  Steps,
  Uploader,
  TagInput,
  Tag,
} from "rsuite";
import Responsive from "../../Components/Responsive";
import { roomTypes, serviceTypes } from "../../Constants/types";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import Grid from "../../Components/Grid";
import boards from "../../Data/boards.json";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import { APi } from "../../Api/";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { default as AddCheck } from "../Check/AddEdit.component";
import { CheckModel } from "../../Models/CheckModel";
import Swal from "sweetalert2";
import moment from "moment";
import { CurrencyAtom } from "../../Atoms/currency.atom";
import PackageModel, {
  DisponobilityModel,
  ExtratModel,
} from "../../Models/Package.model";
import { modules } from "react-data-export";
import { PackageAtom } from "../../Atoms/Package.atom";
import { ExchangeAtom } from "../../Atoms/exchange.atom";
import { PackageUmrahAtom } from "../../Atoms/packageUmrah.atom";
import {
  Arrangement,
  DifferentFees,
  HotelUmrah,
  PackageUmrahCost,
  PackageUmrahMealType,
} from "../../Models/packageUmrahModel";
import format_number from "../../Helpers/number_formatter";
import Prices from "./Prices";
import Disponibilities from "./Disponibilities";
const styles = {
  // width: "200px",
  // display: "inline-table",
  verticalAlign: "top",
};
function AddEdit({
  error,
  providers,
  save,
  fetchProviders,
  isDefault = false,
}) {
  const [current, setcurrent] = useState(0);

  const [model, _setmodel] = useRecoilState(PackageUmrahAtom);
  const [costModel, setcostModel] = useState(new PackageUmrahCost());
  const [hotelMakkahModel, sethotelMakkahModel] = useState(new HotelUmrah());
  const [hotelMadinahModel, sethotelMadinahModel] = useState(new HotelUmrah());
  const currencies = useRecoilValue(CurrencyAtom);
  const exchanges = useRecoilValue(ExchangeAtom);
  return (
    <>
      <div style={{ padding: "40px 10px" }}>
        <Steps current={current} style={styles}>
          <Steps.Item title="Infos General" onClick={() => setcurrent(0)} />
          <Steps.Item title="Vols " onClick={() => setcurrent(1)} />
          <Steps.Item title="Hotel Makkah" onClick={() => setcurrent(2)} />
          <Steps.Item title="Hotel Madinah" onClick={() => setcurrent(3)} />
          <Steps.Item title="Prix" onClick={() => setcurrent(4)} />
        </Steps>
        {!current ? (
          <>
            <Responsive m={6} l={6} xl={6} className="p-10">
              <label>Nom : </label>
              <Input
                value={model.name}
                onChange={(name) => {
                  _setmodel((prev) => {
                    return { ...prev, name: name.toUpperCase() };
                  });
                }}
              />
            </Responsive>

            <Responsive s={4} m={3} l={2} xl={2} className="p-10">
              <label>Commission : </label>
              <Input
                type="number"
                step="1"
                value={model.commission}
                onChange={(commission) => {
                  _setmodel((prev) => {
                    return { ...prev, commission: parseFloat(commission) };
                  });
                }}
              />
            </Responsive>

            <Responsive s={4} m={3} l={2} xl={2} className="p-10">
              <label>Commission B2B : </label>
              <Input
                type="number"
                step="1"
                value={model.b2Bcommission}
                onChange={(b2Bcommission) => {
                  _setmodel((prev) => {
                    return {
                      ...prev,
                      b2Bcommission: parseFloat(b2Bcommission),
                    };
                  });
                }}
              />
            </Responsive>
            <Responsive s={4} m={3} l={2} xl={2} className="p-10">
              <label>Nbre pour une gratuité : </label>
              <Input
                type="number"
                step="1"
                value={model.nbr_for_free_place}
                onChange={(nbr_for_free_place) => {
                  _setmodel((prev) => {
                    return {
                      ...prev,
                      nbr_for_free_place: parseInt(nbr_for_free_place),
                    };
                  });
                }}
              />
            </Responsive>
            <div style={{ background: "rgb(70,90,238,0.2)" }} className="p-10">
              <Responsive l={5} xl={5} className="p-10">
                <label>Désignation : </label>
                <Input
                  block
                  value={costModel.designation}
                  onChange={(designation) => {
                    setcostModel((prev) => {
                      return { ...prev, designation };
                    });
                  }}
                />
              </Responsive>
              <Responsive m={1} l={1} xl={1} className="p-10">
                <label>tag : </label>
                <Input
                  value={costModel.tag}
                  onChange={(tag) => {
                    setcostModel((prev) => {
                      return { ...prev, tag: tag.toUpperCase() };
                    });
                  }}
                />
              </Responsive>
              <Responsive className="p-10" m={4} l={2.5} xl={1.8}>
                <label>fournisseur : </label>
                <SelectPicker
                  onSearch={(q) => fetchProviders(q)}
                  data={providers.map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                  block
                  noSearch
                  value={costModel.providerId}
                  onSelect={(providerId) => {
                    let currencyId = 0;
                    let exchange = 0;
                    let p = providers.find((p) => p.id === providerId);
                    if (p && p.currencies && p.currencies.length) {
                      currencyId = p.currencies[0].currencyId;
                      exchange = exchanges.find(
                        (el) => el.currencySourceId == currencyId
                      )
                        ? exchanges.find(
                            (el) => el.currencySourceId == currencyId
                          ).rate
                        : 1;
                    }
                    setcostModel((prev) => {
                      return { ...prev, providerId, currencyId, exchange };
                    });
                  }}
                />
              </Responsive>

              <Responsive s={3} m={3} l={1.5} xl={1} className="p-10">
                <label>Coût :</label>
                <Input
                  type="number"
                  step="0.1"
                  value={costModel.cost}
                  onChange={(cost) => {
                    setcostModel((prev) => {
                      return { ...prev, cost: parseFloat(cost) };
                    });
                  }}
                />
              </Responsive>
              <Responsive s={3} m={3} l={1.5} xl={1.2} className="p-10">
                <label>Devise :</label>

                <SelectPicker
                  data={
                    costModel.providerId &&
                    providers.find((p) => p.id === costModel.providerId) &&
                    providers.find((p) => p.id === costModel.providerId)
                      .currencies
                      ? providers
                          .find((p) => p.id === costModel.providerId)
                          .currencies.map((c) => {
                            return {
                              label: c.currency.name,
                              value: c.currency.id,
                            };
                          })
                      : currencies.map((c) => {
                          return { label: c.name, value: c.id };
                        })
                  }
                  block
                  noSearch
                  value={costModel.currencyId}
                  onSelect={(currencyId) => {
                    let ex = exchanges.find(
                      (el) => el.currencySourceId == currencyId
                    );

                    setcostModel((prev) => ({
                      ...prev,
                      currencyId,
                      exchange: ex ? ex.rate : 1,
                    }));
                  }}
                />
              </Responsive>
              <Responsive s={3} m={3} l={1.5} xl={0.8} className="p-10">
                <label>Cour:</label>
                <h6 className="p-10">{costModel.exchange}</h6>
              </Responsive>
              <Responsive s={3} m={3} l={1.5} xl={1} className="p-10">
                <label>Coût TND:</label>
                <h6 className="p-10">
                  {format_number(costModel.cost * costModel.exchange || 0)}
                </h6>
              </Responsive>
              <div style={{ textAlign: "right" }} className="p-10">
                <button
                  style={{ background: "rgb(70,90,238,1)", color: "#fff" }}
                  onClick={() => {
                    _setmodel((prev) => {
                      let packageUmrahCosts = [...model.packageUmrahCosts];
                      if (costModel.id || costModel.uuid) {
                        let index = model.packageUmrahCosts.findIndex(
                          (el) =>
                            (el.id && el.id === costModel.id) ||
                            (!el.id && el.uuid && el.uuid === costModel.uuid)
                        );

                        packageUmrahCosts[index] = costModel;
                      } else {
                        packageUmrahCosts.push({
                          ...costModel,
                          uuid: Date.now(),
                        });
                      }
                      return { ...prev, packageUmrahCosts };
                    });

                    setcostModel(new PackageUmrahCost());
                  }}
                >
                  {costModel.id || costModel.uuid ? "modifier" : "ajouter"}
                </button>
              </div>
              <table>
                <thead>
                  <tr style={{ background: "#454599", color: "#fff" }}>
                    <td>Désignation</td>
                    <td>TAG</td>
                    <td>Coût RYEL</td> <td>Coût TND</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {model.packageUmrahCosts &&
                    model.packageUmrahCosts
                      .filter((el) => el.designation.toUpperCase() != "VOL")
                      .map((el) => (
                        <tr style={{ color: "#454599" }}>
                          <td>{el.designation}</td>
                          <td>
                            {
                              <Tag
                                color={
                                  ["red", "green", "yellow"][
                                    [
                                      ...new Set(
                                        model.packageUmrahCosts.map(
                                          (_el) => _el.tag
                                        )
                                      ),
                                    ].findIndex((__el) => __el == el.tag)
                                  ]
                                }
                              >
                                #{el.tag}
                              </Tag>
                            }
                          </td>
                          <td>{format_number(el.cost)}</td>
                          <td>{format_number(el.cost * el.exchange)}</td>
                          <td>
                            <IconButton
                              appearance="subtle"
                              onClick={() => setcostModel(el)}
                              icon={<Edit />}
                              circle
                            />
                            <Divider vertical />
                            <IconButton
                              appearance="subtle"
                              onClick={() =>
                                _setmodel((prev) => ({
                                  ...prev,
                                  packageUmrahCosts:
                                    prev.packageUmrahCosts.filter(
                                      (el1) =>
                                        (el.id && el1.id != el.id) ||
                                        (!el.id &&
                                          el.uuid &&
                                          el1.uuid != el.uuid)
                                    ),
                                }))
                              }
                              icon={<TrashIcon />}
                              circle
                            />{" "}
                          </td>
                        </tr>
                      ))}
                  <tr>
                    <td>Total</td> <td></td>
                    <td>
                      {format_number(
                        model.packageUmrahCosts
                          .filter((el) => el.designation.toUpperCase() != "VOL")
                          .reduce((a, b) => a + b.cost, 0) || 0
                      )}
                    </td>
                    <td>
                      {format_number(
                        model.packageUmrahCosts
                          .filter((el) => el.designation.toUpperCase() != "VOL")
                          .reduce((a, b) => a + b.cost * b.exchange, 0) || 0
                      )}
                    </td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h6>
              Autres Frais{" "}
              {model.differentFees && model.differentFees.length == 0 && (
                <IconButton
                  onClick={() => {
                    let differentFees = [...model.differentFees];
                    differentFees.push(new DifferentFees());
                    _setmodel({
                      ...model,
                      differentFees,
                    });
                  }}
                  color="violet"
                  icon={<PlusRoundIcon />}
                ></IconButton>
              )}
            </h6>
            {model.differentFees &&
              model.differentFees.map((guest, i) => (
                <>
                  <Responsive m={6} l={6} xl={6} className="p-10">
                    <label>Designation :</label>
                    <Input
                      value={model.differentFees[i].designation}
                      onChange={(designation) => {
                        let differentFees = [...model.differentFees];
                        let g = { ...differentFees[i] };
                        g.designation = designation;
                        differentFees[i] = g;
                        _setmodel({
                          ...model,
                          differentFees,
                        });
                      }}
                    />
                  </Responsive>

                  <Responsive s={6} m={4} l={4} xl={4} className="p-10">
                    <label>Coût :</label>
                    <Input
                      type="number"
                      step="0.1"
                      value={model.differentFees[i].cost}
                      onChange={(cost) => {
                        let differentFees = [...model.differentFees];
                        let g = { ...differentFees[i] };
                        g.cost = parseFloat(cost);
                        differentFees[i] = g;
                        _setmodel({
                          ...model,
                          differentFees,
                        });
                      }}
                    />
                  </Responsive>

                  <Responsive s={2} m={1.6} l={1} xl={1}>
                    <div style={{ height: "42px" }}> </div>
                    <IconButton
                      onClick={() => {
                        let differentFees = [...model.differentFees];
                        differentFees = differentFees.filter((_, j) => j != i);
                        _setmodel({
                          ...model,
                          differentFees,
                        });
                      }}
                      color="violet"
                      icon={<TrashIcon />}
                    ></IconButton>

                    {i + 1 == model.differentFees.length && (
                      <IconButton
                        onClick={() => {
                          let differentFees = [...model.differentFees];
                          differentFees.push(new DifferentFees());
                          _setmodel({
                            ...model,
                            differentFees,
                          });
                        }}
                        color="violet"
                        icon={<PlusRoundIcon />}
                      ></IconButton>
                    )}
                  </Responsive>
                </>
              ))}
          </>
        ) : current == 2 ? (
          <>
            <h3>Hotels Makkah :</h3>
            <div style={{ background: "rgb(70,90,238,0.2)" }} className="p-10">
              <Responsive l={5} xl={5} className="p-10">
                <label>Hôtel : </label>
                <Input
                  block
                  value={hotelMakkahModel.name}
                  onChange={(name) => {
                    sethotelMakkahModel((prev) => {
                      return { ...prev, name };
                    });
                  }}
                />
              </Responsive>
              <Responsive className="p-10" m={6} l={3} xl={1.8}>
                <label>fournisseur : </label>
                <SelectPicker
                  value={hotelMakkahModel.providerId}
                  onSearch={(q) => fetchProviders(q)}
                  data={providers.map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                  block
                  noSearch
                  // value={model.providerId}
                  onSelect={(providerId) => {
                    sethotelMakkahModel((prev) => {
                      return { ...prev, providerId };
                    });
                  }}
                />
              </Responsive>
              <Responsive s={6} m={4} l={2.5} xl={1.4} className="p-10">
                <label>Devise :</label>

                <SelectPicker
                  // onSearch={(q) => fetchClients(q)}
                  data={
                    hotelMakkahModel.providerId &&
                    providers.find((p) => p.id === hotelMakkahModel.providerId)
                      ? providers
                          .find((p) => p.id === hotelMakkahModel.providerId)
                          .currencies.map((c) => {
                            return {
                              label: c.currency.name,
                              value: c.currency.id,
                            };
                          })
                      : currencies.map((c) => {
                          return { label: c.name, value: c.id };
                        })
                  }
                  block
                  noSearch
                  value={hotelMakkahModel.currencyId}
                  onSelect={(currencyId) => {
                    let ex = exchanges.find(
                      (el) => el.currencySourceId == currencyId
                    );

                    sethotelMakkahModel((prev) => ({
                      ...prev,
                      currencyId,
                      exchange: ex ? ex.rate : 1,
                    }));
                  }}
                />
              </Responsive>
              <Responsive s={3} m={3} l={1.5} xl={1} className="p-10">
                <label>Cour:</label>
                <h6 className="p-10">{hotelMakkahModel.exchange}</h6>
              </Responsive>
              <Responsive s={6} m={6} l={2} xl={2} className="p-10">
                <label>durée :</label>
                <Input
                  type="number"
                  step="1"
                  value={hotelMakkahModel.duration}
                  onChange={(duration) => {
                    sethotelMakkahModel((prev) => {
                      return { ...prev, duration: parseInt(duration) };
                    });
                  }}
                />
              </Responsive>
              <hr></hr>

              <h6>
                Arrangements{" "}
                {hotelMakkahModel.arrangements.length == 0 && (
                  <IconButton
                    onClick={() => {
                      let arrangements = [...hotelMakkahModel.arrangements];
                      arrangements.push(new Arrangement());
                      sethotelMakkahModel({
                        ...hotelMakkahModel,
                        arrangements,
                      });
                    }}
                    color="violet"
                    icon={<PlusRoundIcon />}
                  ></IconButton>
                )}
              </h6>
              {hotelMakkahModel.arrangements &&
                hotelMakkahModel.arrangements.map((guest, i) => (
                  <div>
                    {" "}
                    <Responsive className="p-10" m={6} l={4} xl={4}>
                      <label>Type du Chambre: </label>
                      <SelectPicker
                        data={roomTypes}
                        block
                        noSearch
                        value={hotelMakkahModel.arrangements[i].designation}
                        onChange={(designation) => {
                          let arrangements = [...hotelMakkahModel.arrangements];
                          let g = { ...arrangements[i] };
                          g.designation = designation;
                          arrangements[i] = g;
                          sethotelMakkahModel({
                            ...hotelMakkahModel,
                            arrangements,
                          });
                        }}
                      />
                    </Responsive>
                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Coût :</label>
                      <Input
                        type="number"
                        step="0.1"
                        value={hotelMakkahModel.arrangements[i].cost}
                        onChange={(cost) => {
                          let arrangements = [...hotelMakkahModel.arrangements];
                          let g = { ...arrangements[i] };
                          g.cost = parseFloat(cost);
                          g.unitCost =
                            g.designation == "Chambre quadruple"
                              ? g.cost / 4
                              : g.designation == "Chambre triple"
                              ? g.cost / 3
                              : g.cost / 2;
                          arrangements[i] = g;
                          sethotelMakkahModel({
                            ...hotelMakkahModel,
                            arrangements,
                          });
                        }}
                      />
                    </Responsive>
                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Coût Par Personne :</label>
                      <Input
                        type="number"
                        step="0.1"
                        disabled
                        value={hotelMakkahModel.arrangements[i].unitCost}
                      />
                    </Responsive>
                    <Responsive s={6} m={4} l={2} xl={1.4} className="p-10">
                      <label>Coût Total TND:</label>
                      <h6 className="p-10">
                        {" "}
                        {format_number(
                          hotelMakkahModel.arrangements[i].unitCost *
                            hotelMakkahModel.exchange *
                            hotelMakkahModel.duration || 0
                        )}
                      </h6>
                    </Responsive>
                    <Responsive s={2} m={1.6} l={1} xl={1}>
                      <div style={{ height: "42px" }}> </div>
                      <IconButton
                        onClick={() => {
                          let arrangements = [...hotelMakkahModel.arrangements];
                          arrangements = arrangements.filter((_, j) => j != i);
                          sethotelMakkahModel({
                            ...hotelMakkahModel,
                            arrangements,
                          });
                        }}
                        color="violet"
                        icon={<TrashIcon />}
                      ></IconButton>

                      {i + 1 == hotelMakkahModel.arrangements.length && (
                        <IconButton
                          onClick={() => {
                            let arrangements = [
                              ...hotelMakkahModel.arrangements,
                            ];
                            arrangements.push(new Arrangement());
                            sethotelMakkahModel({
                              ...hotelMakkahModel,
                              arrangements,
                            });
                          }}
                          color="violet"
                          icon={<PlusRoundIcon />}
                        ></IconButton>
                      )}
                    </Responsive>
                  </div>
                ))}
              <hr />

              <h6>
                Repas à l'hôtel :{" "}
                {hotelMakkahModel.packageUmrahMealTypes.length == 0 && (
                  <IconButton
                    onClick={() => {
                      let packageUmrahMealTypes = [
                        ...hotelMakkahModel.packageUmrahMealTypes,
                      ];
                      packageUmrahMealTypes.push(new PackageUmrahMealType());
                      sethotelMakkahModel({
                        ...hotelMakkahModel,
                        packageUmrahMealTypes,
                      });
                    }}
                    color="violet"
                    icon={<PlusRoundIcon />}
                  ></IconButton>
                )}
              </h6>
              {hotelMakkahModel.packageUmrahMealTypes &&
                hotelMakkahModel.packageUmrahMealTypes.map((guest, i) => (
                  <>
                    <Responsive m={6} l={5} xl={5} className="p-10">
                      <label>designation:</label>
                      <Input
                        value={
                          hotelMakkahModel.packageUmrahMealTypes[i].designation
                        }
                        onChange={(designation) => {
                          let packageUmrahMealTypes = [
                            ...hotelMakkahModel.packageUmrahMealTypes,
                          ];
                          let g = { ...packageUmrahMealTypes[i] };
                          g.designation = designation;
                          packageUmrahMealTypes[i] = g;
                          sethotelMakkahModel({
                            ...hotelMakkahModel,
                            packageUmrahMealTypes,
                          });
                        }}
                      />
                    </Responsive>

                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Coût :</label>
                      <Input
                        type="number"
                        step="0.1"
                        value={hotelMakkahModel.packageUmrahMealTypes[i].cost}
                        onChange={(cost) => {
                          let packageUmrahMealTypes = [
                            ...hotelMakkahModel.packageUmrahMealTypes,
                          ];
                          let g = { ...packageUmrahMealTypes[i] };
                          g.cost = parseFloat(cost);
                          packageUmrahMealTypes[i] = g;
                          sethotelMakkahModel({
                            ...hotelMakkahModel,
                            packageUmrahMealTypes,
                          });
                        }}
                      />
                    </Responsive>
                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Prix TND:</label>
                      <h6>
                        {format_number(
                          hotelMakkahModel.packageUmrahMealTypes[i].cost *
                            hotelMakkahModel.duration *
                            hotelMakkahModel.exchange
                        )}
                      </h6>
                    </Responsive>
                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Prix Total :</label>
                      <Input
                        type="number"
                        step="0.1"
                        value={
                          hotelMakkahModel.packageUmrahMealTypes[i].totalPrice
                        }
                        onChange={(totalPrice) => {
                          let packageUmrahMealTypes = [
                            ...hotelMakkahModel.packageUmrahMealTypes,
                          ];
                          let g = { ...packageUmrahMealTypes[i] };
                          g.totalPrice = parseFloat(totalPrice);
                          packageUmrahMealTypes[i] = g;
                          sethotelMakkahModel({
                            ...hotelMakkahModel,
                            packageUmrahMealTypes,
                          });
                        }}
                      />
                    </Responsive>
                    <Responsive s={2} m={1.6} l={1} xl={1}>
                      <div style={{ height: "42px" }}> </div>
                      <IconButton
                        onClick={() => {
                          let packageUmrahMealTypes = [
                            ...hotelMakkahModel.packageUmrahMealTypes,
                          ];
                          packageUmrahMealTypes = packageUmrahMealTypes.filter(
                            (_, j) => j != i
                          );
                          sethotelMakkahModel({
                            ...hotelMakkahModel,
                            packageUmrahMealTypes,
                          });
                        }}
                        color="violet"
                        icon={<TrashIcon />}
                      ></IconButton>

                      {i + 1 ==
                        hotelMakkahModel.packageUmrahMealTypes.length && (
                        <IconButton
                          onClick={() => {
                            let packageUmrahMealTypes = [
                              ...hotelMakkahModel.packageUmrahMealTypes,
                            ];
                            packageUmrahMealTypes.push(
                              new PackageUmrahMealType()
                            );
                            sethotelMakkahModel({
                              ...hotelMakkahModel,
                              packageUmrahMealTypes,
                            });
                          }}
                          color="violet"
                          icon={<PlusRoundIcon />}
                        ></IconButton>
                      )}
                    </Responsive>
                  </>
                ))}
              <div style={{ textAlign: "right" }} className="p-10">
                <button
                  style={{ background: "rgb(70,90,238,1)", color: "#fff" }}
                  onClick={() => {
                    _setmodel((prev) => {
                      let prices = [...prev.prices];

                      let hotels = [...model.hotels];
                      if (hotelMakkahModel.id || hotelMakkahModel.uuid) {
                        for (let i = 0; i < prices.length; i++) {
                          let p = { ...prices[i] };
                          if (
                            p.hotelMakkahName ==
                            prev.hotels.find(
                              (el) =>
                                hotelMakkahModel.id == el.id ||
                                el.id == hotelMakkahModel.uuid
                            ).name
                          ) {
                            p.hotelMakkahName = hotelMakkahModel.name;
                          }
                          prices[i] = p;
                        }
                        let index = hotels.findIndex(
                          (el) =>
                            (el.id && el.id === hotelMakkahModel.id) ||
                            (el.uuid && el.uuid === hotelMakkahModel.uuid)
                        );

                        hotels[index] = hotelMakkahModel;
                      } else {
                        hotels.push({
                          ...hotelMakkahModel,
                          uuid: Date.now(),
                          isMakkah: true,
                        });
                      }
                      return { ...prev, hotels, prices };
                    });

                    sethotelMakkahModel(new HotelUmrah());
                  }}
                >
                  {hotelMakkahModel.id || hotelMakkahModel.uuid
                    ? "modifier"
                    : "ajouter"}
                </button>
              </div>
              <table>
                <thead>
                  <tr style={{ background: "#454599", color: "#fff" }}>
                    <td>Nom</td>

                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {model.hotels
                    .filter((h) => h.isMakkah)
                    .map((el) => (
                      <tr style={{ color: "#454599" }}>
                        <td>{el.name}</td>
                        <td>
                          <IconButton
                            appearance="subtle"
                            onClick={() => sethotelMakkahModel(el)}
                            icon={<Edit />}
                            circle
                          />
                          <Divider vertical />
                          <IconButton
                            appearance="subtle"
                            onClick={() =>
                              _setmodel((prev) => ({
                                ...prev,
                                hotels: prev.hotels.filter(
                                  (el1) =>
                                    (el.id && el1.id != el.id) ||
                                    (el.uuid && el1.uuid != el.uuid)
                                ),
                              }))
                            }
                            icon={<TrashIcon />}
                            circle
                          />{" "}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : current == 3 ? (
          <>
            <h3>Hotels Madinah :</h3>
            <div style={{ background: "rgb(70,90,238,0.2)" }} className="p-10">
              <Responsive l={5} xl={5} className="p-10">
                <label>Hôtel : </label>
                <Input
                  block
                  value={hotelMadinahModel.name}
                  onChange={(name) => {
                    sethotelMadinahModel((prev) => {
                      return { ...prev, name };
                    });
                  }}
                />
              </Responsive>
              <Responsive className="p-10" m={6} l={3} xl={1.8}>
                <label>fournisseur : </label>
                <SelectPicker
                  value={hotelMadinahModel.providerId}
                  key="__"
                  onSearch={(q) => fetchProviders(q)}
                  data={providers.map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                  block
                  noSearch
                  // value={model.providerId}
                  onSelect={(providerId) => {
                    sethotelMadinahModel((prev) => {
                      return { ...prev, providerId };
                    });
                  }}
                />
              </Responsive>
              <Responsive s={6} m={4} l={2.5} xl={1.4} className="p-10">
                <label>Devise :</label>

                <SelectPicker
                  key="hotelMadinahModel.currencyId"
                  // onSearch={(q) => fetchClients(q)}
                  data={
                    hotelMadinahModel.providerId &&
                    providers.find((p) => p.id === hotelMadinahModel.providerId)
                      ? providers
                          .find((p) => p.id === hotelMadinahModel.providerId)
                          .currencies.map((c) => {
                            return {
                              label: c.currency.name,
                              value: c.currency.id,
                            };
                          })
                      : currencies.map((c) => {
                          return { label: c.name, value: c.id };
                        })
                  }
                  block
                  noSearch
                  value={hotelMadinahModel.currencyId}
                  onSelect={(currencyId) => {
                    let ex = exchanges.find(
                      (el) => el.currencySourceId == currencyId
                    );

                    sethotelMadinahModel((prev) => ({
                      ...prev,
                      currencyId,
                      exchange: ex ? ex.rate : 1,
                    }));
                  }}
                />
              </Responsive>
              <Responsive s={3} m={3} l={1.5} xl={1} className="p-10">
                <label>Cour:</label>
                <h6 className="p-10">{hotelMadinahModel.exchange}</h6>
              </Responsive>
              <Responsive s={6} m={6} l={2} xl={2} className="p-10">
                <label>durée :</label>
                <Input
                  type="number"
                  step="1"
                  value={hotelMadinahModel.duration}
                  onChange={(duration) => {
                    sethotelMadinahModel((prev) => {
                      return { ...prev, duration: parseInt(duration) };
                    });
                  }}
                />
              </Responsive>
              <hr></hr>

              <h6>
                Arrangements{" "}
                {hotelMadinahModel.arrangements.length == 0 && (
                  <IconButton
                    onClick={() => {
                      let arrangements = [...hotelMadinahModel.arrangements];
                      arrangements.push(new Arrangement());
                      sethotelMadinahModel({
                        ...hotelMadinahModel,
                        arrangements,
                      });
                    }}
                    color="violet"
                    icon={<PlusRoundIcon />}
                  ></IconButton>
                )}
              </h6>
              {hotelMadinahModel.arrangements &&
                hotelMadinahModel.arrangements.map((guest, i) => (
                  <div>
                    {" "}
                    <Responsive className="p-10" m={6} l={4} xl={4}>
                      <label>Type du Chambre: </label>
                      <SelectPicker
                        data={roomTypes}
                        block
                        noSearch
                        value={hotelMadinahModel.arrangements[i].designation}
                        onChange={(designation) => {
                          let arrangements = [
                            ...hotelMadinahModel.arrangements,
                          ];
                          let g = { ...arrangements[i] };
                          g.designation = designation;
                          arrangements[i] = g;
                          sethotelMadinahModel({
                            ...hotelMadinahModel,
                            arrangements,
                          });
                        }}
                      />
                    </Responsive>
                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Coût :</label>
                      <Input
                        type="number"
                        step="0.1"
                        value={hotelMadinahModel.arrangements[i].cost}
                        onChange={(cost) => {
                          let arrangements = [
                            ...hotelMadinahModel.arrangements,
                          ];
                          let g = { ...arrangements[i] };
                          g.cost = parseFloat(cost);
                          g.unitCost =
                            g.designation == "Chambre quadruple"
                              ? g.cost / 4
                              : g.designation == "Chambre triple"
                              ? g.cost / 3
                              : g.cost / 2;
                          arrangements[i] = g;
                          sethotelMadinahModel({
                            ...hotelMadinahModel,
                            arrangements,
                          });
                        }}
                      />
                    </Responsive>
                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Coût Par Personne :</label>
                      <Input
                        type="number"
                        step="0.1"
                        disabled
                        value={hotelMadinahModel.arrangements[i].unitCost}
                      />
                    </Responsive>
                    <Responsive s={6} m={4} l={2} xl={1.4} className="p-10">
                      <label>Coût Total TND:</label>
                      <h6 className="p-10">
                        {" "}
                        {format_number(
                          hotelMadinahModel.arrangements[i].unitCost *
                            hotelMadinahModel.exchange *
                            hotelMadinahModel.duration || 0
                        )}
                      </h6>
                    </Responsive>
                    <Responsive s={2} m={1.6} l={1} xl={1}>
                      <div style={{ height: "42px" }}> </div>
                      <IconButton
                        onClick={() => {
                          let arrangements = [
                            ...hotelMadinahModel.arrangements,
                          ];
                          arrangements = arrangements.filter((_, j) => j != i);
                          sethotelMadinahModel({
                            ...hotelMadinahModel,
                            arrangements,
                          });
                        }}
                        color="violet"
                        icon={<TrashIcon />}
                      ></IconButton>

                      {i + 1 == hotelMadinahModel.arrangements.length && (
                        <IconButton
                          onClick={() => {
                            let arrangements = [
                              ...hotelMadinahModel.arrangements,
                            ];
                            arrangements.push(new Arrangement());
                            sethotelMadinahModel({
                              ...hotelMadinahModel,
                              arrangements,
                            });
                          }}
                          color="violet"
                          icon={<PlusRoundIcon />}
                        ></IconButton>
                      )}
                    </Responsive>
                  </div>
                ))}
              <hr />

              <h6>
                Repas à l'hôtel :{" "}
                {hotelMadinahModel.packageUmrahMealTypes.length == 0 && (
                  <IconButton
                    onClick={() => {
                      let packageUmrahMealTypes = [
                        ...hotelMadinahModel.packageUmrahMealTypes,
                      ];
                      packageUmrahMealTypes.push(new PackageUmrahMealType());
                      sethotelMadinahModel({
                        ...hotelMadinahModel,
                        packageUmrahMealTypes,
                      });
                    }}
                    color="violet"
                    icon={<PlusRoundIcon />}
                  ></IconButton>
                )}
              </h6>
              {hotelMadinahModel.packageUmrahMealTypes &&
                hotelMadinahModel.packageUmrahMealTypes.map((guest, i) => (
                  <>
                    <Responsive m={6} l={5} xl={5} className="p-10">
                      <label>designation:</label>
                      <Input
                        value={
                          hotelMadinahModel.packageUmrahMealTypes[i].designation
                        }
                        onChange={(designation) => {
                          let packageUmrahMealTypes = [
                            ...hotelMadinahModel.packageUmrahMealTypes,
                          ];
                          let g = { ...packageUmrahMealTypes[i] };
                          g.designation = designation;
                          packageUmrahMealTypes[i] = g;
                          sethotelMadinahModel({
                            ...hotelMadinahModel,
                            packageUmrahMealTypes,
                          });
                        }}
                      />
                    </Responsive>

                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Coût :</label>
                      <Input
                        type="number"
                        step="0.1"
                        value={hotelMadinahModel.packageUmrahMealTypes[i].cost}
                        onChange={(cost) => {
                          let packageUmrahMealTypes = [
                            ...hotelMadinahModel.packageUmrahMealTypes,
                          ];
                          let g = { ...packageUmrahMealTypes[i] };
                          g.cost = parseFloat(cost);
                          packageUmrahMealTypes[i] = g;
                          sethotelMadinahModel({
                            ...hotelMadinahModel,
                            packageUmrahMealTypes,
                          });
                        }}
                      />
                    </Responsive>
                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Prix TND:</label>
                      <h6>
                        {format_number(
                          hotelMadinahModel.packageUmrahMealTypes[i].cost *
                            hotelMadinahModel.duration *
                            hotelMadinahModel.exchange
                        )}
                      </h6>
                    </Responsive>
                    <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
                      <label>Prix Total :</label>
                      <Input
                        type="number"
                        step="0.1"
                        value={
                          hotelMadinahModel.packageUmrahMealTypes[i].totalPrice
                        }
                        onChange={(totalPrice) => {
                          let packageUmrahMealTypes = [
                            ...hotelMadinahModel.packageUmrahMealTypes,
                          ];
                          let g = { ...packageUmrahMealTypes[i] };
                          g.totalPrice = parseFloat(totalPrice);
                          packageUmrahMealTypes[i] = g;
                          sethotelMadinahModel({
                            ...hotelMadinahModel,
                            packageUmrahMealTypes,
                          });
                        }}
                      />
                    </Responsive>
                    <Responsive s={2} m={1.6} l={1} xl={1}>
                      <div style={{ height: "42px" }}> </div>
                      <IconButton
                        onClick={() => {
                          let packageUmrahMealTypes = [
                            ...hotelMadinahModel.packageUmrahMealTypes,
                          ];
                          packageUmrahMealTypes = packageUmrahMealTypes.filter(
                            (_, j) => j != i
                          );
                          sethotelMadinahModel({
                            ...hotelMadinahModel,
                            packageUmrahMealTypes,
                          });
                        }}
                        color="violet"
                        icon={<TrashIcon />}
                      ></IconButton>

                      {i + 1 ==
                        hotelMadinahModel.packageUmrahMealTypes.length && (
                        <IconButton
                          onClick={() => {
                            let packageUmrahMealTypes = [
                              ...hotelMadinahModel.packageUmrahMealTypes,
                            ];
                            packageUmrahMealTypes.push(
                              new PackageUmrahMealType()
                            );
                            sethotelMadinahModel({
                              ...hotelMadinahModel,
                              packageUmrahMealTypes,
                            });
                          }}
                          color="violet"
                          icon={<PlusRoundIcon />}
                        ></IconButton>
                      )}
                    </Responsive>
                  </>
                ))}
              <div style={{ textAlign: "right" }} className="p-10">
                <button
                  style={{ background: "rgb(70,90,238,1)", color: "#fff" }}
                  onClick={() => {
                    _setmodel((prev) => {
                      let prices = [...prev.prices];
                      let hotels = [...model.hotels];
                      if (hotelMadinahModel.id || hotelMadinahModel.uuid) {
                        for (let i = 0; i < prices.length; i++) {
                          let p = { ...prices[i] };
                          if (
                            p.hotelMadinahName ==
                            prev.hotels.find(
                              (el) =>
                                hotelMadinahModel.id == el.id ||
                                el.id == hotelMadinahModel.uuid
                            ).name
                          ) {
                            p.hotelMadinahName = hotelMadinahModel.name;
                          }
                          prices[i] = p;
                        }
                        let index = hotels.findIndex(
                          (el) =>
                            (el.id && el.id === hotelMadinahModel.id) ||
                            (el.uuid && el.uuid === hotelMadinahModel.uuid)
                        );

                        hotels[index] = hotelMadinahModel;
                      } else {
                        hotels.push({
                          ...hotelMadinahModel,
                          uuid: Date.now(),
                        });
                      }
                      return { ...prev, hotels };
                    });

                    sethotelMadinahModel(new HotelUmrah());
                  }}
                >
                  {hotelMadinahModel.id || hotelMadinahModel.uuid
                    ? "modifier"
                    : "ajouter"}
                </button>
              </div>
              <table>
                <thead>
                  <tr style={{ background: "#454599", color: "#fff" }}>
                    <td>Nom</td>

                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {model.hotels
                    .filter((h) => !h.isMakkah)
                    .map((el) => (
                      <tr style={{ color: "#454599" }}>
                        <td>{el.name}</td>
                        <td>
                          <IconButton
                            appearance="subtle"
                            onClick={() => sethotelMadinahModel(el)}
                            icon={<Edit />}
                            circle
                          />
                          <Divider vertical />
                          <IconButton
                            appearance="subtle"
                            onClick={() =>
                              _setmodel((prev) => ({
                                ...prev,
                                hotels: prev.hotels.filter(
                                  (el1) =>
                                    (el.id && el1.id != el.id) ||
                                    (el.uuid && el1.uuid != el.uuid)
                                ),
                              }))
                            }
                            icon={<TrashIcon />}
                            circle
                          />{" "}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        ) : current == 4 ? (
          <>
            <h2>Prix</h2>
            <Prices />
          </>
        ) : (
          <Disponibilities />
        )}
      </div>
      {isDefault && (
        <div style={{ textAlign: "right" }}>
          <button onClick={save}>Enregistrer</button>
        </div>
      )}
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
      <Divider />
    </>
  );
}

export default AddEdit;

// -----------------------------------
