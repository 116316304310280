import React, { useEffect, useState } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { Button, Checkbox, DatePicker, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { paymentType, SaleStatus, serviceTypes } from "../../Constants/types";
import validate from "../../Helpers/validate";
import AddEdit from "./AddEdit.component";
import { multiDataSet } from "./excel_data";
import moment from "moment";
import { FaBed, FaHotel } from "react-icons/fa";
import { InvoiceService } from "../../Api/invoice.api";
import { agencyAtom } from "../../Atoms/agency.atom";
import { BASE_URL_INVOICE } from "../../Config/api.invoice";
import { SaleHostingState } from "../../Atoms/hosting.atom";
import HostingModel from "../../Models/HostingModel";
import ResumeCard from "../../Components/ResumeCard";
export default function SaleHosting(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [clients, setclients] = useState([]);
  const [clients2, setclients2] = useState([]);
  const [tags, setTags] = useState([]);
  const [providers, setproviders] = useState([]);
  const agency = useRecoilValue(agencyAtom);
  const [totalCount, settotalCount] = useState(0);
  const [totalSale, settotalSale] = useState(0);
  const [totalRefund, settotalRefund] = useState(0);
  const [totalCommission, settotalCommission] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    clientId: 0,
    status: 0,
    date: null,
    tagId: 0,
  });
  const [checkeds, setcheckeds] = useState([]);
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(SaleHostingState);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new HostingModel());
    setError("");
  };
  // API CALLS
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) =>
          forFilter ? setclients(res.data) : setclients2(res.data)
        );
    }
  };

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };
  const fetchTags = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Tag, { q }, "/autocomplete")
        .customGet()
        .then((res) => setTags(res.data));
    }
  };
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Hosting", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
        settotalSale(res.data.totalSale);
        settotalRefund(res.data.totalRefund);
        settotalCommission(res.data.totalCommission);
      })
      .catch((e) => setError(e.Message));
  };

  const save = () => {
    // let msg = validate(model, [
    //   { number: "Numéro" },
    //   { status: "Status" },
    //   { amount: "Montant" },
    //   { depositDate: "Date de Dépôt" },
    //   // { date: "Date" },

    //   { checkPurpose: "Type de Chéque" },
    //   // { clientId: "Client" },
    // ]);
    // if (msg) setError(msg);
    // else {
    console.log(model);

    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = {
      ...model,
    };
    m.customer = m.hotelReservation.guests[0];
    m.totalPrice =
      (1 + parseFloat(m.commissionPercent) / 100) *
      (parseFloat(m.commission) + parseFloat(m.fare));
    // delete m.tagSales;
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Hosting")
        .update(m.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      console.log(m);

      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Hosting")
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
    // }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Hosting")
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  //
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.Order)
      .fetchById(id)
      .then((res) => {
        if (
          res.data.client &&
          !clients2.find((c) => c.id == res.data.client.id)
        ) {
          let arr = [...clients2];
          arr.unshift({ id: res.data.client.id, name: res.data.client.name });
          setclients2(arr);
        }
        setmodel({
          ...res.data,
          date: res.data.date ? res.data.date.substring(0, 10) : new Date(),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns = [
    {
      value: "ref",
      name: "Référence",
      render: (v) => <a href="#"> {v}</a>,
    },
    {
      value2: "client",
      value: "id",
      name: "Client",
      render: (id, v) => (
        <div>
          {/* <Checkbox
            onChange={(v) => {
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => [...prev, id]);
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>{" "} */}
          {v ? v.name.toUpperCase() : ""}
        </div>
      ),
    },
    {
      value: "customer",
      name: "Résident",
      render: (v) => (
        <b>
          {" "}
          <a>
            {v ? v.name.toUpperCase() : ""}
            {/* <br></br> <i style={{ color: "#aaa" }}>{v ? v.passportNumber : ""}</i> */}
          </a>
        </b>
      ),
    },
    {
      value: "totalPrice",
      value2: "currency",
      name: "Prix Total",
      render: (v, v2) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {v.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {v2 ? v2.code : ""}
          </span>
        </b>
      ),
    },

    {
      value: "date",
      name: "Date",
      render: (v) => <strong>{moment(v).format("DD MMM YYYY")}</strong>,
    },
    {
      value: "hotelReservation",
      name: "Détails",
      render: (v) => (
        <strong>
          <span style={{ display: "flex", alignItems: "center" }}>
            <FaHotel color="#aaa" />
            <span style={{ marginLeft: "5px" }}>
              {" "}
              {v ? v.hotelName.toUpperCase() : ""}
            </span>
          </span>
          <i style={{ color: "#4667d1" }}>{v ? v.board : ""}</i>
        </strong>
      ),
    },
    {
      value: "hotelReservation",
      name: "Nbre Nuitées",
      render: (v) => (
        <strong>
          <span style={{ display: "", alignItems: "center" }}>
            {"de "} <i>{moment(v.checkIn).format("DD MMM YYYY")}</i> {" à "}
            <i>{moment(v.checkOut).format("DD MMM YYYY")}</i>
          </span>
          <br></br>
          <Tag color="orange">
            <FaBed color="#fff" />
            <span style={{ marginLeft: "5px" }}>
              {moment(v.checkOut).diff(moment(v.checkIn), "days")} nuitée
              {moment(v.checkOut).diff(moment(v.checkIn), "days") > 1
                ? "s"
                : ""}
            </span>
          </Tag>
        </strong>
      ),
    },
    {
      value: "status",
      name: "Status",
      render: (v) => (
        <Tag
          color={
            v == 1
              ? "blue"
              : v == 2
              ? "green"
              : v == 3
              ? "violet"
              : v == 4
              ? "black"
              : "yellow"
          }
        >
          {SaleStatus.find((el) => el.value == v) &&
            SaleStatus.find((el) => el.value == v).label}
        </Tag>
      ),
    },
  ];

  // LIFE CYCLES
  useEffect(() => {
    fetch();
    fetchClients();
    fetchTags();
    fetchProviders();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter
        search={() => {
          fetch();
        }}
      >
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Client: </label>
          <SelectPicker
            onSearch={(q) => fetchClients(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              clients.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.clientId}
            onSelect={(clientId) => {
              setfilterModel((prev) => {
                return { ...prev, clientId };
              });
            }}
          />
        </Responsive>

        {/* <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Tags: </label>
          <SelectPicker
            onSearch={fetchTags}
            data={[{ label: "Tout", value: 0 }].concat(
              tags.map((el) => {
                return { label: el.name, value: el.id };
              })
            )}
            block
            noSearch
            value={filterModel.tagId}
            onSelect={(tagId) => {
              setfilterModel((prev) => {
                return { ...prev, tagId };
              });
            }}
          />
        </Responsive> */}

        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Date du Vente: </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        //    excelData={multiDataSet(data)}
        nameExcel="sale"
        size="lg"
        save={save}
        ActionOnClose={reset}
        AddComponent={
          <AddEdit
            tags={tags}
            fetchTags={fetchTags}
            providers={providers}
            fetchProviders={fetchProviders}
            error={error}
            clients={clients2}
            fetchClients={(q) => fetchClients(q, false)}
          />
        }
      />
      <div>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard
            icon={<FaHotel />}
            notAmount
            text="Nbre de Vente"
            color="245,195,35"
            amount={totalCount}
          />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total Vente" color="0,169,141" amount={totalSale} />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard
            text="Total Remboursement"
            amount={totalRefund}
            color="229,57,53"
          />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total Commission" amount={totalCommission} />
        </Responsive>
      </div>
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}
