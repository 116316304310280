import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Divider,
  IconButton,
  Input,
  Message,
  SelectPicker,
  Steps,
  Uploader,
  TagInput,
} from "rsuite";
import { saleSate } from "../../Atoms/sale.atom";
import Responsive from "../../Components/Responsive";
import { serviceTypes } from "../../Constants/types";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import Grid from "../../Components/Grid";
import boards from "../../Data/boards.json";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import TrashIcon from "@rsuite/icons/Trash";
import { APi } from "../../Api/";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { default as AddCheck } from "../Check/AddEdit.component";
import { CheckModel } from "../../Models/CheckModel";
import validate from "../../Helpers/validate";
import Swal from "sweetalert2";
import moment from "moment";
import { CurrencyAtom } from "../../Atoms/currency.atom";
import PackageModel, {
  DisponobilityModel,
  ExtratModel,
} from "../../Models/Package.model";
import { modules } from "react-data-export";
import { PackageAtom } from "../../Atoms/Package.atom";
import { ExchangeAtom } from "../../Atoms/exchange.atom";
import { Airlines } from "../../Atoms/airlines.atom";
const styles = {
  // width: "200px",
  // display: "inline-table",
  verticalAlign: "top",
};
function AddEdit({ error, providers, fetchProviders }) {
  const [current, setcurrent] = useState(0);
  const [model, _setmodel] = useRecoilState(PackageAtom);

  return (
    <>
      <Steps current={current} style={styles}>
        <Steps.Item title="Infos Génerales" onClick={() => setcurrent(0)} />
        <Steps.Item title="Prix" onClick={() => setcurrent(1)} />
        {/* <Steps.Item title="Disponibilités" onClick={() => setcurrent(2)} /> */}
        <Steps.Item title="Attachments" onClick={() => setcurrent(3)} />
      </Steps>

      <div style={{ padding: "40px 10px" }}>
        {!current ? (
          <>
            <Responsive m={6} l={6} xl={6} className="p-10">
              <label>Nom : </label>
              <Input
                value={model.name}
                onChange={(name) => {
                  _setmodel((prev) => {
                    return { ...prev, name: name.toUpperCase() };
                  });
                }}
              />
            </Responsive>
            <Responsive className="p-10">
              <label>Fournisseurs Terrestres: </label>
              <SelectPicker
                onSearch={(q) => fetchProviders(q)}
                data={providers
                  .filter(
                    (c) =>
                      c.type == 1 &&
                      model.providers1 &&
                      !model.providers1.find((el) => el.id == c.id)
                  )
                  .map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                block
                noSearch
                // value={model.providerId}
                onSelect={(providerId) => {
                  let p = providers.find((_) => _.id == providerId);
                  console.log(p);
                  let providers1 = [...model.providers1];
                  providers1.push(p);
                  _setmodel((prev) => {
                    return { ...prev, providers1 };
                  });
                }}
              />
              <ol style={{ padding: "10px" }}>
                {model.providers1.map((el, i) => (
                  <li>
                    {el.name}

                    <br></br>
                    <label>Hotels</label>
                    <br></br>
                    <TagInput
                      block
                      value={el.hotels ? el.hotels.split(",") : []}
                      onCreate={(value, item) => {
                        console.log(value, item);
                        let p = { ...el, hotels: value.join() };

                        let providers1 = [...model.providers1];
                        providers1[i] = p;
                        _setmodel((prev) => {
                          return { ...prev, providers1 };
                        });
                      }}
                    />
                  </li>
                ))}
              </ol>
            </Responsive>
            <Responsive className="p-10">
              <label>Fournisseurs Aeriens: </label>
              <SelectPicker
                onSearch={(q) => fetchProviders(q)}
                data={providers
                  .filter((c) => c.type == 2)
                  .map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                block
                noSearch
                // value={model.providerId}
                onSelect={(providerId) => {
                  let p = providers.find((_) => _.id == providerId);
                  console.log(p);
                  let providers2 = [...model.providers2];
                  providers2.push(p);
                  _setmodel((prev) => {
                    return { ...prev, providers2 };
                  });
                }}
              />
              <ol style={{ padding: "10px" }}>
                {model.providers2.map((el) => (
                  <li>{el.name}</li>
                ))}
              </ol>
            </Responsive>
            {/* <h6>Cout & Devise</h6>
            <Responsive s={6} m={6} l={3} xl={3} className="p-10">
              <label>Cout Unitaire:</label>
              <Input
                type="number"
                step="0.1"
                value={model.unitCost}
                onChange={(v) => {
                  _setmodel((prev) => {
                    return { ...prev, unitCost: parseInt(v) };
                  });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={3} xl={3} className="p-10">
              <label>Cout d'échange:</label>
              <Input
                type="number"
                step="0.1"
                value={model.exchange}
                onChange={(v) => {
                  _setmodel((prev) => {
                    return { ...prev, exchange: parseInt(v) };
                  });
                }}
              />
            </Responsive>
            <h6>Frais B2C</h6>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Commission Unitaire:</label>
              <Input
                type="number"
                step="0.1"
                value={model.commission}
                onChange={(v) => {
                  _setmodel((prev) => {
                    return { ...prev, commission: parseFloat(v) };
                  });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Frais de service:</label>
              <Input
                type="number"
                step="0.1"
                value={model.unitFee}
                onChange={(v) => {
                  _setmodel((prev) => {
                    return { ...prev, unitFee: parseInt(v) };
                  });
                }}
              />
            </Responsive>

            <h6>Frais B2B</h6>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Commission Unitaire:</label>
              <Input
                type="number"
                step="0.1"
                value={model.commissionFranchise}
                onChange={(v) => {
                  _setmodel((prev) => {
                    return { ...prev, commissionFranchise: parseFloat(v) };
                  });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Frais de service:</label>
              <Input
                type="number"
                step="0.1"
                value={model.unitFeeFranchise}
                onChange={(v) => {
                  _setmodel((prev) => {
                    return { ...prev, unitFeeFranchise: parseInt(v) };
                  });
                }}
              />
            </Responsive>
            <h6>Frais Branche</h6>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Commission Unitaire:</label>
              <Input
                type="number"
                step="0.1"
                value={model.commissionBranche}
                onChange={(v) => {
                  _setmodel((prev) => {
                    return { ...prev, commissionBranche: parseFloat(v) };
                  });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Frais de service:</label>
              <Input
                type="number"
                step="0.1"
                value={model.unitFeeBranche}
                onChange={(v) => {
                  _setmodel((prev) => {
                    return { ...prev, unitFeeBranche: parseInt(v) };
                  });
                }}
              />
            </Responsive> */}
            <div style={{ background: "rgb(84,159,10,0.2)", padding: "10px" }}>
              {" "}
              <h1>Calendrier Produit</h1>
              <Disponibilities />
            </div>
          </>
        ) : current == 1 ? (
          <Prices providers={providers} fetchProviders={fetchProviders} />
        ) : (
          // ) : current == 2 ? (
          //   <Disponibilities />
          <Attachments />
        )}
      </div>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
      <Divider />
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;

const Extrats = ({ providers, fetchProviders }) => {
  const [services, setservices] = useState(serviceTypes);
  const [model, _setmodel] = useRecoilState(PackageAtom);
  const [state, setstate] = useState(new PackageModel());
  const save = () => {
    _setmodel((prev) => {
      console.log(prev);
      let m = { ...prev };
      if (typeof m.extrats != "object") m.extrats = [];
      if (typeof state.index == "number") {
        let extrats = [...m.extrats];
        extrats[state.index] = state;

        m = { ...m, extrats };
      } else {
        let extrats = [...m.extrats];
        extrats.push(state);
        m = { ...m, extrats };
      }
      return m;
    });
    setstate(new ExtratModel());
  };
  const _delete = (index) => {
    _setmodel((prev) => {
      let m = { ...prev };
      let extrats = [...m.extrats];
      extrats.splice(index, 1);
      m = { ...m, extrats };
      return m;
    });
  };
  const setEditedElement = (index) => {
    setstate({ ...model.extrats[index], index });
  };
  return (
    <>
      {/* <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Fournisseur : </label>
        <SelectPicker
          onSearch={(q) => fetchProviders(q)}
          data={providers.map((c) => {
            return { label: c.name, value: c.id };
          })}
          block
          noSearch
          value={state.providerId}
          onSelect={(providerId) => {
            let p = providers.find((_) => _.id == providerId);
            console.log(p);

            // if (p && p.serviceTypes) {
            //   console.log("test");
            //   setservices((prev) => {
            //     return [
            //       ...serviceTypes.filter((ss) =>
            //         p.serviceTypes.includes(ss.value)
            //       ),
            //     ];
            //   });
            // }

            setstate((prev) => {
              return { ...prev, providerId };
            });
          }}
        />
      </Responsive>

      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Service : </label>
        <SelectPicker
          data={services}
          block
          noSearch
          value={state.service}
          onSelect={(service) => {
            setstate((prev) => {
              return { ...prev, service };
            });
          }}
        />
      </Responsive> */}
      {/* <Responsive s={6} m={6} l={3} xl={3} className="p-10">
        <label>Cout Unitaire:</label>
        <Input
          type="number"
          step="0.1"
          value={state.unitCost}
          onChange={(v) => {
            setstate((prev) => {
              return { ...prev, unitCost: parseInt(v) };
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={2} xl={2} className="p-10">
        <label>Frais de service:</label>
        <Input
          type="number"
          step="0.1"
          value={state.unitFee}
          onChange={(v) => {
            setstate((prev) => {
              return { ...prev, unitFee: parseInt(v) };
            });
          }}
        />
      </Responsive>

      <Responsive s={6} m={6} l={4} xl={4} className="p-10">
        <label>Prix Total:</label>
        <div>
          {" "}
          <b style={{ color: "darkcyan", fontSize: "25px" }}>
            {(parseFloat(state.unitFee) + parseFloat(state.unitCost)).toFixed(
              3
            )}
          </b>
        </div>
      </Responsive> */}

      <label>Désignation:</label>
      <Input
        as="textarea"
        rows={2}
        placeholder="Notes"
        value={state.note}
        onChange={(note) => {
          setstate((prev) => {
            return { ...prev, note };
          });
        }}
      />

      <Divider />
      <div style={{ textAlign: "right" }}>
        <IconButton onClick={save} color="violet" icon={<CreditCardPlusIcon />}>
          Ajouter
        </IconButton>
      </div>
      <Divider />
      <Grid
        editAction={setEditedElement}
        deleteAction={_delete}
        actionKey="index"
        noAdvancedActions // for custom advanced actions
        columns={columnsextrats(providers)}
        rows={model.extrats.map((el, index) => {
          return { ...el, index };
        })}
      />
    </>
  );
};

const columnsextrats = (providers) => {
  return [
    {
      value: "note",

      name: "Nom ",
      render: (s) => (
        <b>
          <i>{s}</i>
        </b>
      ),
    },
    // {
    //   value: "unitFee",
    //   value2: "unitCost",
    //   name: "Prix Total",
    //   render: (unitFee, unitCost) => (
    //     <b>{(parseFloat(unitFee) + parseFloat(unitCost)).toFixed(3)}</b>
    //   ),
    // },
  ];
};
const Disponibilities = () => {
  const airlines = useRecoilValue(Airlines);

  const [model, _setmodel] = useRecoilState(PackageAtom);
  const [state, setstate] = useState(new DisponobilityModel());
  const save = () => {
    _setmodel((prev) => {
      console.log(prev);
      let m = { ...prev };
      if (typeof m.disponibilities != "object") m.disponibilities = [];
      if (typeof state.index == "number") {
        let disponibilities = [...m.disponibilities];
        disponibilities[state.index] = state;

        m = { ...m, disponibilities };
      } else {
        let disponibilities = [...m.disponibilities];
        disponibilities.push(state);
        m = { ...m, disponibilities };
      }
      return m;
    });
    setstate(new DisponobilityModel());
  };
  const _delete = (index) => {
    _setmodel((prev) => {
      let m = { ...prev };
      let disponibilities = [...m.disponibilities];
      disponibilities.splice(index, 1);
      m = { ...m, disponibilities };
      return m;
    });
  };
  const setEditedElement = (index) => {
    setstate({ ...model.disponibilities[index], index });
  };
  return (
    <>
      <Responsive s={6} m={3} l={3} xl={3} className="p-10">
        <label>Nombre :</label>
        <Input
          type="number"
          step="0.1"
          value={state.onStock}
          onChange={(v) => {
            setstate((prev) => {
              return { ...prev, onStock: parseInt(v) };
            });
          }}
        />
      </Responsive>
      <Responsive s={2} m={1} l={0.5} xl={0.5} className="p-10">
        {" "}
        <br></br>
        {state.airlineId && (
          <img
            alt=""
            style={{ width: "50px" }}
            src={
              airlines.find((ai) => ai.id == state.airlineId) &&
              airlines.find((ai) => ai.id == state.airlineId).logo
            }
          ></img>
        )}{" "}
      </Responsive>
      <Responsive s={6} m={4} l={2.5} xl={1.3} className="p-10">
        <label>Airline : </label>
        <SelectPicker
          data={airlines.map((c) => {
            return { label: c.name, value: c.id };
          })}
          block
          value={state.airlineId}
          onSelect={(airlineId) => {
            setstate((pr) => {
              return { ...pr, airlineId }; 
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={3} l={2} xl={2} className="p-10">
        <label>PNR: </label>
        <Input
          value={state.PNR}
          onChange={(PNR) => {
            console.log(PNR);
            setstate((prev) => {
              return { ...prev, PNR };
            });
          }}
        />
      </Responsive>
      <Responsive m={6} l={2} xl={2} className="p-10">
        <label>Date: </label>
        <Input
          type="date"
          value={state.date}
          onChange={(date) => {
            console.log(date);
            setstate((prev) => {
              return { ...prev, date };
            });
          }}
        />
      </Responsive>

      <Divider />
      <div style={{ textAlign: "right" }}>
        <IconButton onClick={save} color="violet" icon={<CreditCardPlusIcon />}>
          {state.index != undefined ? "Modifier" : " Ajouter"}
        </IconButton>
      </div>
      <Divider />
      <Grid
        editAction={setEditedElement}
        deleteAction={_delete}
        actionKey="index"
        noAdvancedActions // for custom advanced actions
        columns={[
          {
            value: "onStock",
            name: "Nombre",
            render: (s) => <b>{s}</b>,
          },
          {
            value: "date",

            name: "Date",
            render: (d) => <b>{d}</b>,
          },
        ]}
        rows={model.disponibilities.map((el, index) => {
          return { ...el, index };
        })}
      />
    </>
  );
};

// -----------------------------------

const Attachments = () => {
  const [model, _setmodel] = useRecoilState(saleSate);
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(false);
  const upload = (list, file, fileKey) => {
    setloading(true);
    APi.createAPIEndpoint(APi.ENDPOINTS.File)
      .upload(file)
      .then((res) => {
        setloading(false);

        let i = list.findIndex((el) => el.fileKey == fileKey);
        setstate((prev) => {
          let items = [...list];
          items[i].status = "finished";
          return items;
        });
        let attachments = [...model.attachments];
        attachments.push({ name: res.data[0].fileName });
        _setmodel((prev) => {
          return { ...model, attachments };
        });
      })
      .catch((error) => {
        setloading(false);

        let i = list.findIndex((el) => el.fileKey == fileKey);
        setstate((prev) => {
          let items = [...list];
          items[i].status = "error";
          return items;
        });
      });
  };
  const _delete = (fileKey) => {
    let i = state.findIndex((el) => el.fileKey == fileKey);
    APi.createAPIEndpoint(APi.ENDPOINTS.File)
      .delete(model.attachments[i].name)
      .then((res) => {
        setstate((prev) => {
          let items = [...prev];
          items.splice(i);
          return items;
        });
        let attachments = [...model.attachments];
        attachments.splice(i);
        _setmodel((prev) => {
          return { ...model, attachments };
        });
      })
      .catch((error) => {});
  };
  return (
    <>
      {" "}
      <Uploader
        loading={loading}
        autoUpload={false}
        fileList={state}
        action="#"
        draggable
        onRemove={(f) => _delete(f.fileKey)}
        onChange={(list) => {
          // setstate((prev) => list);
          console.log(list);
          let file = list.filter((el) => el.status != "finished").reverse()[0];
          if (file) upload(list, file.blobFile, file.fileKey);
        }}
      >
        <div style={{ background: "rgba(200,200,200,0.1)" }}>
          <div style={{ fontSize: "50px" }}>
            {" "}
            <FileUploadIcon color="#3598ff"></FileUploadIcon>
          </div>
          <h4 style={{ color: "#bbb", fontWeight: "400" }}>
            Clicker ou Faites glisser les fichiers vers cette zone
            {/* pour les télécharger */}
          </h4>
        </div>
      </Uploader>
      {loading && <></>}
    </>
  );
};

const Prices = ({ providers, fetchProviders }) => {
  const [state, setstate] = useState(false);
  const [model, _setmodel] = useRecoilState(PackageAtom);
  const currencies = useRecoilValue(CurrencyAtom);
  const exchanges = useRecoilValue(ExchangeAtom);

  useEffect(() => {
    let _providers = model.providers1.concat(model.providers2);

    let prices = [...model.prices].map((el) => {
      let price = { ...el };
      let costs = [...price.costs];
      console.log(_providers);
      _providers.map((p) => {
        if (!costs.some((c) => c.provider.id == p.id))
          costs.push({
            cost: 0,
            provider: p,
            exchange:
              exchanges &&
              currencies.filter((c) =>
                p.currencies
                  ? p.currencies.find((_c) => _c.currencyId == c.id)
                    ? true
                    : false
                  : false
              )[0]
                ? exchanges.find(
                    (ex) =>
                      ex.currencySourceId ==
                      currencies.filter((c) =>
                        p.currencies
                          ? p.currencies.find((_c) => _c.currencyId == c.id)
                            ? true
                            : false
                          : false
                      )[0].id
                  )
                  ? exchanges.find(
                      (ex) =>
                        ex.currencySourceId ==
                        currencies.filter((c) =>
                          p.currencies
                            ? p.currencies.find((_c) => _c.currencyId == c.id)
                              ? true
                              : false
                            : false
                        )[0].id
                    ).rate
                  : 1
                : 1,
            currencyId: currencies.filter((c) =>
              p.currencies
                ? p.currencies.find((_c) => _c.currencyId == c.id)
                  ? true
                  : false
                : false
            )[0]
              ? currencies.filter((c) =>
                  p.currencies
                    ? p.currencies.find((_c) => _c.currencyId == c.id)
                      ? true
                      : false
                    : false
                )[0].id
              : 0,
          });
      });
      return { ...price, costs };
    });
    console.log({ ...model, prices });
    _setmodel((prev) => ({ ...model, prices }));
  }, [model.providers1, model.providers2]);

  return (
    <div>
      <div style={{ overflowX: "auto" }}>
        <table style={{ tableLayout: "fixed", borderCollapse: "collapse" }}>
          <tr>
            <td
              style={{ padding: "5px", fontSize: "16px", fontWeight: "bold" }}
            >
              Designation
            </td>
            {state && (
              <>
                {model.providers1.map((p, i) => (
                  <>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      F.T {i + 1}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Coût
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Devis
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Exchange
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Coût TND
                    </td>
                  </>
                ))}
                {model.providers2.map((p, i) => (
                  <>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      F.A {i + 1}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Coût
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Devis
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Exchange
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Coût TND
                    </td>
                  </>
                ))}

                <td
                  style={{
                    padding: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Dépense
                </td>
              </>
            )}
            <td
              style={{ padding: "5px", fontSize: "16px", fontWeight: "bold" }}
            >
              Coût Total
              <button
                style={{ background: "rgb(2,90,187)", color: "#fff" }}
                onClick={(e) => setstate((prev) => !prev)}
              >
                {state ? "<" : ">"}
              </button>
            </td>
            <td
              style={{ padding: "5px", fontSize: "16px", fontWeight: "bold" }}
            >
              Prix Vente
            </td>
            <td
              style={{ padding: "5px", fontSize: "16px", fontWeight: "bold" }}
            >
              Benifice
            </td>
            {/* <td
              style={{ padding: "5px", fontSize: "16px", fontWeight: "bold" }}
            >
              C. Branche
            </td>
            <td
              style={{ padding: "5px", fontSize: "16px", fontWeight: "bold" }}
            >
              C. B2B
            </td> */}
            <td>---</td>
          </tr>
          <tbody>
            {model.prices.map((el, i) => (
              <tr
                style={{
                  background: i % 2 == 0 ? "rgb(128,128,128,0.2)" : "#fff",
                  padding: "5px",
                }}
              >
                <td>
                  <input
                    style={{
                      padding: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      let prices = [...model.prices];
                      let price = { ...el };
                      price.name = value;
                      prices[i] = price;
                      console.log(prices);
                      _setmodel((prev) => ({ ...prev, prices }));
                    }}
                    value={el.name}
                  ></input>
                </td>
                {state && (
                  <>
                    {el.costs.map((p, j) => (
                      <>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            background: "rgb(2,90,187)",
                            color: "#fff",
                          }}
                        >
                          {p.provider.name}
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <input
                            style={{
                              padding: "5px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              width: "100px",
                            }}
                            onChange={(e) => {
                              let value = e.target.value;
                              let prices = [...model.prices];
                              let costs = [...el.costs];
                              let cost = { ...costs[j] };
                              cost.cost = value;
                              costs[j] = cost;
                              let price = { ...prices[i] };
                              price.costs = costs;
                              prices[i] = price;
                              _setmodel((prev) => ({ ...prev, prices }));
                            }}
                            value={p.cost}
                          ></input>
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <SelectPicker
                            // onSearch={(q) => fetchClients(q)}
                            data={currencies
                              .filter((c) =>
                                p.provider.currencies
                                  ? p.provider.currencies.find(
                                      (_c) => _c.currencyId == c.id
                                    )
                                    ? true
                                    : false
                                  : false
                              )
                              .map((c) => {
                                return { label: c.name, value: c.id };
                              })}
                            block
                            noSearch
                            value={p.currencyId}
                            onSelect={(currencyId) => {
                              let prices = [...model.prices];
                              let costs = [...el.costs];
                              let cost = { ...costs[j] };
                              cost.currencyId = currencyId;
                              console.log(exchanges);
                              cost.exchange = exchanges
                                ? exchanges.find(
                                    (ex) => ex.currencySourceId == currencyId
                                  )
                                  ? exchanges.find(
                                      (ex) => ex.currencySourceId == currencyId
                                    ).rate
                                  : 1
                                : 1;
                              costs[j] = cost;
                              let price = { ...prices[i] };
                              price.costs = costs;
                              prices[i] = price;
                              _setmodel((prev) => ({ ...prev, prices }));
                            }}
                          />
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <input
                            style={{
                              padding: "5px",
                              fontSize: "16px",
                              fontWeight: "bold",
                              width: "100px",
                            }}
                            disabled
                            // onChange={(e) => {
                            //   let value = e.target.value;
                            //   let prices = [...model.prices];
                            //   let costs = [...el.costs];
                            //   let cost = { ...costs[j] };
                            //   cost.exchange = value;
                            //   costs[j] = cost;
                            //   let price = { ...prices[i] };
                            //   price.costs = costs;
                            //   prices[i] = price;
                            //   _setmodel((prev) => ({ ...model, prices }));
                            // }}
                            value={p.exchange}
                          ></input>
                        </td>
                        <td
                          style={{
                            padding: "5px",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {p.cost * p.exchange}
                        </td>
                      </>
                    ))}

                    <td
                      style={{
                        padding: "5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      <input
                        style={{
                          padding: "5px",
                          fontSize: "16px",
                          fontWeight: "bold",
                          width: "100px",
                        }}
                        onChange={(e) => {
                          console.log(model);
                          let value = e.target.value;
                          let prices = [...model.prices];

                          let price = { ...prices[i] };
                          price.expense = value;
                          prices[i] = price;
                          _setmodel((prev) => ({ ...prev, prices }));
                        }}
                        value={el.expense}
                      ></input>
                    </td>
                  </>
                )}
                <td
                  style={{
                    padding: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(el.expense) +
                    parseFloat(
                      el.costs.length
                        ? el.costs
                            .map(
                              (el) =>
                                parseFloat(el.cost) * parseFloat(el.exchange)
                            )
                            .reduce((a, b) => a + b)
                        : 0
                    )}
                </td>
                <td>
                  <input
                    style={{
                      padding: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      let prices = [...model.prices];

                      let price = { ...prices[i] };
                      price.price = value;
                      prices[i] = price;
                      _setmodel((prev) => ({ ...prev, prices }));
                    }}
                    value={el.price}
                  ></input>
                </td>
                <td
                  style={{
                    padding: "5px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {parseFloat(el.price) -
                    parseFloat(
                      parseFloat(el.expense) +
                        parseFloat(
                          el.costs.length
                            ? el.costs
                                .map(
                                  (l) =>
                                    parseFloat(l.cost) * parseFloat(l.exchange)
                                )
                                .reduce((a, b) => a + b)
                            : 0
                        )
                    )}
                </td>
                {/* <td>
                  <input
                    style={{
                      padding: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      let prices = [...model.prices];

                      let price = { ...prices[i] };
                      price.BrancheCommission = value;
                      prices[i] = price;
                      _setmodel((prev) => ({ ...prev, prices }));
                    }}
                    value={el.BrancheCommission}
                  ></input>
                </td>

                <td>
                  <input
                    style={{
                      padding: "5px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      let prices = [...model.prices];

                      let price = { ...prices[i] };
                      price.B2BCommission = value;
                      prices[i] = price;
                      _setmodel((prev) => ({ ...prev, prices }));
                    }}
                    value={el.B2BCommission}
                  ></input> */}
                {/* </td> */}
                <td>
                  {" "}
                  <button
                    style={{
                      fontSize: "20px",
                      background: "rgb(236,71,40)",
                      color: "#fff",
                    }}
                    onClick={(e) => {
                      let prices = [...model.prices];

                      prices.splice(i, 1);

                      _setmodel((prev) => ({ ...prev, prices }));
                    }}
                  >
                    -
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <button
          style={{ fontSize: "20px", background: "#555", color: "#fff" }}
          onClick={(e) => {
            let prices = [...model.prices];
            let price = {
              name: "",
              costs: [],
              expense: 0,

              price: 0,
            };
            let _providers = model.providers1.concat(model.providers2);
            let costs = [...price.costs];
            _providers.map((p) => {
              if (!costs.some((c) => c.providerId == p.id))
                costs.push({
                  cost: 0,
                  provider: p,
                  exchange:
                    exchanges &&
                    currencies.filter((c) =>
                      p.currencies
                        ? p.currencies.find((_c) => _c.currencyId == c.id)
                          ? true
                          : false
                        : false
                    )[0]
                      ? exchanges.find(
                          (ex) =>
                            ex.currencySourceId ==
                            currencies.filter((c) =>
                              p.currencies
                                ? p.currencies.find(
                                    (_c) => _c.currencyId == c.id
                                  )
                                  ? true
                                  : false
                                : false
                            )[0].id
                        )
                        ? exchanges.find(
                            (ex) =>
                              ex.currencySourceId ==
                              currencies.filter((c) =>
                                p.currencies
                                  ? p.currencies.find(
                                      (_c) => _c.currencyId == c.id
                                    )
                                    ? true
                                    : false
                                  : false
                              )[0].id
                          ).rate
                        : 1
                      : 1,
                  currencyId: currencies.filter((c) =>
                    p.currencies
                      ? p.currencies.find((_c) => _c.currencyId == c.id)
                        ? true
                        : false
                      : false
                  )[0]
                    ? currencies.filter((c) =>
                        p.currencies
                          ? p.currencies.find((_c) => _c.currencyId == c.id)
                            ? true
                            : false
                          : false
                      )[0].id
                    : 0,
                });
            });
            prices.push({ ...price, costs });

            _setmodel((prev) => ({ ...model, prices }));
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};
