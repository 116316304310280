import React from "react";
import Responsive from "../../Components/Responsive";
import format_number from "../../Helpers/number_formatter";
import AccountsFollowUp from "../AccountFollowUp";
import AccountsPayableLedger from "../Ledger/AccountsPayableLedger";
import Stats, { Stats2, Stats3 } from "./components";

function Home() {
  return (
    <div>
      <Responsive m={6} l={3} xl={3} className="p-10">
        <Stats
          amount={format_number(999.5)}
          title="Vente Totale"
          increase={0.18}
        />
      </Responsive>
      <Responsive m={6} l={3} xl={3} className="p-10">
        <Stats
          amount={format_number(81111.5)}
          title="Achat Total"
          increase={-0.18}
          color="rgb(84,177,7)"
        />
      </Responsive>
      <Responsive m={6} l={3} xl={3} className="p-10">
        <Stats
          amount={format_number(999.5)}
          title="Dépense Totale"
          color="rgb(236,182,28)"
        />
      </Responsive>
      <Responsive m={6} l={3} xl={3} className="p-10">
        <Stats amount="850.000" color="rgb(84,177,7)" />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <Stats2
          amount1={format_number(850)}
          amount2={format_number(200)}
          ration={format_number(Number(200 / 580))}
          title="Rapport Vente-Recouvrement"
          color="rgb(84,177,7)"
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <Stats2
          amount1={format_number(9990)}
          amount2={format_number(6000)}
          ration={format_number(Number(6000 / 9990))}
          title="Rapport Achat-Paiement"
          color="red"
        />
      </Responsive>
      <Responsive l={5} xl={5} className="p-10">
        <Stats3 />
      </Responsive>
      <Responsive l={7} xl={7} className="p-10">
        <div
          style={{
            wordWrap: "break-word",
            border: "1px solid rgba(0, 0, 0, 0.125)",
            background: "#fff",
            padding: "10px",
            boxShadow: " 0 0 10px rgb(28 39 60 / 6%)",
          }}
        >
          <h6>SUIVIE CLIENTS</h6>
          <br></br>
          <AccountsFollowUp noHead />
        </div>
      </Responsive>
    </div>
  );
}

export default Home;
