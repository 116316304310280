import ImageIcon from "@rsuite/icons/Image";
import React, { useEffect, useState } from "react";

import axios from "axios";
import { useRecoilState } from "recoil";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Tag,
} from "rsuite";
import Swal from "sweetalert2";
import { APi } from "../../Api";
import { ENDPOINTS } from "../../Api/enpoints";
import { ConfigState } from "../../Atoms/configs.atom";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import { groupsAtoms } from "../../Atoms/groups.atoms";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { BASE_URL } from "../../Config/api.config";
import AddEdit from "./AddEdit.component";
import RawdhaModel from "../../Models/rawdhaModel";
import { rawdhasAtoms } from "../../Atoms/rawdhasAtoms";
export default function Rawdhas(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [openRawdhaDetails, setopenRawdhaDetails] = useState(false);
  const [rawdhaId, setrawdhaId] = useState(0);
  const [data2, setdata2] = useRecoilState(rawdhasAtoms);
  const [totalCount, settotalCount] = useState(0);
  const [selectedVisa, setselectedVisa] = useState(0);
  const [pilgrims, setpilgrims] = useState([]);
  const [loading, setloading] = useState(false);
  const [conf, setconf] = useRecoilState(ConfigState);

  const [filterModel, setfilterModel] = useState({
    q: "",
    page: 1,
    take: 50,
    date: null,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useState(new RawdhaModel());
  const [selected, setselected] = useState(0);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new RawdhaModel());
    setError("");
  };
  // useEffect(() => {

  // }, []);

  // API CALLS
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });

    APi.createAPIEndpoint(APi.ENDPOINTS.Rawdha, filterModel)
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });

    APi.createAPIEndpoint(APi.ENDPOINTS.Rawdha, { page: 1, take: 1000, q: "" })
      .fetchAll()
      .then((res) => {
        setdata2(res.data.data);
      })
      .catch((e) => {});
  };
  const save = () => {
    let msg = "";
    if (msg) setError(msg);
    else {
      setstate((prev) => {
        return { ...prev, loading: true };
      });
      if (model.id) {
        APi.createAPIEndpoint(APi.ENDPOINTS.Rawdha)
          .update(model.id, model)
          .then((res) => {
            fetch();
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Élément a été bien modifié !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      } else {
        APi.createAPIEndpoint(APi.ENDPOINTS.Rawdha)
          .create(model)
          .then((res) => {
            fetch();
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Element a été bien ajouté !",
              showConfirmButton: false,
              timer: 1500,
            });
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      }
    }
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Rawdha)
      .delete(id)
      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");

    setmodel(data.find((el) => el.id == id));
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  useEffect(() => {
    if (rawdhaId) fetchByGroup();
  }, [rawdhaId]);
  const columns = [
    {
      value: "designation",
      name: "Designation",
      render: (v) => <b style={{ fontSize: "18px" }}>{v}</b>,
    },
    {
      value: "date",
      name: "DATE",
      render: (v) => <b>{v ? v.substring(0, 10) : v}</b>,
    },
    {
      value: "date",
      name: "Temps",
      render: (v) => <b>{v ? v.substring(11, 16) : v}</b>,
    },

    {
      value: "id",
      name: "DETAILS",
      render: (v) => (
        <Button
          appearance="ghost"
          color="blue"
          onClick={() => {
            setopenRawdhaDetails(true);
            setrawdhaId(v);
          }}
        >
          Détails
        </Button>
      ),
    },
    {
      value: "id",
      name: "-",
      render: (v) => (
        <Button
          loading={selected == v && loading}
          appearance="primary"
          color="orange"
          onClick={() => {
            sendTorawdha(v);
            setselected(v);
          }}
        >
          envoyer
        </Button>
      ),
    },
  ];
  const sendTorawdha = async (rawdhaId) => {
    console.log("rawdha");
    setloading(true);
    let rawdha = data2.find((el) => el.id == rawdhaId);
    // const res = await APi.createAPIEndpoint(ENDPOINTS.Order + "/Umrah", {
    //   page: 1,
    //   take: 10000,
    //   rawdhaId,
    // }).customGet();
    // if (res.data) {
    let m = {};
    m.passportNumbers = "";
    //  res.data.data
    //   .map((el) => el.customer.passportNumber)
    //   .join();
    console.log(m.passportNumbers);
    // m.passportNumbers = "C8307530";
    m.month = rawdha.month;
    m.days = rawdha.days;
    m.isMale = rawdha.isMale;
    m.designation = rawdha.designation;
    // m.user = conf.login;
    m.user = "nusk1031";
    m.password = "nusk_1031#";
    // m.password = conf.password;
    axios.post("http://localhost:8733/rawdha", m).then((_res) => {
      console.log(_res);
      if (!_res.data.Erreur)
        // APi.createAPIEndpoint(ENDPOINTS.Order + "/changeRawdha")
        //   .create({ rawdhaId, orders: data2.map((el) => el.id), isSent: true })
        //   .then((res) => {
        alert("success");
      // });
      else {
        sendTorawdha(rawdhaId);
      }
    });
    setloading(false);
  };

  const handleClose = () => {
    setpilgrims([]);
    setrawdhaId(0);
    setopenRawdhaDetails(false);
  };
  const fetchByGroup = () => {
    APi.createAPIEndpoint(ENDPOINTS.Order + "/Umrah", {
      page: 1,
      take: 10000,
      rawdhaId,
    })
      .customGet()
      .then((res) =>
        setpilgrims(
          res.data.data
            .filter((el) => el)
            .map((el, i) => ({ ...el, index: i + 1 }))
        )
      );
  };
  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Nom: </label>

          <Input
            placeholder="recherche"
            onChange={(q) => {
              setfilterModel((prev) => {
                return { ...prev, q };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={6} xl={6} className="p-10">
          <label>Période: </label>
          <DatePicker
            placement="bottomStart"
            value={filterModel.date}
            onChange={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            onSelect={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            block
          />
        </Responsive>
      </Filter>
      <ExportAdd
        // excelData={multiDataSet(data)}
        noExport
        size="md"
        save={save}
        AddComponent={
          <AddEdit error={error} model={model} _setmodel={setmodel} />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
        <Modal
          size={"lg"}
          overflow={false}
          style={{
            maxHeight: "calc(100vh - 50px)",
            overflow: "auto",
            maxWidth: "100vw",
          }}
          open={openRawdhaDetails}
          onClose={() => {
            handleClose();
          }}
        >
          <Modal.Header>
            <Modal.Title>Rawdha Détails</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                maxHeight: "calc(100vh - 240px)",
                overflow: "auto",
                maxWidth: "100vw",
              }}
            >
              <Grid columns={columns2} rows={pilgrims} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                handleClose();
              }}
              appearance="subtle"
            >
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

const columns2 = [
  {
    value: "codeMoufa",
    value2: "errorMessage",
    render: (v, v2) => (
      <span
        style={{
          content: "",
          width: "10px",
          height: "50px",
          background: v || v2 == "Success" ? "green" : v2 ? "red" : "orange",
          display: "block",
        }}
      ></span>
    ),
  },
  {
    value: "index",
    name: "-",
    render: (v) => (
      <b
        style={{
          fontSize: "20px",
        }}
      >
        {v}
      </b>
    ),
  },
  {
    value: "customer",
    name: "Client B2C",
    render: (v) => (
      <a style={{ display: "flex", alignItems: "center" }}>
        {v.picture ? (
          <img
            alt=""
            src={
              BASE_URL +
              "Uploads/CustomerPictures/" +
              v.picture +
              "?" +
              Date.now()
            }
            width="43"
            height="43"
            style={{
              border: "1px solid #eee",
              borderRadius: "50%",
              boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
            }}
          />
        ) : (
          <div
            style={{
              fontSize: "15px",
              border: "1px dashed #888",
              borderRadius: "50%",
              width: "43px",
              height: "43px",
              textAlign: "center",
              lineHeight: "42px",
            }}
          >
            <ImageIcon color="#3598ff"></ImageIcon>
          </div>
        )}
        <div style={{ padding: "0 5px" }}>
          {" "}
          {v.firstName + "  " + v.lastName}
          {"  "}
          <i
            style={{
              color: "#999",
              fontSize: "14px",
              fontWeight: "lighter",
            }}
          >
            {v ? v.passportNumber : ""}
          </i>
          <br></br>{" "}
          <i style={{ color: "#444" }}>{v.firstNameAr + "  " + v.lastNameAr}</i>
        </div>
      </a>
    ),
  },

  {
    name: "Rawdha",
    value: "isRawdhaSent",
    render: (t) => <Tag color={t ? "blue" : "orange"}>{t ? "oui" : "non"}</Tag>,
  },
];
