import moment from "moment";
const colors = [
  "ecb61c",
  "b743ae",
  "99c262",
  "2590b5",
  "ffd06d",
  "a90e43",
  "87a910",
  "ff8200",
  "008f96",
  "7986cb",
  "2590b5",
  "ffd06d",
];
export const multiDataSet = (data) => {
  console.log(data)
  let clients = data
    .map((item) => item.items.map((_i) => _i.client))
    .reduce((acc, el) => {
      return acc.concat(el);
    }, []);
  clients = clients.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      clients.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  console.log(clients);
  let columns = [
    { title: "-", width: { wpx: 70 } },
    { title: "Vol", width: { wpx: 120 } },
    { title: "Prévision", width: { wpx: 120 } },
    { title: "Airline", width: { wpx: 50 } },
  ];
  clients.map(
    (el, i) =>
      (columns = columns.concat([
        {
          title:el ? el.name.toUpperCase().replace("KOUNOUZ", "").trim():"",
          width: { wpx: 100 },
          style: {
            fill: { fgColor: { rgb: i % 2 ? "ffffff" : colors[i % 9] } },
            font: { sz: "12" },
            border: { bottom: { style: "thin", color: "333333" } },
          },
        },
        {
          title: "-",
          style: {
            fill: { fgColor: { rgb: i % 2 ? "ffffff" : colors[i % 9] } },
            font: { sz: "12" },
            border: { bottom: { style: "thin", color: "333333" } },
          },
        },
      ]))
  );
  if (data && data.length)
    return [
      {
        columns,
        data: data.map((item, i) => {
          let item2 = [
            {
              value: item.disponibility
                ?item.disponibility.onStock - item.items.reduce((a,b)=>a+b.nbr,0)
                : "-",
              style: {
                fill: { fgColor: { rgb: item.disponibility.onStock - item.items.reduce((a,b)=>a+b.nbr,0)>0? "66ff66" :item.disponibility.onStock - item.items.reduce((a,b)=>a+b.nbr,0)==0?"ffffff": "ff6666" } },
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
                border: { bottom: { style: "thin", color: "333333" } },
              },
            },
            {
              value: item.disponibility
                ? moment(item.disponibility.date).format("DD MMM yyyy")
                : "-",
              style: {
                fill: { fgColor: { rgb: i % 2 ? "ffffff" : "eeeeee" } },
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
                border: { bottom: { style: "thin", color: "333333" } },
              },
            },
            {
              value: item.disponibility
                ? item.disponibility.onStock
                : "-",
              style: {
                fill: { fgColor: { rgb: i % 2 ? "ffffff" : "eeeeee" } },
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
                border: { bottom: { style: "thin", color: "333333" } },
              },
            },
            {
              value: item.disponibility.airline
                ? item.disponibility.airline.logo
                    .replace("https://images.kiwi.com/airlines/64/", "")
                    .replace(".png", "")
                : "",
              style: {
                fill: { fgColor: { rgb: i % 2 ? "ffffff" : "eeeeee" } },
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
                border: { bottom: { style: "thin", color: "333333" } },
              },
            },
          ];
          clients.map((c, i) => {
            item2 = item2.concat([
              {
                value:  item.items.find((el) =>c&& el.clientId == c.id)
                  ? item.items.find((el) =>c&& el.clientId == c.id).nbr
                  : 0,
                width: { wpx: 100 },
                style: {
                  fill: { fgColor: { rgb: i % 2 ? "ffffff" : colors[i % 9] } },
                  font: { sz: "12" },
                  alignment: { horizontal: "center" },
                  border: { bottom: { style: "thin", color: "333333" } },
                },
              },
              {
                value: item.items.find((el) => el.clientId == c.id)
                  ? item.items.find((el) => el.clientId == c.id).designation
                  : "-",
                style: {
                  fill: { fgColor: { rgb: i % 2 ? "ffffff" : colors[i % 9] } },
                  font: { sz: "12" },
                  border: { bottom: { style: "thin", color: "333333" } },
                },
              },
            ]);
          });
          return item2;
        }),
      },
    ];
};
