import moment from "moment";
import format_number from "../../Helpers/number_formatter";
import { ConfirmationStatus } from "../../Constants/types";

export const multiDataSet = (data) => {
  if (data && data.length)
    return [
      {
        columns: [
          { title: "Voyage", width: { wpx: 120 } },
          { title: "Vol", width: { wpx: 120 } },
          { title: "Client B2B", width: { wpx: 120 } },
          { title: "Coupon", width: { wpx: 100 } },
          { title: "Wakil", width: { wpx: 120 } },
          { title: "Arrangement", width: { wpx: 130 } },

          { title: "Hotel Makkah", width: { wpx: 150 } },
          { title: "Hotel Madinah", width: { wpx: 150 } },

          { title: "Status", width: { wpx: 160 } },

          { title: "Pélérin", width: { wpx: 200 } },
          { title: "Numéro de passeport", width: { wpx: 120 } },

          { title: "Prix total", width: { wpx: 100 } }, //pixels width
        ],
        data: data.map((item) => {
          let item2 = [
            {
              value: item.packageUmrah ? item.packageUmrah.name : "-",
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: { sz: "13", color: { rgb: "2590b5" } },
                alignment: { horizontal: "center" },
              },
            },

            {
              value: item.disponibility
                ? moment(item.disponibility.date).format("DD MMM")
                : "-",
              style: {
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.client ? item.client.name : "-",
              style: {
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.coupon || "-",
              style: {
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.wakil || "-",
              style: {
                font: { sz: "13", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.price
                ? item.price.arrangementMadinahDesignation
                : "-",
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: {
                  sz: "13",
                  color: {
                    rgb: item.price
                      ? item.price.arrangementMadinahDesignation ==
                        "Chambre triple"
                        ? "2f1a4c"
                        : item.price.arrangementMadinahDesignation ==
                          "Chambre double"
                        ? "ecb61c"
                        : "5a2c3e"
                      : "5a2c3e",
                  },
                },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.price ? item.price.hotelMakkahName : "-",
              style: {
                font: {
                  sz: "13",
                  color: {
                    rgb: "777777",
                  },
                },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.price ? item.price.hotelMadinahName : "-",
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: {
                  sz: "13",
                  color: {
                    rgb: "888888",
                  },
                },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: ConfirmationStatus.find(
                (el) =>
                  item.confirmationStatus === null ||
                  el.value === item.confirmationStatus
              )
                ? ConfirmationStatus.find(
                    (el) =>
                      item.confirmationStatus === null ||
                      el.value === item.confirmationStatus
                  ).label
                : ConfirmationStatus.find((el) => el.value === 1).label,
              style: {
                font: {
                  sz: "13",
                  color: {
                    rgb:
                      item.confirmationStatus === 2
                        ? "663399"
                        : item.confirmationStatus == 3
                        ? "0b9666"
                        : item.confirmationStatus == 4
                        ? "b61955"
                        : "e68019",
                  },
                },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.customer.name,
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },
                font: { sz: "12", color: { rgb: "5a2c3e" } },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: item.customer.passportNumber,
              style: {
                alignment: { horizontal: "center" },
              },
            },

            {
              value: format_number(item.totalPrice),
              style: {
                fill: { fgColor: { rgb: "eeeeee" } },

                font: { sz: "13", color: { rgb: "2f1a4c" } },
                alignment: { horizontal: "center" },
              },
            },
          ];
          return item2;
        }),
      },
    ];
};
