import { Input, Message, SelectPicker } from "rsuite";

function AddEdit({ _setmodel, error, model }) {
  return (
    <>
      <label>Designation:</label>
      <Input
        onChange={(designation) => {
          _setmodel((prev) => {
            return { ...prev, designation };
          });
        }}
        value={model.designation}
      />
      {/* <label>Email Commun :</label>
      <Input
        type="email"
        value={model.commonEmail}
        onChange={(commonEmail) => {
          _setmodel((prev) => {
            return { ...prev, commonEmail };
          });
        }}
      />
      <label>Tél Commun</label>
      <Input
        type="phone"
        value={model.commonPhone}
        onChange={(commonPhone) => {
          _setmodel((prev) => {
            return { ...prev, commonPhone };
          });
        }}
      /> */}
      <label>jours :</label>
      <input
        style={{ display: "block" }}
        value={model.days}
        onChange={(e) => {
          _setmodel((prev) => {
            return { ...prev, days: e.target.value };
          });
        }}
      />
      <br></br>
      <label htmlFor="monthSelect"> Mois: </label>
      <select
        id="monthSelect"
        value={model.month}
        onChange={(e) => {
          _setmodel((prev) => {
            return { ...prev, month: e.target.value };
          });
        }}
      >
        <option value="">Select</option>
        <option value="0">Ce Mois</option>
        <option value="1">Mois Prochain</option>
      </select>
      <br></br>
      <label>
        {" "}
        Genre : male
        <br></br>{" "}
        <input
          type="checkbox"
          // onClick={(e) => {
          //   _setmodel((prev) => ({
          //     ...prev,
          //     confirmationStatus: prev.confirmationStatus===2 ? 1 : 2,
          //   }));
          // }}
          onChange={(e) => {
            _setmodel((prev) => ({
              ...prev,
              isMale: true,
            }));
          }}
          checked={model.isMale == true}
        ></input>
      </label>
      <br></br>
      <label>
        {" "}
        Genre female :<br></br>{" "}
        <input
          type="checkbox"
          // onClick={(e) => {
          //   _setmodel((prev) => ({
          //     ...prev,
          //     confirmationStatus: prev.confirmationStatus===2 ? 1 : 2,
          //   }));
          // }}
          onChange={(e) => {
            _setmodel((prev) => ({
              ...prev,
              isMale: false,
            }));
          }}
          checked={model.isMale == false}
        ></input>
      </label>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
