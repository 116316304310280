import { Input, Message, SelectPicker, TagInput } from "rsuite";
import { clientTypes } from "../../../Constants/types";
import { ClientModel } from "../../../Models/TiersModels";

function AddEdit({ _setmodel, error, model, clients, fetchClients }) {
  return (
    <>
      <label>Nom:</label>
      <Input
        onChange={(name) => {
          _setmodel((prev) => {
            return { ...prev, name };
          });
        }}
        value={model.name}
      />
      <label>Code</label>
      <Input
        value={model.code}
        onChange={(code) => {
          _setmodel((prev) => {
            return { ...prev, code };
          });
        }}
      />
      <label>Ville</label>
      <Input
        value={model.city}
        onChange={(city) => {
          _setmodel((prev) => {
            return { ...prev, city };
          });
        }}
      />
      <label>Adresse</label>
      <Input
        value={model.address}
        onChange={(address) => {
          _setmodel((prev) => {
            return { ...prev, address };
          });
        }}
      />
      <label>Emails</label>
      <TagInput
        block
        size="md"
        //   placeholder="emails "
        value={model.emails ? model.emails.split(",") : []}
        onChange={(emails) => {
          let m = { ...model };

          m.emails = emails.join(",");
          _setmodel(m);
        }}
      />
      <label>Télephones</label>
      <TagInput
        block
        size="md"
        // placeholder="numéros des télephones"
        value={model.phones ? model.phones.split(",") : []}
        onChange={(phones) => {
          model.phones = phones.join(",");
          let m = { ...model };
          _setmodel(m);
        }}
      />
      <label>Code Tax </label>
      <Input
        value={model.taxCode}
        onChange={(taxCode) => {
          _setmodel((prev) => {
            return { ...prev, taxCode };
          });
        }}
      />
      <label>Commission Voyage Organisé:</label>
      <Input
        type="number"
        step="0.1"
        value={model.voCommission}
        onChange={(voCommission) => {
          _setmodel((prev) => {
            return { ...prev, voCommission: parseFloat(voCommission) };
          });
        }}
      />
      <label>Commission Omrah:</label>
      <Input
        type="number"
        step="0.1"
        value={model.umrahCommission}
        onChange={(umrahCommission) => {
          _setmodel((prev) => {
            return { ...prev, umrahCommission: parseFloat(umrahCommission) };
          });
        }}
      />
      <label>Appartient Au Client</label>
      <SelectPicker
        onSearch={(q) => fetchClients(q)}
        data={[{ label: "Tout", value: null }].concat(
          clients.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.belongsToClientId}
        onSelect={(belongsToClientId) => {
          _setmodel((prev) => {
            return { ...prev, belongsToClientId };
          });
        }}
      />

      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
