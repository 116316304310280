import { CustomerVoModel } from "./SaleVoModel";

export default class HostingModel {
  date = new Date();
  clientId;
  providerId;
  customer = new CustomerVoModel();
  totalPrice = 0;
  totalRefund = 0;
  commission = 0;
  fare = 0;
  commissionPercent = 0;
  note = "";
  service = 8;
  ref = "";
  tag;
  hotelReservation = new HotelReservationModel();
}
export class HotelReservationModel {
  guests = [new CustomerVoModel()]; // array of guests
  hotelName = "";
  board = "";
  checkIn = new Date();
  checkOut = new Date();
}
