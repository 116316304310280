import moment from "moment";
import { useEffect, useState } from "react";
import { BiTrash } from "react-icons/bi";
import { useRecoilState } from "recoil";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Message,
  SelectPicker,
} from "rsuite";
import { ForcastVoAtom } from "../../Atoms/forcast.atom";
import Responsive from "../../Components/Responsive";
import format_number from "../../Helpers/number_formatter";
const styles = {
  // width: "200px",
  // display: "inline-table",
  verticalAlign: "top",
};
function AddEdit({
  error,
  clients,

  fetchClients,

  vols = [],
  packs = [],
}) {
  const [current, setcurrent] = useState(0);
  const [model, setmodel] = useRecoilState(ForcastVoAtom);
  const [pack, setpack] = useState([]);
  const [client, setclient] = useState({ nbr: 1 });
  const [data, setdata] = useState(null);

  return (
    <>
      <div style={{ padding: "40px 10px" }}>
        <label>Vols : </label>
        <SelectPicker
          data={vols.map((c, i) => {
            return {
              label: (
                <b
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "3px",
                    background: i % 2 ? "rgb(70,103,209,0.2)" : "",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    alt=""
                    style={{ width: "30px", height: "30px", margin: "0 6px" }}
                    src={c.airline ? c.airline.logo : ""}
                  ></img>
                  <b>
                    {" "}
                    <div style={{ color: "#232323" }}>
                      {moment(c.date).format("DD MMM YYYY")}
                    </div>
                    <b style={{ color: "#888", fontWeight: "lighter" }}>
                      {" "}
                      {c.airline ? c.airline.name : ""} ({" "}
                      <i style={{ color: "#999" }}>
                        {packs &&
                          packs.find((l) => l.id == c.packageId) &&
                          packs.find((l) => l.id == c.packageId).name}
                      </i>{" "}
                      )
                    </b>
                  </b>
                  <b className="p-5">
                    <span
                      style={{
                        background: "#121238",
                        color: "#fff",
                        width: "30px",
                        height: "30px",
                        textAlign: "center",
                        lineHeight: "30px",
                        display: "inline-block",
                      }}
                    >
                      {c.onStock}
                    </span>
                  </b>
                </b>
              ),
              value: c.id,
            };
          })}
          block
          noSearch
          value={model.disponibilityId}
          onSelect={(disponibilityId) => {
            let ___pack = packs.find((el) =>
              el.disponibilities.find((d) => d.id == disponibilityId)
            );
            // setpack(___pack);

            setmodel((prev) => {
              return { ...prev, disponibilityId, packageId: ___pack.id };
            });
          }}
        />

        <hr></hr>
        <Responsive className="p-10" s={7} m={7} xl={7} l={7}>
          <label>Date D'option: </label>
          <input
            style={{ display: "block", width: "100%" }}
            type="date"
            value={
              typeof model.optionDate == "string"
                ? model.optionDate.substring(0, 10)
                : model.optionDate
            }
            onChange={(e) => {
              let { value } = e.target;
              setmodel((prev) => ({ ...prev, optionDate: value }));
            }}
          />
        </Responsive>
        <Responsive className="p-10" s={3} m={3} xl={3.5} l={3.8}>
          <label>Nombre De Prévision: </label>

          <Input
            type="number"
            value={model.virtualStock}
            onChange={(virtualStock) =>
              setmodel((prev) => ({
                ...prev,
                virtualStock: parseInt(virtualStock),
              }))
            }
          />
        </Responsive>
        <hr></hr>
        <Responsive className="p-10" s={7} m={7} xl={7} l={7}>
          <label>Client: </label>
          <SelectPicker
            onSearch={(q) => fetchClients(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              clients
                .filter((c) => !model.clients.some((el) => el.clientId == c.id))
                .map((c) => {
                  return { label: c.name, value: c.id };
                })
            )}
            block
            noSearch
            value={model.clientId}
            onSelect={(clientId) => {
              setclient({
                clientId,
                client: clients.find((c) => c.id == clientId),
                nbr: 1,
              });
            }}
          />
        </Responsive>
        <Responsive className="p-10" s={3} m={3} xl={3.5} l={3.8}>
          <label>Nombre: </label>

          <Input
            type="number"
            value={client.nbr}
            onChange={(nbr) =>
              setclient((prev) => ({ ...prev, nbr: parseInt(nbr) }))
            }
          />
        </Responsive>
        <Responsive className="p-10" s={2} m={2} xl={1.5} l={1.2}>
          <label>.</label>
          <Button
            color="violet"
            appearance="primary"
            block
            onClick={() => {
              if (client.clientId)
                setmodel((pr) => ({ ...pr, clients: [...pr.clients, client] }));
              setclient({ nbr: 1, clientId: 0 });
            }}
          >
            Ajout +
          </Button>
        </Responsive>
        <table>
          <thead>
            <tr>
              <th>Client</th> <th>Nombre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {model.clients &&
              model.clients.map((c, i) => (
                <tr>
                  <td>{c.client && c.client.name}</td>{" "}
                  <td>
                    <Input
                      value={c.nbr}
                      onChange={(nbr) =>
                        setmodel((pr) => {
                          let clients = [...pr.clients];
                          clients[i] = { ...c, nbr: parseInt(nbr) };
                          return { ...pr, clients };
                        })
                      }
                    />
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        setmodel((pr) => {
                          return {
                            ...pr,
                            clients: pr.clients.filter(
                              (_c) => _c.clientId != c.clientId
                            ),
                          };
                        })
                      }
                    >
                      {" "}
                      <BiTrash />
                    </button>
                  </td>
                </tr>
              ))}
            <tr>
              <td>
                <b>Total</b>
              </td>{" "}
              <td>{model.clients.reduce((a, b) => a + b.nbr, 0)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
      <Divider />
      <style jsx>{`
        table {
          margin-top: 25px;
        }
        thead {
          background: #232350;
          color: #fff;
          height: 35px;
        }
        td {
          padding: 5px;
        }
        th {
          padding: 3px 6px;
        }
      `}</style>
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;

// -----------------------------------
