import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { SelectPicker } from "rsuite";
import { APi } from "../../../Api";
import { CurrencyAtom } from "../../../Atoms/currency.atom";
import Responsive from "../../../Components/Responsive";

export default function Preference() {
  const currencies = useRecoilValue(CurrencyAtom);
  const [currencyId, setcurrencyId] = useState(0);

  return (
    <div>
      <Responsive xl={6} l={6}>
        <h4>Devise Par defaut :</h4>
        <SelectPicker
          // onSearch={(q) => fetchClients(q)}
          data={currencies.map((c) => {
            return { label: c.name, value: c.id };
          })}
          block
          noSearch
          value={currencyId}
          onSelect={(currencyId) => {
            APi.createAPIEndpoint(
              APi.ENDPOINTS.Currency,
              { id: currencyId },
              "/SetDefault"
            )
              .customGet()
              .then((res) => alert("suvagardé"));
            setcurrencyId(currencyId);
          }}
        />
      </Responsive>
    </div>
  );
}
