import React, { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { DatePicker, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import {
  checkPurpose,
  checkStatus,
  paymentType,
  SaleStatus,
  serviceTypes,
} from "../../Constants/types";
import validate from "../../Helpers/validate";
import { SaleModel } from "../../Models/SaleModels";
import AddEdit from "./AddEdit.component";
import { multiDataSet } from "./excel_data";
import moment from "moment";

import { saleSate } from "../../Atoms/sale.atom";
import { purchaseSate } from "../../Atoms/purchase.atom";
import { PurchaseModel } from "../../Models/PurchaseModel";
export default function Purchases(props) {
  // STATE
  const [data, setdata] = useState([]);

  const [banks, setbanks] = useState([]);
  const [checkouts, setcheckouts] = useState([]);
  const [tags, setTags] = useState([]);
  const [providers, setproviders] = useState([]);

  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    providerId: 0,
    status: 0,
    date: null,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(purchaseSate);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new PurchaseModel());
    setError("");
  };
  // API CALLS

  const fetchBanks = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Bank, { q }, "/autocomplete")
        .customGet()
        .then((res) => setbanks(res.data));
    }
  };

  const fetchCheckouts = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Checkout, { q }, "/autocomplete")
        .customGet()
        .then((res) => setcheckouts(res.data));
    }
  };

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };
  const fetchTags = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Tag, { q }, "/autocomplete")
        .customGet()
        .then((res) => setTags(res.data));
    }
  };
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Purchase, {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    // let msg = validate(model, [
    //   { number: "Numéro" },
    //   { status: "Status" },
    //   { amount: "Montant" },
    //   { depositDate: "Date de Dépôt" },
    //   // { date: "Date" },

    //   { checkPurpose: "Type de Chéque" },
    //   // { clientId: "Client" },
    // ]);
    // if (msg) setError(msg);
    // else {
    console.log(model);

    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = {
      ...model,
      tagPurchases: model.tags.map((el) => {
        return { tagId: el.id };
      }),
      package: {
        ...model.package,
        packageItems: model.package.packageItems.map((el) => {
          return {
            ...el,
            unitCost: parseFloat(el.unitCost),
            commission: parseInt(el.commission),
            unitFee: parseFloat(el.unitFee),
            qty: parseInt(el.qty),
            totalCost:
              parseInt(el.qty) *
              (parseFloat(el.unitCost) +
                parseFloat(el.unitFee) * (1 + parseInt(el.commission) / 100)),
          };
        }),
      },
      payments: [
        ...model.payments.map((p) => {
          return {
            ...p,
            amount: parseFloat(p.amount),
            date: p.date ? p.date : new Date(),
            providerId: model.providerId,
          };
        }),
      ],
      attachments: model.attachments
        ? model.attachments.map((el) => el.name).join()
        : "",
    };
    delete m.tags;
    // delete m.tagSales;
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Purchase)
        .update(m.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      console.log(m);

      APi.createAPIEndpoint(APi.ENDPOINTS.Purchase)
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
    // }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Purchase)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  //
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.Purchase)
      .fetchById(id)
      .then((res) => {
        // if (
        //   res.data.client &&
        //   !clients2.find((c) => c.id == res.data.client.id)
        // ) {
        //   let arr = [...clients2];
        //   arr.unshift({ id: res.data.client.id, name: res.data.client.name });
        //   setclients2(arr);
        // }
        setmodel({
          ...res.data,
          depositDate: new Date(res.data.depositDate),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
    fetchBanks();
    fetchCheckouts();
    fetchTags();
    fetchProviders();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter search={() => fetch()}>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Période: </label>
          {/* <DatePicker
            placement="bottomStart"
            value={filterModel.date}
            onChange={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            onSelect={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            block
          /> 
        </Responsive> */}
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Provider: </label>
          <SelectPicker
            onSearch={(q) => fetchProviders(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              providers.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.providerId}
            onSelect={(providerId) => {
              setfilterModel((prev) => {
                return { ...prev, providerId };
              });
            }}
          />
        </Responsive>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Status: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(checkStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive> */}
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Type de Paiement: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(paymentType)}
            block
            noSearch
            value={filterModel.type}
            onSelect={(type) => {
              setfilterModel((prev) => {
                return { ...prev, type };
              });
            }}
          />
        </Responsive> 
          </Responsive> */}
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Status du Paiement: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(SaleStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Tags: </label>
          <SelectPicker
            onSearch={fetchTags}
            data={[{ label: "Tout", value: 0 }].concat(
              tags.map((el) => {
                return { label: el.name, value: el.id };
              })
            )}
            block
            noSearch
            value={filterModel.tagId}
            onSelect={(tagId) => {
              setfilterModel((prev) => {
                return { ...prev, tagId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Date du Vente: </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        // excelData={multiDataSet(data)}
        nameExcel="sale"
        size="lg"
        save={save}
        AddComponent={
          <AddEdit
            checkouts={checkouts}
            tags={tags}
            fetchCheckouts={fetchCheckouts}
            fetchTags={fetchTags}
            providers={providers}
            fetchProviders={fetchProviders}
            error={error}
            banks={banks}
            fetchBanks={fetchBanks}
          />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "provider",
    name: "Fournisseur",
    render: (v) => <a>{v ? v.name.toUpperCase() : ""}</a>,
  },
  {
    value: "tags",
    name: "Tags",
    render: (v) => <>{v && v.map((s) => <Tag>{s.name}</Tag>)}</>,
  },
  {
    value: "totatlCost",
    value2: "currency",
    name: "Prix Total",
    render: (v, v2) => (
      <b style={{ color: "#53af50", fontSize: "18px" }}>
        {v.toFixed(3) + " "}
        <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
          {v2}
        </span>
      </b>
    ),
  },
  // {
  //   value: "services",
  //   name: "Services",
  //   render: (v) => (
  //     <>
  //       {serviceTypes
  //         .filter((el) => v.includes(el.value))
  //         .map((s) => (
  //           <Tag color="violet">{s.label}</Tag>
  //         ))}
  //     </>
  //   ),
  // },
  {
    value: "date",
    name: "Date",
    render: (v) => <strong>{moment(v).format("DD MMM YYYY")}</strong>,
  },
  {
    value: "notes",
    name: "Notes",
    render: (v) => {
      return (
        <div
          style={{
            maxWidth: "260px",
            padding: "10px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {v}
        </div>
      );
    },
  },
];
