class T {
  cost = 0; // duration * unitCost
  designation = "";
}
export class PackageUmrahMealType extends T {
  totalPrice = 0;
}

export class DisponibilityUmrahModel {
  airlineId;
  date;
  pnr;
  index;
  onStock;
  cost = 0;
  constructor() {
    this.index = Date.now();
  }
}
export default class PackageUmrahModel {
  name = "";
  date = new Date();
  commission = 0;
  b2Bcommission = 0;
  nbr_places = 1;
  hotels = []; // [HotelUmrah]
  packageUmrahCosts = [];
  differentFees = [];
  nbr_for_free_place = 0;
  isDefault = false;
  prices = [];
  disponibilities = [new DisponibilityUmrahModel()];
}
export class HotelUmrah {
  constructor() {
    this.index = Date.now();
  }
  name = "";
  providerId = null;
  duration = 0;
  arrangements = []; //
  currencyId;
  exchange = 0;
  packageUmrahMealTypes = [];
  index = null;
}
export class Arrangement extends T {
  constructor() {
    super();
    this.index = Date.now();
  }
  unitCost = 0; // by night
  index = null; // for the front end desting ...
}
export class PackageUmrahCost extends T {
  packageUmrahId;
  providerId;

  currencyId;
  exchange = 0;
  tag = "";
}

export class DifferentFees extends T {}
export class PriceUmrah {
  arrangementMakkahIndex;
  hotelMakkahIndex;
  hotelMadinahIndex;
  arrangementMadinahIndex;
  baseCost = 0;
  price = 0;
  isHidden = false;
}
