import { useRecoilValue } from "recoil";
import { Input, Message, SelectPicker, TagInput, TagPicker } from "rsuite";
import { CurrencyAtom } from "../../../Atoms/currency.atom";
import { serviceTypes } from "../../../Constants/types";

function AddEdit({ _setmodel, error, model }) {
  const currencies = useRecoilValue(CurrencyAtom);

  return (
    <>
      <label>Nom:</label>
      <Input
        onChange={(name) => {
          _setmodel((prev) => {
            return { ...prev, name };
          });
        }}
        value={model.name}
      />
      <label>Services Fournies </label>
      <TagPicker
        data={serviceTypes}
        block
        onSelect={(s) => {
          console.log(s);
          _setmodel((prev) => {
            return { ...prev, serviceTypes: s.join() };
          });
        }}
        onChange={(s) => {
          console.log(s);
          _setmodel((prev) => {
            return { ...prev, serviceTypes: s.join() };
          });
        }}
        value={
          model.serviceTypes
            ? model.serviceTypes.split(",").map((el) => parseInt(el))
            : null
        }
      />

      <label>Devis </label>
      <TagPicker
        data={currencies.map((c) => {
          return { label: c.name, value: c.id };
        })}
        block
        onSelect={(s) => {
          console.log(s);
          _setmodel((prev) => {
            return { ...prev, currencies: s.map((el) => ({ currencyId: el })) };
          });
        }}
        onChange={(s) => {
          console.log(s);
          _setmodel((prev) => {
            return { ...prev, currencies: s.map((el) => ({ currencyId: el })) };
          });
        }}
        value={
          model.currencies ? model.currencies.map((el) => el.currencyId) : null
        }
      />

      <label>Code</label>
      <Input
        value={model.code}
        onChange={(code) => {
          _setmodel((prev) => {
            return { ...prev, code };
          });
        }}
      />
      <label>Type</label>
      <SelectPicker
        block
        data={[
          { label: "Terrestre", value: 1 },
          { label: "Aerien", value: 2 },
        ]}
        searchable={false}
        style={{ width: 224 }}
        value={model.type}
        onChange={(type) => {
          _setmodel((prev) => {
            return { ...prev, type };
          });
        }}
      />
      <label>Ville</label>
      <Input
        value={model.city}
        onChange={(city) => {
          _setmodel((prev) => {
            return { ...prev, city };
          });
        }}
      />
      <label>Adresse</label>
      <Input
        value={model.address}
        onChange={(address) => {
          _setmodel((prev) => {
            return { ...prev, address };
          });
        }}
      />
      <label>Emails</label>
      <TagInput
        block
        size="md"
        //   placeholder="emails "
        value={model.emails ? model.emails.split(",") : []}
        onChange={(emails) => {
          let m = { ...model };

          m.emails = emails.join(",");
          _setmodel(m);
        }}
      />
      <label>Télephones</label>
      <TagInput
        block
        size="md"
        // placeholder="numéros des télephones"
        value={model.phones ? model.phones.split(",") : []}
        onChange={(phones) => {
          model.phones = phones.join(",");
          let m = { ...model };
          _setmodel(m);
        }}
      />
      <label>Code Tax </label>
      <Input
        value={model.taxCode}
        onChange={(taxCode) => {
          _setmodel((prev) => {
            return { ...prev, taxCode };
          });
        }}
      />

      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
