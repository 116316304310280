import React from "react";
import AddEdit from "./AddEdit.component";
import { useState } from "react";
import { APi } from "../../Api";
import { PackageUmrahAtom } from "../../Atoms/packageUmrah.atom";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { DefaultTemplateUmrah } from "../../Atoms/defaultuMrahPack";

export default function DefaultPack() {
  const [providers, setproviders] = useState([]);
  const [model, setmodel] = useRecoilState(PackageUmrahAtom);
  const [template, settemplate] = useRecoilState(DefaultTemplateUmrah);

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };
  const get = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getDefault", {})
      .customGet()
      .then((res) => {
        let m = { ...res.data };
        console.log(res.data);
        setmodel((prev) => m);
        // let _m = { ...res.data };
        // delete _m.id;
        // _m.isDefault = false;
        // let _d = Date.now();

        // if (_m.disponibilities) {
        //   _m.disponibilities = _m.disponibilities.map((d, i) => {
        //     delete d.id;
        //     return { ...d, index: _d + i };
        //   });
        // }
        // if (_m.differentFees) {
        //   _m.differentFees = _m.differentFees.map((d, i) => {
        //     delete d.id;
        //     return { ...d, index: _d + i };
        //   });
        // }
        // if (_m.packageUmrahCosts) {
        //   _m.packageUmrahCosts = _m.packageUmrahCosts.map((d, i) => {
        //     delete d.id;
        //     return { ...d, uuid: _d + i };
        //   });
        // }
        // if (_m.prices) {
        //   _m.prices = _m.prices.map((d, i) => {
        //     delete d.id;
        //     return d;
        //   });
        // }
        // if (_m.hotels) {
        //   _m.hotels = _m.hotels.map((d, i) => {
        //     delete d.id;
        //     if (d.arrangements) {
        //       d.arrangements = d.arrangements.map((a, j) => {
        //         delete a.id;
        //         return { ...a, index: _d + j };
        //       });
        //     }
        //     if (d.packageUmrahMealTypes) {
        //       d.packageUmrahMealTypes = d.packageUmrahMealTypes.map((a, j) => {
        //         delete a.id;
        //         return { ...a, index: _d + j };
        //       });
        //     }

        //     return { ...d, uuid: i + 1 };
        //   });
        // }
        // delete _m.id;
        // _m.isDefault = false;
        // settemplate(_m);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    get();
  }, []);
  const save = () => {
    let m = { ...model, isDefault: true };

    console.log(m);

    if (m.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
        .update(m.id, m)
        .then((res) => {
          get();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {});
    } else
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah)
        .create(m)
        .then((res) => {
          get();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {});
  };
  return (
    <div style={{ background: "#fff", padding: "10px", borderRadius: "5px" }}>
      <h5>Template</h5>
      <AddEdit
        providers={providers}
        fetchProviders={fetchProviders}
        isDefault
        save={save}
      />
    </div>
  );
}
