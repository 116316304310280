import { Button, Checkbox, Tag } from "rsuite";
import Grid from "../../Components/Grid";
import { useEffect, useState } from "react";
import { APi } from "../../Api";
import { ENDPOINTS } from "../../Api/enpoints";
import axios from "axios";
import { BASE_URL } from "../../Config/api.config";
import ImageIcon from "@rsuite/icons/Image";
import { countries } from "../../Constants/countries";

const Visas = ({ groupId, group, setgroup }) => {
  const [pilgrims, setpilgrims] = useState([]);
  const [checkeds, setcheckeds] = useState([]);

  const [stop, setstop] = useState(false);
  const [index, setindex] = useState(0);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    localStorage.setItem("stop1", true);
    setcheckeds([]);
  }, []);
  const send_recursive = async (i, els) => {
    setindex(els[i].id);

    let _st = localStorage.getItem("stop1");
    if (_st == "false") {
      let _res = await axios.post("http://localhost:8733/PrintVisaHajj", {
        ...els[i],
        firstName: els[i].customer.firstName,
        passportNumber: els[i].customer.passportNumber,
      });
      let _el = { ...els[i] };
      const reg = /^-?\d+\.?\d*$/;
      // if (reg.test(_res.data)) alert("true");
      if (
        _res.status == 200 &&
        _res.data &&
        _res.data.includes(",") &&
        reg.test(_res.data.split(",")[0]) &&
        !_res.data.startsWith("Error")
      ) {
        _el.errorMessage = "";
        _el.visaCode = _res.data.split(",")[0];
        _el.wakil = _res.data.split(",")[1];
        _el.hasVisa = true;
      } else {
        _el.errorMessage = _res.data ? _res.data : _el.errorMessage;
        if (
          !(_res.data.includes(",") && reg.test(_res.data.split(",")[0])) &&
          !_el.hasVisa
        ) {
          _el.hasVisa = false;

          _el.visaCode = "";
        }
      }
      setpilgrims((prev) => {
        let da = [...prev];
        let j = da.findIndex((ell) => ell.id == els[i].id);
        da[j] = _el;
        return da;
      });

      delete _el.client;
      delete _el.disponibility;
      delete _el.packageUmrah;
      delete _el.price;
      APi.createAPIEndpoint(ENDPOINTS.Order + "/Umrah")
        .update2(_el)
        .then((res) => {
          if (i < els.length - 1) {
            send_recursive(i + 1, els);
          } else setloading(false);
        })
        .catch((e) => {
          console.log("index: ", i);
          if (i < els.length - 1) {
            send_recursive(i + 1, els);
          } else setloading(false);
        });
    }
  };

  const fetch = () => {
    APi.createAPIEndpoint(ENDPOINTS.Order + "/Umrah", {
      page: 1,
      take: 11000,
      groupId,
    })
      .customGet()
      .then((res) => setpilgrims(res.data.data));
  };
  useEffect(() => {
    fetch();
    console.log(group);
  }, [groupId]);
  const send = async () => {
    localStorage.setItem("stop1", false);
    setloading(true);

    let els = pilgrims
      .filter((p1) => checkeds.find((_p) => _p == p1.id) && !p1.withoutVisa)
      .map((p) => ({
        ...p,

        country: countries.find((el) => el.code == p.customer.nationality)
          ? countries.find((el) => el.code == p.customer.nationality).alpha3
          : "TUN",
        id: p.id,
      }));
    await send_recursive(0, els);
  };
  const columns = [
    {
      value: "customer",
      value2: "id",
      name: " Nom",
      render: (v, id) => (
        <b style={{ color: "green", display: "flex", alignItems: "center" }}>
          <Checkbox
            onChange={(v) => {
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => [...prev, id]);
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>
          <a style={{ display: "flex", alignItems: "center" }}>
            {v.picture ? (
              <img
                alt=""
                src={
                  BASE_URL +
                  "Uploads/CustomerPictures/" +
                  v.picture +
                  "?" +
                  Date.now()
                }
                width="43"
                height="43"
                style={{
                  border: "1px solid #eee",
                  borderRadius: "50%",
                  boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
                }}
              />
            ) : (
              <div
                style={{
                  fontSize: "15px",
                  border: "1px dashed #888",
                  borderRadius: "50%",
                  width: "43px",
                  height: "43px",
                  textAlign: "center",
                  lineHeight: "42px",
                }}
              >
                <ImageIcon color="#3598ff"></ImageIcon>
              </div>
            )}
            <div style={{ padding: "0 5px" }}>
              {" "}
              {v.firstName + "  " + v.lastName}
              {"  "}
              <i
                style={{
                  color: "#999",
                  fontSize: "14px",
                  fontWeight: "lighter",
                }}
              >
                {v ? v.passportNumber : ""}
              </i>
              <br></br>{" "}
              <i style={{ color: "#444" }}>
                {v.firstNameAr + "  " + v.lastNameAr}
              </i>
            </div>
          </a>
        </b>
      ),
    },

    {
      value: "errorMessage",
      value2: "visaCode",
      value3: "codeMoufa",
      name: "Resultat",
      render: (v, v2, v3) => (
        <span
          style={{
            color: v == "Error" ? "red" : v == "Success" ? "green" : "",
          }}
        >
          {v2 ? <Tag color="blue">réussi</Tag> : v3 || v}
        </span>
      ),
    },
  ];
  return (
    <div>
      <div style={{ textAlign: "right", padding: "10px" }}>
        <Button appearance="primary" color="" onClick={setgroup}>
          retour
        </Button>{" "}
        <Button appearance="primary" color="blue" onClick={send}>
          visas
        </Button>{" "}
        <Button
          appearance="primary"
          color="red"
          onClick={() => {
            setstop(true);
            localStorage.setItem("stop1", true);
            setloading(false);
          }}
        >
          arréter
        </Button>
      </div>
      <div
        onClick={(e) =>
          setcheckeds((prev) =>
            prev.length ? [] : pilgrims.map((el) => el.id)
          )
        }
        style={{
          display: "flex",
          alignItems: "center",

          padding: "8px",
          borderRadius: "4px",
          background: "#fff",
        }}
      >
        <Checkbox checked={checkeds.length == pilgrims.length}></Checkbox>{" "}
        Sélectionner Tout
      </div>
      {loading && (
        <div
          style={{
            display: "flex ",
            alignItems: "center",
            border: "1px solid #eee",
            padding: "10px",
            background: "#fff",
            maxWidth: "800px",
            margin: "0 auto",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            En cours :
            <b style={{ color: "green" }}>
              {pilgrims.find((p) => p.id == index) &&
                pilgrims.find((p) => p.id == index).customer.firstName +
                  "  " +
                  pilgrims.find((p) => p.id == index).customer.lastName}{" "}
              <br></br>
              <i style={{ color: "#444" }}>
                {pilgrims.find((p) => p.id == index) &&
                  pilgrims.find((p) => p.id == index).customer.firstNameAr +
                    "  " +
                    pilgrims.find((p) => p.id == index).customer.lastNameAr}
              </i>
            </b>
          </div>
          <img alt="...loading" src="/svg-loaders/spinning-circles.svg"></img>
        </div>
      )}
      <Grid columns={columns} rows={pilgrims} />
    </div>
  );
};

export default Visas;
