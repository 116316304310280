import React, { useEffect } from "react";
import Responsive from "./Components/Responsive";
import { useRecoilState } from "recoil";
import { ConfigState } from "./Atoms/configs.atom";
import { Button, Input } from "rsuite";
import { APi } from "./Api";
import { ENDPOINTS } from "./Api/enpoints";

export default function Configs() {
  const [conf, setconf] = useRecoilState(ConfigState);
  useEffect(() => {}, []);
  const save = () => {
    if (conf.id) {
      APi.createAPIEndpoint(ENDPOINTS.Config)
        .update(conf.id, conf)
        .then((res) => alert("bien modifié"));
    } else {
      APi.createAPIEndpoint(ENDPOINTS.Config)
        .create({ ...conf })
        .then((res) => alert("bien enregistré"));
    }
  };
  return (
    <div>
      <Responsive l={6} xl={6} className="p-10">
        <label>Nom d'utilisateur Nusuk : </label>
        <Input
          value={conf.login}
          onChange={(login) => setconf((prev) => ({ ...prev, login }))}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Mot de passe de Nusuk: </label>

        <Input
          value={conf.password}
          onChange={(password) => setconf((prev) => ({ ...prev, password }))}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Email : </label>
        <Input
          type="email"
          value={conf.email}
          onChange={(email) => setconf((prev) => ({ ...prev, email }))}
        />
      </Responsive>
      <Responsive l={6} xl={6} className="p-10">
        <label>Téléphone : </label>
        <Input
          type="phone"
          value={conf.phone}
          onChange={(phone) => setconf((prev) => ({ ...prev, phone }))}
        />
      </Responsive>
      <Button appearance="primary" onClick={save}>
        Enregistrer
      </Button>
    </div>
  );
}
