import { atom } from "recoil";
import { SaleModel } from "../Models/SaleModels";
export const saleSate = atom({
  key: "saleSate", // unique ID (with respect to other atoms/selectors)
  default: new SaleModel(), // default value (aka initial value)
});

export const _serviceTypes = atom({
  key: "services", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});
