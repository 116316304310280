import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Pagination, SelectPicker } from "rsuite";
// import AccountsPayableLedgerDetails from "./LdgerDetails";
import { APi } from "../../Api";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import format_number from "../../Helpers/number_formatter";
import AccountsPayableLedgerDetails from "./LdgerDetails";
import VisibleIcon from "@rsuite/icons/Visible";
import { serviceTypes } from "../../Constants/types";
import { useRecoilState } from "recoil";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";

export default function AccountsPayableLedger() {
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [clients, setclients] = useState([]);
  const [state, setstate] = useRecoilState(exportAddAtom);

  const [id, setid] = useState(null);

  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) => setclients(res.data));
    }
  };
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    clientId: 0,
    date: null,
  });
  const columns = [
    {
      value: "account",
      name: "Client",
      render: (v) => {
        return (
          <b
            // href="javascript:void(0)"

            style={{
              color: "#4667d1",
              fontSize: "16px",
              textTransform: "upperCase",
              cursor: "pointer",
              fontWeight: "500",
            }}
          >
            {v.name}
          </b>
        );
      },
    },
    {
      name: "détails",
      value: "account",
      render: (v) => (
        <button
          onClick={() => setid(v.id)}
          style={{
            color: "rgba(67,55,160,1)",
            padding: "6px 10px",
            fontSize: "12px",
            background: "rgba(67,55,160,0.1)",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <VisibleIcon /> {"  "}
        </button>
      ),
    },
    {
      value: "totalDebit",
      name: "Débit Total",
      render: (v) => (
        <b style={{ color: "#53af50", fontSize: "18px" }}>
          {v.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {"TND"}
          </span>
        </b>
      ),
    },
    {
      value: "totalCredit",
      name: "Crédit Total",
      render: (v) => (
        <b style={{ color: "#5a2c3e", fontSize: "18px" }}>
          {v.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {"TND"}
          </span>
        </b>
      ),
    },

    {
      value: "totalGarantee",
      name: "Garantie",
      render: (v) => (
        <b style={{ color: "#2c92bf", fontSize: "18px" }}>
          {v.toFixed(3) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {"TND"}
          </span>
        </b>
      ),
    },
    {
      value: "totalDebit", // vente
      value2: "totalCredit", // reglement
      name: "Solde",
      render: (v, v2) => (
        <b style={{ color: v2 - v < 0 ? "#e33" : "#53af50", fontSize: "18px" }}>
          {(v2 - v == 0 ? 0 : format_number(v2 - v)) + " "}
          <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
            {"TND"}
          </span>
        </b>
      ),
    },
  ];

  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(
      APi.ENDPOINTS.Ledger,
      {
        ...filterModel,
        date: filterModel.date
          ? moment(filterModel.date).format("DD MMM YYYY")
          : null,
      },
      "/getLedgerClient"
    )
      .customGet()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {});
  };
  // LIFE CYCLES
  // LIFE CYCLES
  useEffect(() => {
    fetchClients();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter search={() => fetch(1)}>
        <Responsive className="p-10">
          <label>Client: </label>
          <SelectPicker
            onSearch={(q) => fetchClients(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              clients.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.clientId}
            onSelect={(clientId) => {
              setfilterModel((prev) => {
                return { ...prev, clientId };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        // excelData={multiDataSet(data)}
        nameExcel="GL"
        noAdd
      />
      <Grid
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
      <Modal size="lg" overflow={true} open={id} onClose={() => setid(null)}>
        <Modal.Header>
          <Modal.Title>Détails Transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div id='Printed' style={{ width: "100vw", top: 0, height: "100vh" }}> */}

          {id && <AccountsPayableLedgerDetails id={id} />}
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setid(null)} appearance="subtle">
            fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
