import { checkStatus, checkPurpose, serviceTypes } from "../../Constants/types";
import moment from "moment";
import format_number from "../../Helpers/number_formatter";

export const multiDataSet = (data) => {
  return [
    {
      columns: [
        { title: "Identifiant", width: { wpx: 120 } },
        { title: "Client", width: { wpx: 150 } }, //char width

        { title: "Tags", width: { wpx: 150 } },
        { title: "Prix Total", width: { wpx: 100 } }, //pixels width
        { title: "date", width: { wpx: 100 } }, //char width
        { title: "Services", width: { wpx: 150 } },

        { title: "Notes", width: { wpx: 100 } }, //pixels width
      ],
      data: data.map((item) => {
        let item2 = [
          {
            value: item.id,
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },
              font: { sz: "13", color: { rgb: "2590b5" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.client ? item.client.name : "",
            style: {
              font: { sz: "14", color: { rgb: "4c67c2" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.tags ? item.tags.map((el) => el.name).join(" , ") : "",
            style: {
              font: { sz: "14", color: { rgb: "5a2c3e" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: format_number(item.totatlPrice),
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: moment(item.date).format("DD MMM YYYY"),
            style: {
              font: { sz: "13", color: { rgb: "2590b5" } },
              alignment: { horizontal: "center" },
            },
          },
          {
            value: item.services
              ? serviceTypes
                  .filter(
                    (el) => item.services.findIndex((l) => l == el.value) > -1
                  )
                  .map((el) => el.label)
                  .join(",")
              : "",
            style: {
              fill: { fgColor: { rgb: "eeeeee" } },

              font: { sz: "13" },
              alignment: { horizontal: "center" },
            },
          },

          // {
          //   value: checkPurpose.find((el) => el.value == item.checkPurpose)
          //     .label,
          //   style: {
          //     font: {
          //       sz: "14",
          //       color: {
          //         rgb:
          //           item.checkPurpose == 1
          //             ? "673ab7"
          //             : item.checkPurpose == 2
          //             ? "5a2c3e"
          //             : "f0b217",
          //       },
          //     },
          //     alignment: { horizontal: "center" },
          //   },
          // },
          {
            value: item.note ? item.note : "",
            style: {
              font: {
                sz: "14",
              },
              alignment: { horizontal: "center" },
            },
          },
        ];
        return item2;
      }),
    },
  ];
};
