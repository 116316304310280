import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Swal from "sweetalert2";
import { APi } from "../../../Api/";
import { CurrencyAtom } from "../../../Atoms/currency.atom";
import { exportAddAtom } from "../../../Atoms/exportAdd.atom";
import ExportAdd from "../../../Components/Common/ExportAdd";
import Filter from "../../../Components/Common/Filter";
import Grid from "../../../Components/Grid";
import validate from "../../../Helpers/validate";
import AddEdit from "./AddEdit.component";
export default function Exchanges(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const currencies = useRecoilValue(CurrencyAtom);

  // const [filterModel, setfilterModel] = useState({ q: "", page: 1, take: 20 });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useState({
    rate: 1,
    currencySourceId: 0,
    currencyId: 0,
  });

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel((prev) => ({ rate: 1, currencySourceId: 0, currencyId: 0 }));
    setError("");
  };
  // API CALLS
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Exchange, {})
      .fetchAll()
      .then((res) => {
        setdata(res.data.map((el, i) => ({ ...el, id: i + 1 })));
      })
      .catch((e) => setError(e.Message));
  };
  const save = () => {
    let msg = validate(model, [{ name: "Nom" }, { code: "Iso Code" }]) && false;
    if (msg) setError(msg);
    else {
      setstate((prev) => {
        return { ...prev, loading: true };
      });
      if (model.id) {
        APi.createAPIEndpoint(APi.ENDPOINTS.Exchange + "/update")
          .create({ ...model, rate: Number(model.rate) })
          .then((res) => {
            fetch();
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Élément a été bien modifié !",
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      } else {
        APi.createAPIEndpoint(APi.ENDPOINTS.Exchange)
          .create({ ...model, rate: Number(model.rate) })
          .then((res) => {
            fetch();
            reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Element a été bien ajouté !",
              showConfirmButton: false,
              timer: 1500,
            });
            setstate((prev) => {
              return { ...prev, open: false, loading: false };
            });
          })
          .catch((e) => {
            setError(e.Message);
            setstate((prev) => {
              return { ...prev, loading: false };
            });
          });
      }
      reset();
    }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Exchange)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  const getBYId = (id) => {
    setError("");
    console.log(id);
    setmodel(data.find((el) => el.id === id));
  };
  const columns = [
    {
      value: "currencySourceId",
      name: "Source",
      render: (v) => (
        <a>
          {currencies.find((el) => el.id == v)
            ? currencies.find((el) => el.id == v).name
            : "-"}
        </a>
      ),
    },

    {
      value: "rate",
      name: "Code",
      render: (v) => <b>{v}</b>,
    },
    {
      value: "currencyId",
      name: "---",
      render: (v) => (
        <a>
          {currencies.find((el) => el.id == v)
            ? currencies.find((el) => el.id == v).name
            : "-"}
        </a>
      ),
    },
  ];
  // LIFE CYCLES
  useEffect(() => fetch(), []);
  return (
    <div>
      <ExportAdd
        size="md"
        noExport
        save={save}
        AddComponent={
          <AddEdit error={error} model={model} _setmodel={setmodel} />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);
          console.log(data);
          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
    </div>
  );
}
