import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  Checkbox,
  Divider,
  IconButton,
  Input,
  Message,
  SelectPicker,
  Steps,
  Uploader,
} from "rsuite";
import Responsive from "../../Components/Responsive";
import {
  AgeType,
  CustomerType,
  Gender,
  MaritalStatus,
  Titles,
  serviceTypes,
} from "../../Constants/types";

import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import ImageIcon from "@rsuite/icons/Image";
import axios from "axios";
import moment from "moment";
import { MdImage } from "react-icons/md";
import { previewFile } from "rsuite/esm/utils";
import { APi } from "../../Api";
import { SaleVoState } from "../../Atoms/SaleVo.atom";
import { SaleUmrahAtom } from "../../Atoms/saleOmrah.atom";
import Grid from "../../Components/Grid";
import mrzparser from "../../Helpers/mrzparser";
import { ExtratModel } from "../../Models/Package.model";
import SaleVoModel from "../../Models/SaleVoModel";
import { PriceOverviewItem2 } from "../PackageUmrah/PricesView";

import { BASE_URL } from "../../Config/api.config";
import { countries } from "../../Constants/countries";
const styles = {
  // width: "200px",
  // display: "inline-table",
  verticalAlign: "top",
};
function AddEdit({
  error,
  clients,
  fetchClients,
  providers,
  fetchProviders,
  vols = [],
  packs = [],
}) {
  const [current, setcurrent] = useState(0);
  const [selectedPack, setselectedPack] = useState(null);
  const [model, setmodel] = useRecoilState(SaleUmrahAtom);
  const [loadingPassport, setloadingPassport] = useState(false);
  const [passportFile, setpassportFile] = useState(null);
  const [validatePassport, setvalidatePassport] = useState("");

  useEffect(() => {
    console.log(model);
    setselectedPack(model.package);
  }, [model.id]);
  const readfile3 = async ($event) => {
    const files = $event.target.files;
    setloadingPassport(true);
    const file = files[0];
    setpassportFile(file);
    const formData = new FormData();

    // Update the formDalet ta object
    formData.append("File", file, file.name);
    let passportPicture = "";

    let _res = await APi.createAPIEndpoint(
      APi.ENDPOINTS.File + "/Passport"
    ).upload1(file);
    console.log(passportPicture);
    if (_res) {
      passportPicture = _res.data;
      setmodel((pr) => ({
        ...pr,
        customer: { ...pr.customer, passportPicture },
      }));
    }
    axios
      .post("https://scan.kounouz.travel/api/Scan/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setmodel((prev) => {
          let customer = { ...model.customer, passportPicture };
          //________________
          customer.birthDate = res.data.birthDate;
          customer.passportNumber = res.data.passportNumber;
          let country = countries.find(
            (c) => c.alpha3 == res.data.nationalityCountryId || c.iso == "TN"
          );
          if (country) {
            console.log(country);
            customer.nationality = country.code;
            customer.addressCountry = country.code;
            customer.birthCountry = country.code;
            customer.passportDeliveryCountry = country.code;
            customer.birthCity = country.country;
            customer.city = country.country;
            customer.passportDeliveryCity = country.country;
          }

          customer.mrz = res.data.mrz;
          customer.expirationPassportDate = res.data.passportExpiryDate;
          customer.lastNameAr = res.data.arlastName;
          customer.firstNameAr = res.data.arfirstName;
          customer.lastName = res.data.lastName;
          customer.firstName = res.data.firstName
            ? res.data.firstName.replace(res.data.lastName, "")
            : "";
          customer.name =
            res.data.firstName !== res.data.lastName
              ? res.data.firstName + " " + res.data.lastName
              : res.data.firstName;
          customer.gender = res.data.gender;
          customer.title = res.data.gender;
          customer.picture = res.data.pilgrimPicture;
          customer.job = "nothing";
          customer.status = 2;
          try {
            let parsedMrz = mrzparser.parse(res.data.mrz);
            customer.cin = parsedMrz.personalNumber.split(" ")[0];
            console.log(parsedMrz);
            //let parsedMrzExpiry = mrzparser.parse( res.data.mrz).expiry;

            let formatted = new Date(res.data.passportExpiryDate);
            var val = Date.parse(formatted);
            if (!isNaN(val)) {
              var d = new Date(val);
              var f = new Date();
              f.setDate(f.getDate() + 15);
              f.setMonth(f.getMonth() + 6);
              if (d < f && d > new Date()) {
                setvalidatePassport("Probleme de date d'expiration");
              } else {
                setvalidatePassport("");
              }
            }

            // console.log(formatted);
            formatted.setFullYear(formatted.getFullYear() - 5);

            customer.deliveryPassportDate = formatted;
            console.log(moment(customer.deliveryPassportDate));
            customer.deliveryPassportDate = moment(
              customer.deliveryPassportDate
            )
              .add(1, "days")
              .format("YYYY-MM-DD");
          } catch {}
          return { ...prev, customer };
        });
        setloadingPassport(false);
      })
      .catch((e) => setloadingPassport(false));
  };
  return (
    <>
      <Steps current={current} style={styles}>
        <Steps.Item title="Infos Génerales" onClick={() => setcurrent(0)} />
        <Steps.Item title="Pélerin" onClick={() => setcurrent(1)} />
        {/* <Steps.Item title="Additions + " onClick={() => setcurrent(2)} /> */}

        {/* <Steps.Item title="Attachments" onClick={() => setcurrent(3)} /> */}
      </Steps>

      <div style={{ padding: "40px 10px" }}>
        {!current ? (
          <General
            fetchClients={fetchClients}
            clients={clients}
            packs={packs}
            vols={vols}
          />
        ) : current == 1 ? (
          <>
            {validatePassport && (
              <Message showIcon type="error">
                {validatePassport}
              </Message>
            )}
            {error && (
              <Message showIcon type="error">
                {error}
              </Message>
            )}
            <div style={{ textAlign: "right" }}>
              {" "}
              <input
                id="hidden3"
                style={{
                  visibility: "hidden",
                  width: "0",
                  height: 0,
                  overflow: "hidden",
                }}
                type="file"
                onChange={readfile3}
                accept=".jpg,.jpeg,.png"
              />
              <IconButton
                loading={loadingPassport}
                icon={<MdImage />}
                onClick={() => {
                  const el = document.querySelector("input#hidden3");
                  console.log(el);
                  el.click();
                }}
                color="green"
                appearance="primary"
              ></IconButton>
              <br></br>
              <div
                style={{
                  width: "170px",
                  heigth: "90px",
                  display: "inline-block",
                }}
              >
                {" "}
                {(passportFile ||
                  (model.customer && model.customer.passportNumber)) && (
                  <Uploader
                    // listType="picture"
                    autoUpload={false}
                    action="#"
                    draggable
                    fileListVisible={false}
                    // onUpload={}
                    onChange={(files) => {
                      APi.createAPIEndpoint(APi.ENDPOINTS.File + "/Passport")
                        .upload1(files[files.length - 1].blobFile)
                        .then((res) =>
                          setmodel((pr) => ({
                            ...pr,
                            customer: {
                              ...pr.customer,
                              passportPicture: res.data,
                            },
                          }))
                        );
                      previewFile(
                        files[files.length - 1].blobFile,
                        (pic) => {}
                      );
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        width: "170px",
                        heigth: "90px",
                        display: "inline-block",
                        marginTop: "5px",
                      }}
                    >
                      {" "}
                      {model.customer.passportPicture ? (
                        <img
                          alt=""
                          src={
                            BASE_URL +
                            "Uploads/" +
                            model.customer.passportPicture +
                            "?" +
                            Date.now()
                          }
                          width="170"
                          height="90"
                          style={{
                            border: "1px dashed #888",
                            borderRadius: "5px",
                          }}
                        />
                      ) : (
                        <>
                          {" "}
                          <div style={{ fontSize: "40px" }}>
                            {" "}
                            <ImageIcon color="#3598ff"></ImageIcon>
                          </div>
                          <h4 style={{ color: "#bbb", fontWeight: "400" }}>
                            Passport
                            {/* pour les télécharger */}
                          </h4>
                        </>
                      )}{" "}
                    </div>
                  </Uploader>
                )}
                {model.customer.passportPicture ? (
                  <a
                    download={model.customer.passportPicture}
                    target="_blank"
                    href={
                      BASE_URL +
                      "Uploads/" +
                      model.customer.passportPicture +
                      "?t=" +
                      Date.now()
                    }
                  >
                    télécharger {model.customer.passportPicture}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Uploader
                // listType="picture"
                autoUpload={false}
                action="#"
                draggable
                fileListVisible={false}
                // onUpload={}
                onChange={(files) => {
                  APi.createAPIEndpoint(APi.ENDPOINTS.File + "/Customer")
                    .upload1(files[files.length - 1].blobFile)
                    .then((res) =>
                      setmodel((pr) => ({
                        ...pr,
                        customer: {
                          ...pr.customer,
                          picture: res.data,
                        },
                      }))
                    );

                  // setfile(files[files.length - 1].blobFile);
                  // previewFile(files[files.length - 1].blobFile, (picture) => {

                  // });
                }}
              >
                <div
                  style={{
                    background: "rgba(200,200,200,0.1)",
                    width: "130px",
                    height: "150px",
                    padding: "0",
                    display: "inline-block",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  {model.customer && model.customer.picture ? (
                    <img
                      width="130"
                      height="150"
                      src={
                        model.customer.picture.includes("base64")
                          ? model.customer.picture
                          : BASE_URL +
                            "Uploads/CustomerPictures/" +
                            model.customer.picture +
                            "?" +
                            Date.now()
                      }
                      style={{ borderRadius: "10px" }}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <div style={{ fontSize: "40px" }}>
                        {" "}
                        <ImageIcon color="#3598ff"></ImageIcon>
                      </div>
                      <h4 style={{ color: "#bbb", fontWeight: "400" }}>
                        Image
                        {/* pour les télécharger */}
                      </h4>
                    </>
                  )}
                </div>
              </Uploader>
            </div>
            {/* <Responsive m={6} l={6} xl={6} className="p-10">
              <label>Nom Complet:</label>
              <Input
                value={model.customer.name}
                onChange={(name) => {
                  let customer = { ...model.customer };

                  customer.name = name;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive> */}
            <label>Type : </label>
            <SelectPicker
              data={CustomerType}
              block
              noSearch
              value={model.customer.customerType}
              onSelect={(customerType) => {
                setmodel((prev) => {
                  return {
                    ...prev,
                    customer: { ...prev.customer, customerType },
                  };
                });
              }}
            />{" "}
            <Responsive l={6} xl={6} className="p-10">
              <label>Nom Arabe:</label>
              <Input
                onChange={(lastNameAr) => {
                  let customer = { ...model.customer };
                  customer.lastNameAr = lastNameAr;

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.lastNameAr}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>Prénom Arabe:</label>
              <Input
                onChange={(firstNameAr) => {
                  let customer = { ...model.customer };
                  customer.firstNameAr = firstNameAr;

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.firstNameAr}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>Nom :</label>
              <Input
                onChange={(lastName) => {
                  let customer = { ...model.customer, lastName };
                  customer.name = customer.firstName + " " + lastName;
                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.lastName}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>Prénom:</label>
              <Input
                onChange={(firstName) => {
                  let customer = { ...model.customer, firstName };
                  customer.name = firstName + " " + customer.lastName;
                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.firstName}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>Nom Du Pére :</label>
              <Input
                onChange={(fatherName) => {
                  let customer = { ...model.customer, fatherName };

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.fatherName}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>CIN:</label>
              <Input
                onChange={(cin) => {
                  let customer = { ...model.customer, cin };

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.cin}
              />
            </Responsive>
            <Responsive m={3} l={3} xl={3} className="p-10">
              <label>Tél:</label>
              <Input
                type="phone"
                value={model.customer.phoneNumber}
                onChange={(phoneNumber) => {
                  let customer = { ...model.customer, phoneNumber };

                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={3} l={3} xl={3} className="p-10">
              <label>Email:</label>
              <Input
                type="email"
                value={model.customer.email}
                onChange={(email) => {
                  let customer = { ...model.customer, email };

                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            {/* <Responsive m={6} l={5} xl={5} className="p-10">
              <label>N° Passport:</label>
              <Input
                value={model.customer.passportNumber}
                onChange={(passportNumber) => {
                  let customer = { ...model.customer };

                  customer.passportNumber = passportNumber;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive> */}
            <Responsive s={6} m={3} l={3} xl={3} className="p-10">
              <label>Type : </label>
              <SelectPicker
                data={Titles}
                block
                noSearch
                value={model.customer.title}
                onChange={(title) => {
                  let customer = { ...model.customer, title };

                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={3} l={3} xl={3} className="p-10">
              <label>Genre : </label>
              <SelectPicker
                data={Gender}
                block
                noSearch
                value={model.customer.gender}
                onChange={(gender) => {
                  let customer = { ...model.customer };

                  customer.gender = gender;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Lieu De Naissance:</label>
              <Input
                onChange={(birthCity) => {
                  let customer = { ...model.customer, birthCity };

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.birthCity}
              />
            </Responsive>
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Pays De Naissance : </label>
              <SelectPicker
                data={countries.map((el) => ({
                  label: el.country,
                  value: el.code,
                }))}
                block
                noSearch
                value={model.customer.birthCountry}
                onChange={(birthCountry) => {
                  let customer = { ...model.customer };

                  customer.birthCountry = birthCountry;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Date de naissance :</label>
              <Input
                type="date"
                value={
                  typeof model.customer.birthDate == "string"
                    ? model.customer.birthDate.substring(0, 10)
                    : model.customer.birthDate
                }
                onChange={(birthDate) => {
                  let customer = { ...model.customer };

                  customer.birthDate = birthDate;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Lieu De Résidance:</label>
              <Input
                onChange={(city) => {
                  let customer = { ...model.customer };

                  customer.city = city;
                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.city}
              />
            </Responsive>{" "}
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Pays De Sejour: </label>
              <SelectPicker
                data={countries.map((el) => ({
                  label: el.country,
                  value: el.code,
                }))}
                block
                noSearch
                value={model.customer.addressCountry}
                onChange={(addressCountry) => {
                  let customer = { ...model.customer };

                  customer.addressCountry = addressCountry;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Nationalité: </label>
              <SelectPicker
                data={countries.map((el) => ({
                  label: el.country,
                  value: el.code,
                }))}
                block
                noSearch
                value={model.customer.nationality}
                onChange={(nationality) => {
                  let customer = { ...model.customer };

                  customer.nationality = nationality;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <hr></hr>
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Passport:</label>
              <Input
                onChange={(passportNumber) => {
                  setmodel((prev) => {
                    return {
                      ...prev,
                      customer: { ...prev.customer, passportNumber },
                    };
                  });
                }}
                value={model.customer.passportNumber}
              />
            </Responsive>{" "}
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Pays D'Issuance : </label>
              <SelectPicker
                data={countries.map((el) => ({
                  label: el.country,
                  value: el.code,
                }))}
                block
                noSearch
                value={model.customer.passportDeliveryCountry}
                onChange={(passportDeliveryCountry) => {
                  let customer = { ...model.customer };

                  customer.passportDeliveryCountry = passportDeliveryCountry;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Ville D'Issuance :</label>
              <Input
                onChange={(passportDeliveryCity) => {
                  setmodel((prev) => {
                    return {
                      ...prev,
                      customer: { ...prev.customer, passportDeliveryCity },
                    };
                  });
                }}
                block
                value={model.customer.passportDeliveryCity}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Date D'Issuance :</label>
              <Input
                type="date"
                onChange={(deliveryPassportDate) => {
                  setmodel((prev) => {
                    return {
                      ...prev,
                      customer: { ...prev.customer, deliveryPassportDate },
                    };
                  });
                }}
                block
                value={
                  typeof model.customer.deliveryPassportDate == "string"
                    ? model.customer.deliveryPassportDate.substring(0, 10)
                    : model.customer.deliveryPassportDate
                }
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label style={{ color: validatePassport ? "red" : "" }}>
                Date d'expiration :
              </label>
              <Input
                type="date"
                value={
                  typeof model.customer.expirationPassportDate == "string"
                    ? model.customer.expirationPassportDate.substring(0, 10)
                    : model.customer.expirationPassportDate
                }
                onChange={(expirationPassportDate) => {
                  let customer = { ...model.customer };

                  customer.expirationPassportDate = expirationPassportDate;
                  customer.deliveryPassportDate =
                    model.customer.expirationPassportDate.substring(0, 10);

                  try {
                    let formatted = new Date(expirationPassportDate);
                    var val = Date.parse(formatted);
                    if (!isNaN(val)) {
                      var d = new Date(val);
                      var f = new Date();
                      f.setDate(f.getDate() + 15);
                      f.setMonth(f.getMonth() + 6);
                      if (d < f && d > new Date()) {
                        setvalidatePassport("Probleme de date d'expiration");
                      } else {
                        setvalidatePassport("");
                      }
                    }

                    // console.log(formatted);
                    formatted.setFullYear(formatted.getFullYear() - 5);

                    customer.deliveryPassportDate = formatted;
                    customer.deliveryPassportDate = moment(
                      customer.deliveryPassportDate
                    )
                      .add(1, "days")
                      .format("YYYY-MM-DD");
                  } catch {}
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <label>MRZ</label>
            <Input
              as="textarea"
              rows={3}
              onChange={(mrz) => {
                setmodel((prev) => {
                  return { ...prev, customer: { ...prev.customer, mrz } };
                });
              }}
              value={model.customer.mrz}
            />
            <Responsive m={6} l={4} xl={4} className="p-10">
              <label>Status : </label>
              <SelectPicker
                data={MaritalStatus}
                noSearch
                block
                value={model.customer.status}
                onChange={(status) => {
                  let customer = { ...model.customer };

                  customer.status = status;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Métier:</label>
              <Input
                onChange={(job) => {
                  setmodel((prev) => {
                    return {
                      ...prev,
                      customer: { ...prev.customer, job },
                    };
                  });
                }}
                value={model.customer.job}
              />
            </Responsive>
          </>
        ) : (
          <Extrats providers={providers} fetchProviders={fetchProviders} />
        )}
      </div>
      <br></br>

      <Divider />
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;

const General = ({ clients, fetchClients, packs, vols }) => {
  const [model, _setmodel] = useRecoilState(SaleUmrahAtom);
  const [pack, setpack] = useState([]);
  useEffect(() => {
    let _pack = packs.find((el) => model.packageUmrahId === el.id);

    if (_pack) setpack(_pack);
  }, [model.id]);
  return (
    <>
      <label>Client: </label>
      <SelectPicker
        onSearch={(q) => fetchClients(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          clients.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.clientId}
        onSelect={(clientId) => {
          _setmodel((prev) => {
            return {
              ...prev,
              clientId,
              client: clients.find((el) => el.id == clientId),
            };
          });
        }}
      />
      {/* {model.clientId ? ( */}
      {/* <div
        style={{
          background: "rgb(107,121,196)",
          color: "white",
          padding: "10px",
          borderRadius: "5px",
          margin: "10px",
        }}
      >
       
        <label>Remise/addition</label>
        <Input
          type="number"
          value={model.regulated_value}
          onChange={(regulated_value) => {
            console.log(regulated_value);
            _setmodel((prev) => {
              return {
                ...prev,
                regulated_value: parseFloat(regulated_value),
              };
            });
          }}
          block
        />
      </div>
      */}
      <label>Vols : </label>
      <SelectPicker
        data={vols.map((c, i) => {
          return {
            label: (
              <b
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "3px",
                  background: i % 2 ? "rgb(70,103,209,0.2)" : "",
                  borderRadius: "5px",
                }}
              >
                <img
                  alt=""
                  style={{ width: "30px", height: "30px", margin: "0 6px" }}
                  src={c.airline ? c.airline.logo : ""}
                ></img>
                <b>
                  {" "}
                  <div style={{ color: "#232323" }}>
                    {moment(c.date).format("DD MMM YYYY")}{" "}
                    {moment(c.date).format("DD MMM YYYY")} ({" "}
                    <i style={{ color: "#999" }}>
                      {packs &&
                        packs.find((l) => l.id == c.packageUmrahId) &&
                        packs.find((l) => l.id == c.packageUmrahId).name}
                    </i>{" "}
                    )
                  </div>
                  <b style={{ color: "#888", fontWeight: "lighter" }}>
                    {" "}
                    {c.airline ? c.airline.name : ""}
                  </b>
                </b>
              </b>
            ),
            value: c.id,
          };
        })}
        block
        noSearch
        value={model.disponibilityId}
        onSelect={(disponibilityId) => {
          let ___pack = packs.find((el) =>
            el.disponibilities.find((d) => d.id == disponibilityId)
          );
          setpack(___pack);

          _setmodel((prev) => {
            return { ...prev, disponibilityId, packageUmrahId: ___pack.id };
          });
        }}
      />{" "}
      {pack && (
        <>
          <PriceOverviewItem2
            pack={pack}
            visa={
              pack.packageUmrahCosts
                ? pack.packageUmrahCosts.find((el) =>
                    el.designation.toUpperCase().includes("VISA")
                  ).cost *
                  pack.packageUmrahCosts.find((el) =>
                    el.designation.toUpperCase().includes("VISA")
                  ).exchange
                : 0
            }
            prices={
              pack.prices
                ? pack.prices
                    .filter((p) => !p.isHidden)
                    .map((p) => {
                      let disp = vols.find(
                        (el) => el.id == model.disponibilityId
                      );
                      let c = 0;
                      if (disp) {
                        c =
                          disp.commission +
                          disp.b2Bcommission -
                          (pack.commission + pack.b2Bcommission);
                      }
                      let _p = { ...p, price: p.price + c };
                      return _p;
                    })
                : []
            }
            model={model}
            _setmodel={_setmodel}
          />
        </>
      )}
      {/* <div
          style={{
            margin: "10px 0",
          }}
        >
          <label>Choir parmi les choix: </label>
          <table style={{ borderRadius: "5px !important" }}>
            {pack.prices
              ? pack.prices
                  .filter((p) => !p.isHidden)
                  .map((p, i) => {
                    return (
                      <tr
                        style={{
                          padding: "5px",
                          borderRadius: "5px !important",
                          background: i % 2 ? "rgb(70,103,209,0.2)" : "",
                        }}
                      >
                        <td
                          style={{
                            padding: "5px",
                          }}
                        >
                          <Checkbox
                            onChange={(v) => {
                              _setmodel((prev) => ({ ...prev, priceId: p.id }));
                            }}
                            checked={model.priceId == p.id}
                          />{" "}
                          {
                            <Tag
                              color={
                                ["red", "green", "yellow"][
                                  roomTypes.findIndex(
                                    (__el) =>
                                      __el.label ==
                                      p.arrangementMakkahDesignation
                                  )
                                ]
                              }
                            >
                              {p.arrangementMakkahDesignation}
                            </Tag>
                          }
                        </td>
                        <td>
                          {" "}
                          <img
                            alt=""
                            src="/makkah.png"
                            style={{ width: "40px" }}
                          />
                          {p.hotelMakkahName}
                        </td>
                        <td>
                          {" "}
                          <img
                            alt=""
                            src="/madinah4.png"
                            style={{ width: "40px" }}
                          />
                          {p.hotelMadinahName}
                        </td>
                        <td>
                          <b style={{ color: "#232323", fontSize: "17px" }}>
                            {p.price}
                            <span
                              style={{
                                color: "#888",
                                fontSize: "11px",
                                fontWeight: "400",
                              }}
                            >
                              TND
                            </span>
                          </b>
                        </td>
                      </tr>
                    );
                  })
              : ""}
          </table>
          <div
            style={{
              marginTop: "5px",
              background: "#fff",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            {pack.hotels &&
              pack.hotels
                .filter((h) =>
                  pack.prices.find(
                    (p) =>
                      h.name == p.hotelMadinahName ||
                      h.name == p.hotelMakkahName
                  )
                )
                .map((h) => {
                  if (h.packageUmrahMealTypes)
                    return h.packageUmrahMealTypes.map((m) => (
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "5px",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          margin: "2px",
                        }}
                      >
                        <b
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "25px",
                              color: "rgb(70, 103, 209)",
                              borderRadius: "50%",
                              border: "1px solid #eee",
                              width: "40px",
                              height: "40px",
                              textAlign: "center",
                              display: "inline-block",
                              marginRight: "8px",
                              background: "rgb(70, 103, 209,0.2)",
                            }}
                          >
                            <MdOutlineFoodBank />{" "}
                          </span>
                          <span>
                            {customer.designation}
                            <br></br>
                            <span
                              style={{
                                textTransform: "uppercase",
                                color: "#888",
                                fontWeight: "lighter",
                              }}
                            >
                              {h.name}{" "}
                              <span style={{ fontSize: "0.7em" }}>
                                {h.isMakkah ? "Makkah" : "Madinah"}
                              </span>
                            </span>
                          </span>
                        </b>{" "}
                        <b
                          style={{
                            color: "#53af50",
                            fontSize: "18px",
                            margin: "3px",
                          }}
                        >
                          {m.totalPrice
                            ? format_number(m.totalPrice)
                            : format_number(h.duration * h.exchange *customer.cost)}
                          <span
                            style={{
                              color: "#888",
                              fontSize: "11px",
                              fontWeight: "400",
                            }}
                          >
                            TND
                          </span>
                        </b>
                      </div>
                    ));
                })}
          </div>
        </div> */}
      <br></br>
      <label>Date De vente: </label>
      <Input
        type="date"
        value={model.date}
        onChange={(date) => {
          console.log(date);
          _setmodel((prev) => {
            return { ...prev, date };
          });
        }}
        // onSelect={(date) =>
        //   _setmodel((prev) => {
        //     return { ...prev, date };
        //   })
        // }
        // block
      />
      <br></br>
      <label>
        N'est pas confirmé:{" "}
        <input
          type="checkbox"
          // onClick={(e) => {
          //   _setmodel((prev) => ({
          //     ...prev,
          //     confirmationStatus: prev.confirmationStatus == 2 ? 1 : 2,
          //   }));
          // }}
          onChange={(e) => {
            _setmodel((prev) => ({
              ...prev,
              confirmationStatus: prev.confirmationStatus == 2 ? 1 : 2,
            }));
          }}
          checked={model.confirmationStatus == 2}
        ></input>
      </label>
      <br></br>
      <label>Note: </label>
      <Input
        as="textarea"
        value={model.note}
        onChange={(note) => {
          console.log(note);
          _setmodel((prev) => {
            return { ...prev, note };
          });
        }}
        // onSelect={(date) =>
        //   _setmodel((prev) => {
        //     return { ...prev, date };
        //   })
        // }
        // block
      />
      <br></br>
      <label>
        empreinte digitale <br></br>{" "}
        <input
          type="checkbox"
          // onClick={(e) => {
          //   _setmodel((prev) => ({
          //     ...prev,
          //     confirmationStatus: prev.confirmationStatus===2 ? 1 : 2,
          //   }));
          // }}
          onChange={(e) => {
            _setmodel((prev) => ({
              ...prev,
              fingerprint: !prev.fingerprint,
            }));
          }}
          checked={model.fingerprint}
        ></input>
      </label>
    </>
  );
};
const Extrats = ({ providers, fetchProviders }) => {
  const [services, setservices] = useState(serviceTypes);
  const [model, _setmodel] = useRecoilState(SaleVoState);
  const [state, setstate] = useState(new SaleVoModel());
  const save = () => {
    _setmodel((prev) => {
      console.log(prev);
      let m = { ...prev };
      if (typeof m.extrats != "object") m.extrats = [];
      if (typeof state.index == "number") {
        let extrats = [...m.extrats];
        extrats[state.index] = state;

        m = { ...m, extrats };
      } else {
        let extrats = [...m.extrats];
        extrats.push(state);
        m = { ...m, extrats };
      }
      return m;
    });
    setstate(new ExtratModel());
  };
  const _delete = (index) => {
    _setmodel((prev) => {
      let m = { ...prev };
      let extrats = [...m.extrats];
      extrats.splice(index, 1);
      m = { ...m, extrats };
      return m;
    });
  };
  const setEditedElement = (index) => {
    setstate({ ...model.extrats[index], index });
  };
  return (
    <>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Fournisseur : </label>
        <SelectPicker
          onSearch={(q) => fetchProviders(q)}
          data={providers.map((c) => {
            return { label: c.name, value: c.id };
          })}
          block
          noSearch
          value={state.providerId}
          onSelect={(providerId) => {
            let p = providers.find((_) => _.id == providerId);
            console.log(p);

            // if (p && p.serviceTypes) {
            //   console.log("test");
            //   setservices((prev) => {
            //     return [
            //       ...serviceTypes.filter((ss) =>
            //         p.serviceTypes.includes(ss.value)
            //       ),
            //     ];
            //   });
            // }

            setstate((prev) => {
              return { ...prev, providerId };
            });
          }}
        />
      </Responsive>

      <Responsive s={6} m={6} l={3} xl={3} className="p-10">
        <label>Cout Unitaire:</label>
        <Input
          type="number"
          step="0.1"
          value={state.unitCost}
          onChange={(v) => {
            setstate((prev) => {
              return { ...prev, unitCost: parseInt(v) };
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={2} xl={2} className="p-10">
        <label>Frais de service:</label>
        <Input
          type="number"
          step="0.1"
          value={state.unitFee}
          onChange={(v) => {
            setstate((prev) => {
              return { ...prev, unitFee: parseInt(v) };
            });
          }}
        />
      </Responsive>

      <Responsive s={6} m={6} l={4} xl={4} className="p-10">
        <label>Prix Total:</label>
        <div>
          {" "}
          <b style={{ color: "darkcyan", fontSize: "25px" }}>
            {(Number(state.unitFee) + Number(state.unitCost)).toFixed(3)}
          </b>
        </div>
      </Responsive>
      <hr></hr>
      <label>Désignation:</label>
      <Input
        as="textarea"
        rows={2}
        placeholder="Notes"
        value={state.note}
        onChange={(note) => {
          setstate((prev) => {
            return { ...prev, note };
          });
        }}
      />

      <Divider />
      <div style={{ textAlign: "right" }}>
        <IconButton onClick={save} color="violet" icon={<CreditCardPlusIcon />}>
          Ajouter
        </IconButton>
      </div>
      <Divider />
      <Grid
        editAction={setEditedElement}
        deleteAction={_delete}
        actionKey="index"
        noAdvancedActions // for custom advanced actions
        columns={columnsextrats(providers)}
        rows={model.extrats.map((el, index) => {
          return { ...el, index };
        })}
      />
    </>
  );
};

const columnsextrats = (providers) => {
  return [
    {
      value: "note",

      name: "Nom ",
      render: (s) => (
        <b>
          <i>{s}</i>
        </b>
      ),
    },
    // {
    //   value: "unitFee",
    //   value2: "unitCost",
    //   name: "Prix Total",
    //   render: (unitFee, unitCost) => (
    //     <b>{(parseFloat(unitFee) + parseFloat(unitCost)).toFixed(3)}</b>
    //   ),
    // },
  ];
};
