import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Button,
  ButtonToolbar,
  DatePicker,
  Divider,
  IconButton,
  Input,
  Message,
  SelectPicker,
  Steps,
  Tag,
  TagInput,
  TagPicker,
  Uploader,
} from "rsuite";
import { saleSate } from "../../Atoms/sale.atom";
import Responsive from "../../Components/Responsive";
import {
  AgeType,
  Gender,
  roomTypes,
  serviceTypes,
} from "../../Constants/types";

import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import Grid from "../../Components/Grid";
import boards from "../../Data/boards.json";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import TrashIcon from "@rsuite/icons/Trash";
import { APi } from "../../Api/";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { default as AddCheck } from "../Check/AddEdit.component";
import { CheckModel } from "../../Models/CheckModel";
import validate from "../../Helpers/validate";
import Swal from "sweetalert2";
import moment from "moment";
import { SaleVoState } from "../../Atoms/SaleVo.atom";
import { createAPIEndpoint } from "../../Api/authenticated.requests";
import { ENDPOINTS } from "../../Api/enpoints";
import { ExtratModel } from "../../Models/Package.model";
import { SaleModel } from "../../Models/SaleModels";
import SaleVoModel from "../../Models/SaleVoModel";
const styles = {
  // width: "200px",
  // display: "inline-table",
  verticalAlign: "top",
};
function AddEdit({
  error,
  clients,

  fetchClients,

  providers,
  fetchProviders,
}) {
  const [current, setcurrent] = useState(0);
  const [selectedPack, setselectedPack] = useState(null);
  const [model, setmodel] = useRecoilState(SaleVoState);
  useEffect(() => {
    console.log(model);
    setselectedPack(model.package);
  }, [model.id]);

  return (
    <>
      <Steps current={current} style={styles}>
        <Steps.Item title="Infos Génerales" onClick={() => setcurrent(0)} />
        <Steps.Item title="Voyageur" onClick={() => setcurrent(1)} />
        {/* <Steps.Item title="Additions + " onClick={() => setcurrent(2)} /> */}

        {/* <Steps.Item title="Attachments" onClick={() => setcurrent(3)} /> */}
      </Steps>

      <div style={{ padding: "40px 10px" }}>
        {!current ? (
          <General
            fetchClients={fetchClients}
            clients={clients}
            setPack={setselectedPack}
            pack={selectedPack}
          />
        ) : current == 1 ? (
          <>
            <Responsive m={6} l={5} xl={5} className="p-10">
              <label>Nom Complet:</label>
              <Input
                value={model.customer.name}
                onChange={(name) => {
                  let customer = { ...model.customer };

                  customer.name = name;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={6} l={5} xl={5} className="p-10">
              <label>Tél:</label>
              <Input
                type="phone"
                value={model.customer.phoneNumber}
                onChange={(phoneNumber) => {
                  let customer = { ...model.customer };

                  customer.phoneNumber = phoneNumber;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={6} l={5} xl={5} className="p-10">
              <label>N° Passport:</label>
              <Input
                value={model.customer.passportNumber}
                onChange={(passportNumber) => {
                  let customer = { ...model.customer };

                  customer.passportNumber = passportNumber;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={10} m={4.4} l={2} xl={2} className="p-10">
              <label>Date d'expiration :</label>
              <Input
                type="date"
                value={model.customer.expirationPassportDate}
                onChange={(expirationPassportDate) => {
                  let customer = { ...model.customer };

                  customer.expirationPassportDate = expirationPassportDate;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Type : </label>
              <SelectPicker
                data={AgeType}
                block
                noSearch
                value={model.customer.ageType}
                onChange={(ageType) => {
                  let customer = { ...model.customer };

                  customer.ageType = ageType;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Genre : </label>
              <SelectPicker
                data={Gender}
                block
                noSearch
                value={model.customer.gender}
                onChange={(gender) => {
                  let customer = { ...model.customer };

                  customer.gender = gender;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            {/* <Responsive s={10} m={4.4} l={2} xl={2} className="p-10">
              <label>Âge:</label>
              <Input
                type="number"
                value={model.customer.age}
                onChange={(age) => {
                  let customer = { ...model.customer };

                  customer.age = parseInt(age);
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive> */}
            <Responsive s={10} m={4.4} l={2} xl={2} className="p-10">
              <label>Date de naissance :</label>
              <Input
                type="date"
                value={model.customer.birthDate}
                onChange={(birthDate) => {
                  let customer = { ...model.customer };

                  customer.birthDate = birthDate;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
          </>
        ) : (
          <Extrats providers={providers} fetchProviders={fetchProviders} />
        )}
      </div>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
      <Divider />
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;

const General = ({ clients, fetchClients, setPack, pack }) => {
  const [model, _setmodel] = useRecoilState(SaleVoState);
  const [packs, setpacks] = useState([]);
  const fetchPacks = (q = "") => {
    createAPIEndpoint(ENDPOINTS.Package + "/GetForSale", {
      q,
      page: 1,
      take: 1000,
    })
      .fetchAll()
      .then((res) => setpacks(res.data.data));
  };
  useEffect(() => {
    fetchPacks();
  }, []);
  return (
    <>
      <label>Client: </label>
      <SelectPicker
        onSearch={(q) => fetchClients(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          clients.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.clientId}
        onSelect={(clientId) => {
          _setmodel((prev) => {
            return { ...prev, clientId };
          });
        }}
      />
      {model.clientId && (
        <div
          style={{
            background: "rgb(107,121,196)",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            margin: "10px",
          }}
        >
          <b>
            Commission:{" "}
            {clients.find((c) => c.id == model.clientId) &&
              clients.find((c) => c.id == model.clientId).voCommission}
          </b>
          <br></br>
          <label>Remise/addition</label>
          <Input
            type="number"
            value={model.regulated_value}
            onChange={(regulated_value) => {
              console.log(regulated_value);
              _setmodel((prev) => {
                return {
                  ...prev,
                  regulated_value: parseFloat(regulated_value),
                };
              });
            }}
            block
          />
        </div>
      )}
      <label>Voyage Organisé: </label>
      <SelectPicker
        onSearch={(q) => fetchPacks(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          packs.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.packageId}
        onSelect={(packageId) => {
          setPack(packs.find((el) => el.id == packageId));
          console.log(packs.find((el) => el.id == packageId));
          _setmodel((prev) => {
            return { ...prev, packageId };
          });
        }}
      />{" "}
      {pack && (
        <div
          style={{
            background: "rgb(107,121,196)",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            margin: "10px",
          }}
        >
          <label>Choir parmi les vols: </label>
          <SelectPicker
            data={pack.disponibilities.map((c) => {
              return {
                label: moment(c.date).format("DD MMM YYYY"),
                value: c.id,
              };
            })}
            block
            noSearch
            value={model.disponibilityId}
            onSelect={(disponibilityId) => {
              _setmodel((prev) => {
                return { ...prev, disponibilityId };
              });
            }}
          />
          {pack.packageProviders &&
          pack.packageProviders.find((el) => el.hotels) ? (
            pack.packageProviders
              .filter((pp) => pp.hotels)
              .map((pp, i) => (
                <>
                  <label>Choir Hotel N° {i + 1}: </label>
                  <SelectPicker
                    data={pp.hotels.split(",").map((c) => {
                      return { label: c, value: c };
                    })}
                    block
                    noSearch
                    value={model["hotelName" + (i + 1)]}
                    onSelect={(v) => {
                      _setmodel((prev) => {
                        return { ...prev, ["hotelName" + (i + 1)]: v };
                      });
                    }}
                  />
                </>
              ))
          ) : (
            <>
              <label>Hotel: </label>
              <Input
                value={model.hotelName1}
                onChange={(hotelName1) => {
                  console.log(hotelName1);
                  _setmodel((prev) => {
                    return { ...prev, hotelName1 };
                  });
                }}
                block
              />
            </>
          )}
          <label>Choir parmi les arrangements: </label>
          <SelectPicker
            data={pack.prices.map((c) => {
              return {
                label: c.name + " --- " + c.price + "TND",
                value: c.name,
              };
            })}
            block
            noSearch
            value={model.arrangement}
            onSelect={(arrangement) => {
              _setmodel((prev) => {
                return {
                  ...prev,
                  arrangement,
                  totalPrice: pack.prices.find((el) => el.name == arrangement)
                    .price,
                };
              });
            }}
          />{" "}
        </div>
      )}
      {/* <Responsive s={6} m={3} l={2} xl={2} className="p-10">
        <label>N° Ticket: </label>
        <Input
          value={model.ticketNumber}
          onChange={(ticketNumber) => {
            console.log(ticketNumber);
            _setmodel((prev) => {
              return { ...prev, ticketNumber };
            });
          }}
          block
        />
      </Responsive>
      <Responsive s={6} m={3} l={2} xl={2} className="p-10">
        <label>Tarif De Base: </label>
        <Input
          type="number"
          value={model.tickeBaseFare}
          onChange={(tickeBaseFare) => {
            console.log(tickeBaseFare);
            _setmodel((prev) => {
              return { ...prev, tickeBaseFare: Number(tickeBaseFare) };
            });
          }}
          block
        />
      </Responsive>
      <Responsive s={6} m={3} l={2} xl={2} className="p-10">
        <label>Commission: </label>
        <Input
          type="number"
          value={model.tickeCommission}
          onChange={(tickeCommission) => {
            console.log(tickeCommission);
            _setmodel((prev) => {
              return { ...prev, tickeCommission: Number(tickeCommission) };
            });
          }}
          block
        />
      </Responsive>
      <Responsive s={6} m={3} l={2} xl={2} className="p-10">
        <label>Taxe: </label>
        <Input
          type="number"
          value={model.tickeTax}
          onChange={(tickeTax) => {
            console.log(tickeTax);
            _setmodel((prev) => {
              return { ...prev, tickeTax: Number(tickeTax) };
            });
          }}
          block
        />
      </Responsive> */}
      <br></br>
      <label>Date De vente: </label>
      <Input
        type="date"
        value={model.date}
        onChange={(date) => {
          console.log(date);
          _setmodel((prev) => {
            return { ...prev, date };
          });
        }}
        // onSelect={(date) =>
        //   _setmodel((prev) => {
        //     return { ...prev, date };
        //   })
        // }
        // block
      />
      <label>Note: </label>
      <Input
        as="textarea"
        value={model.note}
        onChange={(note) => {
          console.log(note);
          _setmodel((prev) => {
            return { ...prev, note };
          });
        }}
        // onSelect={(date) =>
        //   _setmodel((prev) => {
        //     return { ...prev, date };
        //   })
        // }
        // block
      />
      <br></br>
      <label>
        N'est pas confirmé:{" "}
        <input
          type="checkbox"
          // onClick={(e) => {
          //   _setmodel((prev) => ({
          //     ...prev,
          //     confirmationStatus: prev.confirmationStatus == 2 ? 1 : 2,
          //   }));
          // }}
          onChange={(e) => {
            _setmodel((prev) => ({
              ...prev,
              confirmationStatus: prev.confirmationStatus == 2 ? 1 : 2,
            }));
          }}
          checked={model.confirmationStatus == 2}
        ></input>
      </label>
    </>
  );
};
const Extrats = ({ providers, fetchProviders }) => {
  const [services, setservices] = useState(serviceTypes);
  const [model, _setmodel] = useRecoilState(SaleVoState);
  const [state, setstate] = useState(new SaleVoModel());
  const save = () => {
    _setmodel((prev) => {
      console.log(prev);
      let m = { ...prev };
      if (typeof m.extrats != "object") m.extrats = [];
      if (typeof state.index == "number") {
        let extrats = [...m.extrats];
        extrats[state.index] = state;

        m = { ...m, extrats };
      } else {
        let extrats = [...m.extrats];
        extrats.push(state);
        m = { ...m, extrats };
      }
      return m;
    });
    setstate(new ExtratModel());
  };
  const _delete = (index) => {
    _setmodel((prev) => {
      let m = { ...prev };
      let extrats = [...m.extrats];
      extrats.splice(index, 1);
      m = { ...m, extrats };
      return m;
    });
  };
  const setEditedElement = (index) => {
    setstate({ ...model.extrats[index], index });
  };
  return (
    <>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Fournisseur : </label>
        <SelectPicker
          onSearch={(q) => fetchProviders(q)}
          data={providers.map((c) => {
            return { label: c.name, value: c.id };
          })}
          block
          noSearch
          value={state.providerId}
          onSelect={(providerId) => {
            let p = providers.find((_) => _.id == providerId);
            console.log(p);

            // if (p && p.serviceTypes) {
            //   console.log("test");
            //   setservices((prev) => {
            //     return [
            //       ...serviceTypes.filter((ss) =>
            //         p.serviceTypes.includes(ss.value)
            //       ),
            //     ];
            //   });
            // }

            setstate((prev) => {
              return { ...prev, providerId };
            });
          }}
        />
      </Responsive>

      <Responsive s={6} m={6} l={3} xl={3} className="p-10">
        <label>Cout Unitaire:</label>
        <Input
          type="number"
          step="0.1"
          value={state.unitCost}
          onChange={(v) => {
            setstate((prev) => {
              return { ...prev, unitCost: parseInt(v) };
            });
          }}
        />
      </Responsive>
      <Responsive s={6} m={6} l={2} xl={2} className="p-10">
        <label>Frais de service:</label>
        <Input
          type="number"
          step="0.1"
          value={state.unitFee}
          onChange={(v) => {
            setstate((prev) => {
              return { ...prev, unitFee: parseInt(v) };
            });
          }}
        />
      </Responsive>

      <Responsive s={6} m={6} l={4} xl={4} className="p-10">
        <label>Prix Total:</label>
        <div>
          {" "}
          <b style={{ color: "darkcyan", fontSize: "25px" }}>
            {(Number(state.unitFee) + Number(state.unitCost)).toFixed(3)}
          </b>
        </div>
      </Responsive>
      <hr></hr>
      <label>Désignation:</label>
      <Input
        as="textarea"
        rows={2}
        placeholder="Notes"
        value={state.note}
        onChange={(note) => {
          setstate((prev) => {
            return { ...prev, note };
          });
        }}
      />

      <Divider />
      <div style={{ textAlign: "right" }}>
        <IconButton onClick={save} color="violet" icon={<CreditCardPlusIcon />}>
          Ajouter
        </IconButton>
      </div>
      <Divider />
      <Grid
        editAction={setEditedElement}
        deleteAction={_delete}
        actionKey="index"
        noAdvancedActions // for custom advanced actions
        columns={columnsextrats(providers)}
        rows={model.extrats.map((el, index) => {
          return { ...el, index };
        })}
      />
    </>
  );
};

const columnsextrats = (providers) => {
  return [
    {
      value: "note",

      name: "Nom ",
      render: (s) => (
        <b>
          <i>{s}</i>
        </b>
      ),
    },
    // {
    //   value: "unitFee",
    //   value2: "unitCost",
    //   name: "Prix Total",
    //   render: (unitFee, unitCost) => (
    //     <b>{(parseFloat(unitFee) + parseFloat(unitCost)).toFixed(3)}</b>
    //   ),
    // },
  ];
};
