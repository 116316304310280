import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Button,
  ButtonToolbar,
  DatePicker,
  Divider,
  IconButton,
  Input,
  Message,
  SelectPicker,
  Steps,
  Tag,
  TagInput,
  TagPicker,
  Uploader,
} from "rsuite";
import { saleSate } from "../../Atoms/sale.atom";
import Responsive from "../../Components/Responsive";
import {
  AgeType,
  checkPurpose,
  checkStatus,
  Gender,
  paymentType,
  roomTypes,
  serviceTypes,
} from "../../Constants/types";

import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import Grid from "../../Components/Grid";
import boards from "../../Data/boards.json";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import TrashIcon from "@rsuite/icons/Trash";
import { APi } from "../../Api/";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { default as AddCheck } from "../Check/AddEdit.component";
import { CheckModel } from "../../Models/CheckModel";
import validate from "../../Helpers/validate";
import Swal from "sweetalert2";
import moment from "moment";
import { CurrencyAtom } from "../../Atoms/currency.atom";
import { SaleHostingState } from "../../Atoms/hosting.atom";
import { CustomerVoModel } from "../../Models/SaleVoModel";
import { HotelReservationModel } from "../../Models/HostingModel";
import { SaleBusState } from "../../Atoms/bus.atom";
import { BusItemModel, BusModel } from "../../Models/SaleBusModel";
const styles = {
  // width: "200px",
  // display: "inline-table",
  verticalAlign: "top",
};
function AddEdit({
  error,
  clients,

  fetchClients,

  providers,
  fetchProviders,

  fetchTags,
  tags,
}) {
  const [current, setcurrent] = useState(0);
  const [state, setstate] = useRecoilState(SaleBusState);
  return (
    <>
      <Steps current={current} style={styles}>
        <Steps.Item title="Infos Génerales" onClick={() => setcurrent(0)} />
        <Steps.Item title="Détails" onClick={() => setcurrent(1)} />
        {/* {noCCA && (
          <Steps.Item onClick={() => setcurrent(2)} title="Encaissement" />
        )} */}
        <Steps.Item title="Attachments" onClick={() => setcurrent(3)} />
      </Steps>

      <div style={{ padding: "40px 10px" }}>
        {!current ? (
          <General
            fetchClients={fetchClients}
            clients={clients}
            tags={tags}
            fetchTags={fetchTags}
          />
        ) : current == 1 ? (
          <>
            {" "}
            <Responsive m={6} l={6} xl={6} className="p-10">
              <label>Fournisseur : </label>
              <SelectPicker
                onSearch={(q) => fetchProviders(q)}
                data={providers.map((c) => {
                  return { label: c.name, value: c.id };
                })}
                block
                value={state.providerId}
                noSearch
                onSelect={(providerId) => {
                  let p = providers.find((_) => _.id == providerId);
                  setstate((prev) => ({ ...prev, providerId }));
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={3} xl={3} className="p-10">
              <label>Prix :</label>
              <Input
                type="number"
                step="0.1"
                value={state.fare}
                onChange={(fare) => {
                  setstate((prev) => {
                    return { ...prev, fare: parseFloat(fare) };
                  });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Commission:</label>
              <Input
                type="number"
                step="0.1"
                value={state.commission}
                onChange={(commission) => {
                  setstate((prev) => {
                    return { ...prev, commission: parseFloat(commission) };
                  });
                }}
              />
            </Responsive>
            {/* <Responsive s={6} m={6} l={2} xl={2} className="p-10">
        <label>Quantité:</label>
        <Input
          disabled={state.service == 1}
          type="number"
          step="1"
          value={state.qty}
          onChange={(qty) => {
            setstate((prev) => {
              return { ...prev, qty };
            });
          }}
        />
      </Responsive> */}
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Commission ( % ):</label>
              <Input
                type="number"
                step="0.1"
                value={state.commissionPercent}
                onChange={(commissionPercent) => {
                  setstate((prev) => {
                    return {
                      ...prev,
                      commissionPercent: parseFloat(commissionPercent),
                    };
                  });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={4} xl={4} className="p-10">
              <label>Prix De Vente:</label>
              <div>
                <b style={{ color: "darkcyan", fontSize: "25px" }}>
                  {(
                    (1 + parseFloat(state.commissionPercent) / 100) *
                    (parseFloat(state.commission) + parseFloat(state.fare))
                  ).toFixed(3)}
                </b>
              </div>
            </Responsive>
            <hr></hr>
            <Bus
              model={state.bus}
              setModel={(bus) =>
                setstate((prev) => {
                  return { ...prev, bus };
                })
              }
            />
          </>
        ) : (
          <Attachments />
        )}
      </div>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
      <Divider />
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;

const General = ({ clients, fetchClients, tags, fetchTags }) => {
  const [selectedTags, setselectedTags] = useState([]);
  const [model, _setmodel] = useRecoilState(SaleBusState);
  const currencies = useRecoilValue(CurrencyAtom);
  useEffect(() => {
    if (currencies && currencies.length && !model.currencyId) {
      _setmodel((prev) => {
        return { ...model, currencyId: currencies[0].id };
      });
    }
  }, [model.clientId]);
  return (
    <>
      <label>Client: </label>
      <SelectPicker
        onSearch={(q) => fetchClients(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          clients.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.clientId}
        onSelect={(clientId) => {
          _setmodel((prev) => {
            return { ...prev, clientId };
          });
        }}
      />
      <label>Date De vente: </label>
      <Input
        type="date"
        value={model.date}
        defaultValue={model.date}
        onChange={(date) => {
          console.log(date);
          _setmodel((prev) => {
            return { ...prev, date };
          });
        }}
        // onSelect={(date) =>
        //   _setmodel((prev) => {
        //     return { ...prev, date };
        //   })
        // }
        // block
      />

      {/* <label>Mots Clés: </label>
      <TagPicker
        data={
          tags
            ? tags.map((c) => {
                return { label: c.name, value: c.id };
              })
            : []
        }
        block
        onSearch={fetchTags}
        onSelect={(s) => {
          let existed = selectedTags.filter((el) =>
            s.find((_id) => _id == el.id)
          );
          let newTags = tags.filter(
            (item) =>
              s.find((_id) => _id == item.id) &&
              !selectedTags.find((el) => el.id == item.id)
          );
          setselectedTags((prev) => {
            return [...existed, ...newTags];
          });

          _setmodel((prev) => {
            return { ...prev, tags: [...existed, ...newTags] };
          });
        }}
      /> */}
      <label>Devise: </label>
      <SelectPicker
        // onSearch={(q) => fetchClients(q)}
        data={currencies.map((c) => {
          return { label: c.name, value: c.id };
        })}
        block
        noSearch
        value={model.currencyId}
        onSelect={(currencyId) => {
          _setmodel((prev) => {
            return { ...prev, currencyId };
          });
        }}
      />
    </>
  );
};

// -----------------------------------

const Bus = ({ model = new BusModel(), setModel }) => {
  return (
    <>
      {/* 
      <Responsive s={6} m={6} l={6} xl={6} className="p-10">
        <label>Date De Depart:</label>
        <Input
          type="date"
          value={model.}
          onChange={(checkIn) => setModel({ ...model, checkIn })}
        />
      </Responsive>
      <Responsive s={6} m={6} l={6} xl={6} className="p-10">
        <label>Date De Retour:</label>
        <Input
          type="date"
          value={model.checkOut}
          onChange={(checkOut) => setModel({ ...model, checkOut })}
        />
      </Responsive> */}
      <h5>Liste Des Voyages</h5>
      {model.items.map((item, i) => (
        <>
          <Responsive m={6} l={3.8} xl={3.8} className="p-5">
            <label>Nom Du chauffeur:</label>
            <Input
              value={model.items[i].driverName}
              onChange={(driverName) => {
                let items = [...model.items];
                let g = { ...items[i] };
                g.driverName = driverName;
                items[i] = g;
                setModel({ ...model, items });
              }}
            />
          </Responsive>
          <Responsive s={6} m={6} l={1.8} xl={1.8} className="p-5">
            <label>Date De Depart:</label>
            <Input
              type="datetime-local"
              value={model.items[i].departDate}
              onChange={(departDate) => {
                let items = [...model.items];
                let g = { ...items[i] };
                g.departDate = departDate;
                items[i] = g;
                setModel({ ...model, items });
              }}
            />
          </Responsive>
          <Responsive s={6} m={6} l={1.8} xl={1.8} className="p-5">
            <label>Date De Retour:</label>
            <Input
              type="datetime-local"
              value={model.items[i].arrivalDate}
              onChange={(arrivalDate) => {
                let items = [...model.items];
                let g = { ...items[i] };
                g.arrivalDate = arrivalDate;
                items[i] = g;
                setModel({ ...model, items });
              }}
            />
          </Responsive>
          <Responsive s={6} m={6} l={1.8} xl={1.8} className="p-5">
            <label>Ville De Depart:</label>
            <Input
              value={model.items[i].departCity}
              onChange={(departCity) => {
                let items = [...model.items];
                let g = { ...items[i] };
                g.departCity = departCity;
                items[i] = g;
                setModel({ ...model, items });
              }}
            />
          </Responsive>
          <Responsive s={6} m={6} l={1.8} xl={1.8} className="p-5">
            <label>Ville D'Arrivé:</label>
            <Input
              value={model.items[i].arrivalCity}
              onChange={(arrivalCity) => {
                let items = [...model.items];
                let g = { ...items[i] };
                g.arrivalCity = arrivalCity;
                items[i] = g;
                setModel({ ...model, items });
              }}
            />
          </Responsive>

          <Responsive s={2} m={1.6} l={1} xl={1}>
            <div style={{ height: "42px" }}> </div>
            {i ? (
              <IconButton
                onClick={() => {
                  let items = [...model.items];
                  items.splice(i, 1);
                  setModel({ ...model, items });
                }}
                color="violet"
                icon={<TrashIcon />}
              ></IconButton>
            ) : (
              ""
            )}
            {i + 1 == model.items.length && (
              <IconButton
                onClick={() => {
                  let items = [...model.items];
                  items.push(new BusItemModel());
                  setModel({ ...model, items });
                }}
                color="violet"
                icon={<PlusRoundIcon />}
              ></IconButton>
            )}
          </Responsive>
        </>
      ))}
    </>
  );
};
//---------------------------------

const Attachments = () => {
  const [model, _setmodel] = useRecoilState(saleSate);
  const [state, setstate] = useState([]);
  const [loading, setloading] = useState(false);
  const upload = (list, file, fileKey) => {
    setloading(true);
    APi.createAPIEndpoint(APi.ENDPOINTS.File)
      .upload(file)
      .then((res) => {
        setloading(false);

        let i = list.findIndex((el) => el.fileKey == fileKey);
        setstate((prev) => {
          let items = [...list];
          items[i].status = "finished";
          return items;
        });
        let attachments = [...model.attachments];
        attachments.push({ name: res.data[0].fileName });
        _setmodel((prev) => {
          return { ...model, attachments };
        });
      })
      .catch((error) => {
        setloading(false);

        let i = list.findIndex((el) => el.fileKey == fileKey);
        setstate((prev) => {
          let items = [...list];
          items[i].status = "error";
          return items;
        });
      });
  };
  const _delete = (fileKey) => {
    let i = state.findIndex((el) => el.fileKey == fileKey);
    APi.createAPIEndpoint(APi.ENDPOINTS.File)
      .delete(model.attachments[i].name)
      .then((res) => {
        setstate((prev) => {
          let items = [...prev];
          items.splice(i);
          return items;
        });
        let attachments = [...model.attachments];
        attachments.splice(i);
        _setmodel((prev) => {
          return { ...model, attachments };
        });
      })
      .catch((error) => {});
  };
  return (
    <>
      {" "}
      <Uploader
        loading={loading}
        autoUpload={false}
        fileList={state}
        action="#"
        draggable
        onRemove={(f) => _delete(f.fileKey)}
        onChange={(list) => {
          // setstate((prev) => list);
          console.log(list);
          let file = list.filter((el) => el.status != "finished").reverse()[0];
          if (file) upload(list, file.blobFile, file.fileKey);
        }}
      >
        <div style={{ background: "rgba(200,200,200,0.1)" }}>
          <div style={{ fontSize: "50px" }}>
            {" "}
            <FileUploadIcon color="#3598ff"></FileUploadIcon>
          </div>
          <h4 style={{ color: "#bbb", fontWeight: "400" }}>
            Clicker ou Faites glisser les fichiers vers cette zone
            {/* pour les télécharger */}
          </h4>
        </div>
      </Uploader>
      {loading && <></>}
    </>
  );
};
