import moment from "moment";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import Responsive from "../../Components/Responsive";
import styles from "./flights.module.scss";
import airlines from "../../Data/airlines.json";
import { IoIosAirplane } from "react-icons/io";
import { Tag } from "rsuite";

export const Fly = ({
  airlineCode = "TU",
  AgentName,
  isRoundTrip,
  segments = [
    {
      //   departCity: "TUNIS",
      //   departCountry: "TUNISIA", //
      departAirport: "TUN",
      departTime: "11:00", // date + time
      //   arrivalCity: "ISTANBUL", //
      //   arrivalCountry: "TURKIE", //
      departDate: "2023-01-19", // date + time
      arrivalAirport: "IST",
      arrivalDate: "2023-01-19",
      arrivalTime: "13:00",
      flightNumber: "125",

      class: "ECO",
      bagages: "2PCS",

      duration: "2H00",
    },
    {
      //   departCity: "TUNIS",
      //   departCountry: "TUNISIA", //
      departAirport: "IST",
      departTime: "11:00", // date + time
      //   arrivalCity: "ISTANBUL", //
      //   arrivalCountry: "TURKIE", //
      departDate: "2023-01-21", // date + time
      arrivalAirport: "IST",
      arrivalDate: "2023-01-21",
      arrivalTime: "13:30",
      flightNumber: "125",

      class: "ECO",
      bagages: "2PCS",

      duration: "2H30",
    },
  ],
  ...props
}) => {
  const [data, setdata] = useState([]);

  function getTimePercent(segs, d) {
    let percent = 0;
    let duration = parseInt(d.split("H")[0]) * 60 + parseInt(d.split("H")[1]);
    let total_stops_duration = moment(segs[segs.length - 1].departure.at).diff(
      moment(segs[0].arrival.at),
      "minutes"
    );

    percent = (total_stops_duration / duration) * 100;
    console.log(Math.round(percent));
    return Math.round(percent);
  }
  function formatDuration(d) {
    return d.substring(2, d.length - 1);
  }
  function left(depart, d, d1, dr) {
    console.log("arrival segment at", d);
    console.log("depart next segment at", d1);

    let duration = parseInt(dr.split("H")[0]) * 60 + parseInt(dr.split("H")[1]);
    console.log("duration", duration);

    let l = moment(d1).diff(moment(d), "minutes");
    console.log("diff", l);
    const left = (moment(d).diff(moment(depart), "minutes") / duration) * 100;
    return { width: Math.round((l / duration) * 100), left };
  }
  const findRoundTrip = () => {
    let _i = segments.findLastIndex((el, i) => {
      return i > 0 && segments[i - 1].departAirport == el.arrivalAirport;
    });
    if (_i > -1) {
      setdata([segments.slice(0, _i + 1), segments.slice(_i)]);
    } else {
      setdata([segments]);
    }
  };
  //   useEffect(() => {
  //     findRoundTrip();
  //   }, [segments]);

  return (
    <div className={styles.ticket}>
      <div className={styles.itineraries}>
        <div
        // style={{borderBottom:"1px dashed rgb(70,103,209,1)"}}
        >
          {segments[0] && (
            <img
              alt={segments[0].airCode}
              src={
                "https://daisycon.io/images/airline/?width=300&height=150&color=ffffff&iata=" +
                segments[0].airCode
                // airlines.find((el) => el.id == airlineCode).logo
              }
            ></img>
          )}
          <span>Agent : {AgentName}</span>
        </div>
        {segments.map((item, key) => (
          <div className={styles.itinerary}>
            <div>
              <b>
                {item.departAirport}
                <br></br>{" "}
                <i style={{ color: "#888", fontSize: "12px" }}>
                  {item.departCity}
                </i>
              </b>
              <Tag color="green">{item.class}</Tag>
              <span className={styles.duration}>{item.duration}</span>
              <Tag color="orange">{"Bagages :" + item.bagages}</Tag>

              <b>
                {item.arrivalAirport} <br></br>{" "}
                <i style={{ color: "#888", fontSize: "12px" }}>
                  {item.arrivalCity}
                </i>
              </b>
            </div>
            <div className={styles.duration}>
              {" "}
              <div
                style={{
                  textAlign: "center",
                  flexGrow: 2,
                  color: "rgb(70,103,209,1)",
                  position: "relative",
                  top: "-15px",
                }}
              >
                <IoIosAirplane size={"30px"} />
              </div>
            </div>
            {/* <div className={styles.duration}>
                {item.map(
                  (s, k) =>
                    // k > 0 &&
                    k < item.length - 1 && (
                      //  slice(1, item.segments.length).
                      <>
                        <div className="time_percent">
                          <span>{item[k + 1].departAirport}</span>
                        </div>
                        <style jsx>{`
                          .time_percent {
                            width: ${left(
                              item[0].departTime,
                              s.arrivalTime,
                              item[k + 1].departTime,
                              item[0].duration
                            ).width}% !important;
                            display: inline-block;
                            height: 6px;
                            top: -3px;
                            left: ${left(
                              item[0].departTime,
                              s.arrivalTime,
                              item[k + 1].departTime,
                              item[0].duration
                            ).left}%;
                            position: absolute;

                            border: 1px solid #ccc;
                            background-color: #eee;
                            content: "";
                            border-radius: 5px;
                            // transform: translate(-50%);
                          }
                          .time_percent span {
                            display: inline-block;
                            margin-top: 5px;
                            font-size: 14px;
                            font-weight: 300;
                            color: #999;
                          }
                        `}</style>
                      </>
                    )
                )}
              </div>{" "} */}
            <div style={{ padding: "0 10px" }}>
              <b>
                {item.departDate ? item.departDate.substring(0, 10) : ""}
                <br></br> {item.departTime}
              </b>

              <b style={{ textAlign: "right" }}>
                {item.arrivalDate ? item.arrivalDate.substring(0, 10) : ""}
                <br></br> {item.arrivalTime}
              </b>
            </div>
            {/*              
              {show_details == key + 1 && (
                <div className={styles.segments}>
                  {item.segments.map((seg) => (
                    <div className={styles.segment}>
                      <div>
                        <b>
                          {moment(seg.departure.at).format("hh:mm")}
                          <span>
                            {" "}
                            {moment(seg.departure.at).format("DD/MM")}
                          </span>
                        </b>
                        <span>{formatDuration(seg.duration)}</span>
                        <b>
                          {moment(seg.arrival.at).format("hh:mm")}
                          <span> {moment(seg.arrival.at).format("DD/MM")}</span>
                        </b>
                      </div>
                      <div className={styles.dots}></div>
                      <div className={styles.details}>
                        <h4>test airport</h4>

                        <div></div>
                        <h4>test airport</h4>
                      </div>
                    </div>
                  ))}
                </div>
              )} */}
          </div>
        ))}
      </div>
    </div>
  );
};
