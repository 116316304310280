import { CustomerVoModel } from "./SaleVoModel";

export class SaleModel {
  date = new Date();
  clientId;
  providerId;
  customer = new CustomerVoModel();
  totalPrice = 0;
  totalRefund = 0;
  commission = 0;
  fare = 0;
  commissionPercent = 0;
  note = "";
  service = 8;
}
export class PaymentModel {
  amount = 0;
  type = 1; // enums 	PaymentType
  date = null;
  // "paymentNo": "",  auto generated
  notes = "";
  refReglement = "";
  isPaid = false;
  checkId = null;
  bankId = null;
  checkoutId = null;
  EmissionBank = "";
  paymentEventType = 1;
  recoveryPointId;
  constructor(type = 1, paymentEventType = 1) {
    this.paymentEventType = paymentEventType;
    this.type = type;
  }
}