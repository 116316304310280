import { Input, Message, SelectPicker, TagInput } from "rsuite";

function AddEdit({ _setmodel, error, model = {}, Positions, fetchPositions }) {
  return (
    <>
      <label>Nom:</label>
      <Input
        onChange={(name) => {
          _setmodel((prev) => {
            return { ...prev, name };
          });
        }}
        value={model.name}
      />

      <label>Adresse</label>
      <Input
        value={model.address}
        onChange={(address) => {
          _setmodel((prev) => {
            return { ...prev, address };
          });
        }}
      />
      <label>Email</label>
      <Input
        block
        placeholder="example@cc.ccc "
        value={model.email}
        onChange={(email) => {
          let m = { ...model, email };

          _setmodel(m);
        }}
      />
      <label>Télephones</label>
      <TagInput
        block
        size="md"
        // placeholder="numéros des télephones"
        value={model.phones ? model.phones.split(",") : []}
        onChange={(phones) => {
          model.phones = phones.join(",");
          let m = { ...model };
          _setmodel(m);
        }}
      />

      <label>position</label>
      <SelectPicker
        onSearch={(q) => fetchPositions(q)}
        data={[{ label: "Tout", value: null }].concat(
          Positions.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.positionId}
        onSelect={(positionId) => {
          _setmodel((prev) => {
            return { ...prev, positionId };
          });
        }}
      />

      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;
