import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Button,
  ButtonToolbar,
  Divider,
  IconButton,
  Input,
  Message,
  SelectPicker,
  Steps,
  Tag,
  TagInput,
  TagPicker,
  Uploader,
} from "rsuite";
import { ticketSate } from "../../Atoms/ticket.atom";
import Responsive from "../../Components/Responsive";
import { AgeType, Gender, ticketType } from "../../Constants/types";

import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import Grid from "../../Components/Grid";
import boards from "../../Data/boards.json";
import PlusRoundIcon from "@rsuite/icons/PlusRound";
import TrashIcon from "@rsuite/icons/Trash";
import { APi } from "../../Api";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { default as AddCheck } from "../Check/AddEdit.component";
import { CheckModel } from "../../Models/CheckModel";
import validate from "../../Helpers/validate";
import Swal from "sweetalert2";
import moment from "moment";
import { CurrencyAtom } from "../../Atoms/currency.atom";
import TicketModel from "../../Models/Ticket.model";
import countries from "../../Data/countries";
import AddTicketModel from "../../Models/AddTikcketModel";
const styles = {
  // width: "200px",
  // display: "inline-table",
  verticalAlign: "top",
};
function AddEdit({
  error,
  clients,

  fetchClients,
  airlines,
  fetchAirlines,
  fetchAgentBookingFlights,
  agentBookingFlights,
  fetchTags,
  tags,
}) {
  const [current, setcurrent] = useState(0);
  const [model, _setmodel] = useRecoilState(ticketSate);

  return (
    <>
      <Steps current={current} style={styles}>
        <Steps.Item title="Infos Génerales" onClick={() => setcurrent(0)} />
        <Steps.Item title="Voyageur" onClick={() => setcurrent(1)} />

        <Steps.Item title="Infos Ticket" onClick={() => setcurrent(2)} />
      </Steps>

      <div style={{ padding: "40px 10px" }}>
        {!current ? (
          <General
            fetchClients={fetchClients}
            clients={clients}
            tags={tags}
            fetchTags={fetchTags}
            airlines={airlines}
            fetchAirlines={fetchAirlines}
            fetchAgentBookingFlights={fetchAgentBookingFlights}
            agentBookingFlights={agentBookingFlights}
          />
        ) : current == 1 ? (
          <>
            {" "}
            <Responsive m={6} l={5} xl={5} className="p-10">
              <label>Nom Complet:</label>
              <Input
                value={model.paxName}
                onChange={(paxName) => {
                  _setmodel({ ...model, paxName });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Type : </label>
              <SelectPicker
                data={AgeType}
                block
                noSearch
                value={model.paxType}
                onChange={(paxType) => {
                  _setmodel({ ...model, paxType });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>N° Passport : </label>
              <Input
                value={model.passportNbr}
                onChange={(passportNbr) => {
                  _setmodel({ ...model, passportNbr });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Date D'expiration:</label>
              <Input
                type="date"
                value={model.passportExpiration}
                onChange={(passportExpiration) =>
                  _setmodel({ ...model, passportExpiration })
                }
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Genre : </label>
              <SelectPicker
                data={Gender.map((el) => ({ ...el, value: el.label }))}
                block
                noSearch
                value={model.gender}
                onChange={(gender) => {
                  _setmodel({ ...model, gender });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Date de naissance:</label>
              <Input
                type="date"
                value={model.dateOfBirth}
                onChange={(dateOfBirth) => _setmodel({ ...model, dateOfBirth })}
              />
            </Responsive>
            <Responsive s={6} m={6} l={6} xl={6} className="p-10">
              <label>Nationalité:</label>
              <SelectPicker
                data={countries.map((c) => ({ label: c.name, value: c.name }))}
                block
                noSearch
                value={model.nationality}
                onChange={(nationality) => {
                  _setmodel({ ...model, nationality });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Address : </label>
              <Input
                type="address"
                value={model.adress}
                onChange={(adress) => {
                  _setmodel({ ...model, adress });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Email : </label>
              <Input
                type="email"
                value={model.email}
                onChange={(email) => {
                  _setmodel({ ...model, email });
                }}
              />
            </Responsive>
            <Responsive s={6} m={6} l={2} xl={2} className="p-10">
              <label>Tél : </label>
              <Input
                type="phone"
                value={model.phone}
                onChange={(phone) => {
                  _setmodel({ ...model, phone });
                }}
              />
            </Responsive>
          </>
        ) : (
          <div>
            <TicketAddEdit setmodel={_setmodel} model={{ ...model }} />
          </div>
        )}
      </div>
      <br></br>
      {error && (
        <Message showIcon type="error">
          {error}
        </Message>
      )}
      <Divider />
    </>
  );
}
// AddEdit.defaultProps = {
//   model: new ClientModel(),
// };
export default AddEdit;

const General = ({
  clients,
  fetchClients,
  tags,
  fetchTags,
  airlines,
  fetchAirlines,
  fetchAgentBookingFlights,
  agentBookingFlights,
}) => {
  const [selectedTags, setselectedTags] = useState([]);
  const [model, _setmodel] = useRecoilState(ticketSate);
  useEffect(() => {}, []);
  return (
    <>
      <label>Client: </label>
      <SelectPicker
        onSearch={(q) => fetchClients(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          clients.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.clientId}
        onSelect={(clientId) => {
          _setmodel((prev) => {
            return { ...prev, clientId };
          });
        }}
      />
      {/* <label>Date De vente: </label>
      <Input
        type="date"
        value={model.date}
        onChange={(date) => {
          console.log(date);
          _setmodel((prev) => {
            return { ...prev, date };
          });
        }}
        // onSelect={(date) =>
        //   _setmodel((prev) => {
        //     return { ...prev, date };
        //   })
        // }
        // block
      /> */}
      <label>Airline : </label>
      <SelectPicker
        onSearch={(q) => fetchAirlines(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          airlines.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.airlineId}
        onSelect={(airlineId) => {
          _setmodel((prev) => {
            return { ...prev, airlineId };
          });
        }}
      />
      {/* <label>Mots Clés: </label>
      <TagPicker
        data={
          tags
            ? tags.map((c) => {
                return { label: c.name, value: c.id };
              })
            : []
        }
        block
        onSearch={fetchTags}
        onSelect={(s) => {
          let existed = selectedTags.filter((el) =>
            s.find((_id) => _id == el.id)
          );
          let newTags = tags.filter(
            (item) =>
              s.find((_id) => _id == item.id) &&
              !selectedTags.find((el) => el.id == item.id)
          );
          setselectedTags((prev) => {
            return [...existed, ...newTags];
          });

          _setmodel((prev) => {
            return { ...prev, tags: [...existed, ...newTags] };
          });
        }}
      /> */}
      <label>Note</label>
      <Input
        value={model.note}
        onChange={(note) => {
          _setmodel((prev) => {
            return { ...prev, note };
          });
        }}
      />
      {/* <label>Agent Amadeus : </label>
      <SelectPicker
        onSearch={(q) => fetchAgentBookingFlights(q)}
        data={[{ label: "Tout", value: 0 }].concat(
          agentBookingFlights.map((c) => {
            return { label: c.name, value: c.id };
          })
        )}
        block
        noSearch
        value={model.agentAmadusId}
        onSelect={(agentAmadusId) => {
          _setmodel((prev) => {
            return { ...prev, agentAmadusId };
          });
        }} * /> */}
    </>
  );
};

const TicketAddEdit = ({ model = new AddTicketModel(), setmodel, agents }) => (
  <>
    <Responsive m={6} l={4} xl={4} className="p-10">
      <label>N Ticket : </label>
      <Input
        type="number"
        value={model.docNumber}
        onChange={(docNumber) => setmodel({ ...model, docNumber })}
      />
    </Responsive>

    <Responsive s={6} m={6} l={2} xl={2} className="p-10">
      <label>PNR:</label>
      <Input
        value={model.pnr}
        onChange={(pnr) => setmodel({ ...model, pnr })}
      />
    </Responsive>

    <Responsive s={6} m={6} l={6} xl={6} className="p-10">
      <label>Date D'emmisssion:</label>
      <Input
        type="date"
        value={model.doEmmission}
        onChange={(doEmmission) => setmodel({ ...model, doEmmission })}
      />
    </Responsive>

    <Responsive s={6} m={6} l={6} xl={6} className="p-10">
      <label>Status:</label>
      <SelectPicker
        data={ticketType.filter(
          (el) => el.value == "TKTT" || el.value == "EMDS"
        )}
        block
        noSearch
        value={model.tranc}
        onChange={(tranc) =>
          setmodel({
            ...model,
            tranc,
            totalTTC: 0,
            emd: 0,
            serviceFees: 0,
            servideFeesTVA: 0,
            fare: 0,
          })
        }
      />
    </Responsive>
    {/* {model.tranc == "RFND" && (
      <Responsive s={6} m={6} l={6} xl={6} className="p-10">
        <label>Date de remboursement:</label>
        <Input
          type="date"
          value={model.refundDate}
          onChange={(refundDate) => setmodel({ ...model, refundDate })}
        />
      </Responsive>
    )} */}
    <h4>Prix</h4>
    {model.tranc == "EMDS" ? (
      <Responsive s={6} m={6} l={3} xl={3} className="p-10">
        <label>Montant Total EMD:</label>
        <Input
          type="number"
          step="0.1"
          value={model.totalTTC}
          onChange={(totalTTC) => {
            setmodel((prev) => {
              return {
                ...prev,
                totalTTC: parseFloat(totalTTC),
              };
            });
          }}
        />
      </Responsive>
    ) : (
      <>
        {" "}
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Prix de base:</label>
          <Input
            type="number"
            step="0.1"
            value={model.fare}
            onChange={(fare) => {
              setmodel((prev) => {
                return {
                  ...prev,
                  fare: parseFloat(fare),
                  totalTTC:
                    parseFloat(fare) +
                    parseFloat(prev.emd) +
                    parseFloat(prev.tax) +
                    parseFloat(prev.serviceFees),
                };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Frais de service :</label>
          <Input
            type="number"
            step="0.1"
            value={model.serviceFees}
            onChange={(serviceFees) => {
              setmodel((prev) => {
                return {
                  ...prev,
                  serviceFees: parseFloat(serviceFees),
                  serviceFeesTVA: parseFloat(serviceFees * 0.19),

                  totalTTC:
                    parseFloat(prev.fare) +
                    parseFloat(prev.emd) +
                    parseFloat(prev.tax) +
                    parseFloat(serviceFees),
                };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>EMD :</label>
          <Input
            type="number"
            step="0.1"
            value={model.emd}
            onChange={(emd) => {
              setmodel((prev) => {
                return {
                  ...prev,
                  emd: parseFloat(emd),

                  totalTTC:
                    parseFloat(prev.fare) +
                    parseFloat(emd) +
                    parseFloat(prev.tax) +
                    parseFloat(prev.serviceFees),
                };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>TVA (Frais de service) :</label>
          <Input
            type="number"
            step="0.1"
            value={model.serviceFeesTVA}
            disabled
          />
        </Responsive>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Taxes :</label>
          <Input
            type="number"
            step="0.1"
            value={model.tax}
            onChange={(tax) => {
              setmodel((prev) => {
                return {
                  ...prev,
                  tax: parseFloat(tax),
                  totalTTC:
                    parseFloat(prev.fare) +
                    parseFloat(prev.emd) +
                    parseFloat(prev.serviceFees) +
                    parseFloat(prev.serviceFeesTVA) +
                    parseFloat(tax),
                };
              });
            }}
          />
        </Responsive>
        {/* <Responsive s={6} m={6} l={2} xl={2} className="p-10">
        <label>Quantité:</label>
        <Input
          disabled={model.service == 1}
          type="number"
          step="1"
          value={model.qty}
          onChange={(qty) => {
            setmodel((prev) => {
              return { ...prev, qty };
            });
          }}
        />
      </Responsive> */}
        <Responsive className="p-10">
          <label>Prix Total:</label>
          <div>
            {" "}
            <b style={{ color: "darkcyan", fontSize: "25px" }}>
              {model.totalTTC ? model.totalTTC.toFixed(3) : 0}
            </b>
          </div>
        </Responsive>
      </>
    )}
    {/* {model.tranc == "RFND" && (
      <>
        <h6>Remboursement</h6>

        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Frais de service :</label>
          <Input
            type="number"
            step="0.1"
            value={model.commissionRefund}
            onChange={(commissionRefund) => {
              setmodel((prev) => {
                return {
                  ...prev,
                  commissionRefund: parseFloat(commissionRefund),
                  totalRefund: parseFloat(
                    prev.totalTTC -
                      prev.refundTax -
                      commissionRefund -
                      prev.refundPenalty
                  ),
                };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Penalité Remboursement :</label>
          <Input
            type="number"
            step="0.1"
            value={model.refundPenalty}
            onChange={(refundPenalty) => {
              setmodel((prev) => {
                return {
                  ...prev,
                  refundPenalty: parseFloat(refundPenalty),
                  totalRefund: parseFloat(
                    prev.totalTTC -
                      prev.refundTax -
                      refundPenalty -
                      prev.commissionRefund
                  ),
                };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={3} xl={3} className="p-10">
          <label>Taxe Remboursement :</label>
          <Input
            type="number"
            step="0.1"
            value={model.refundTax}
            onChange={(refundTax) => {
              setmodel((prev) => {
                return {
                  ...prev,
                  refundTax: parseFloat(refundTax),
                  totalRefund: parseFloat(
                    prev.totalTTC -
                      prev.refundPenalty -
                      refundTax -
                      prev.commissionRefund
                  ),
                };
              });
            }}
          />
        </Responsive>
        <Responsive s={6} m={6} l={4} xl={4} className="p-10">
          <label>Montant Total à rembourser:</label>
          <div>
            <b style={{ color: "darkcyan", fontSize: "25px" }}>
              {(
                model.totalTTC -
                model.refundPenalty -
                model.refundTax -
                model.commissionRefund
              ).toFixed(3)}
            </b>
          </div>
        </Responsive>
      </>
    )} */}
  </>
);
