import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DatePicker, Input, SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import TextImageIcon from "@rsuite/icons/TextImage";
import { serviceTypes } from "../../Constants/types";
import validate from "../../Helpers/validate";
import AddEdit from "./AddEdit.component";
import { multiDataSet } from "./excel_data";
import moment from "moment";

import { agencyAtom } from "../../Atoms/agency.atom";
import PackageModel from "../../Models/Package.model";
import { PackageAtom } from "../../Atoms/Package.atom";
export default function Packages(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [providers, setproviders] = useState([]);
  const agency = useRecoilValue(agencyAtom);

  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,

    date: null,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(PackageAtom);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new PackageModel());
    setError("");
  };
  // API CALLS

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };

  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.Package, {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    let m = _formatModel(model);

    console.log(m);
    // m.disponibilities = m.disponibilities.map((item) => {
    //   return { ...item, date: item.date.substring(0, 10) };
    // });
    if (m.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Package)
        .update(m.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else
      APi.createAPIEndpoint(APi.ENDPOINTS.Package)
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
  };

  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Package)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  //
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.Package)
      .fetchById(id)
      .then((res) => {
        let m = { ...res.data };
        if (m.disponibilities)
          m.disponibilities = m.disponibilities.map((item) => {
            return { ...item, date: item.date.substring(0, 10) };
          });
        if (m.packageProviders) {
          m.providers1 = m.packageProviders
            .filter((pp) => pp.hotels)
            .map((el) => ({ ...el.provider, hotels: el.hotels }));
          m.providers2 = m.packageProviders
            .filter((pp) => !pp.hotels)
            .map((el) => el.provider);
          console.log(m.providers1);
          console.log(m.providers2);
          m.prices = m.prices.map((el) => {
            if (el.costs) {
              el.costs = el.costs.map((el1) => {
                el1.provider = m.packageProviders.find(
                  (p) => p.providerId == el1.providerId
                )
                  ? m.packageProviders.find(
                      (p) => p.providerId == el1.providerId
                    ).provider
                  : {};
                return el1;
              });
            }
            return el;
          });
        }
        setmodel(m);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // LIFE CYCLES
  useEffect(() => {
    fetchProviders();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter search={() => fetch()}>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Période: </label>
          {/* <DatePicker
            placement="bottomStart"
            value={filterModel.date}
            onChange={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            onSelect={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            block
          /> 
        </Responsive> */}

        <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Date : </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        ActionOnClose={reset}
        excelData={multiDataSet(data)}
        nameExcel="packages"
        size="xl"
        save={save}
        AddComponent={
          <AddEdit
            providers={providers}
            fetchProviders={fetchProviders}
            error={error}
          />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "id",
    name: "Identifiant",
    render: (v) => <h5>{v}</h5>,
  },
  {
    value: "name",
    name: "Nom",
    render: (v) => <a>{v ? v.toUpperCase() : ""}</a>,
  },

  {
    value: "note",
    name: "Notes",
    render: (v) => {
      return (
        <div
          style={{
            maxWidth: "260px",
            padding: "10px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {v}
        </div>
      );
    },
  },
];

const _formatModel = (model) => {
  let m = { ...model };
  // m.providers1 = m.providers1 ? m.providers1.map((p) => ({ id: p.id })) : [];
  // m.providers2 = m.providers2 ? m.providers2.map((p) => ({ id: p.id })) : [];
  m.prices = m.prices
    ? m.prices.map((p) => {
        let _p = { ...p };
        _p.expense = parseFloat(_p.expense);
        _p.price = parseFloat(_p.price);
        _p.BrancheCommission = parseFloat(_p.BrancheCommission);
        _p.B2BCommission = parseFloat(_p.B2BCommission);
        let costs = _p.costs
          ? [...p.costs].map((c) => {
              let _c = { ...c };
              _c.providerId = c.provider.id;
              delete _c.provider;

              return {
                ..._c,
                cost: parseFloat(c.cost),
                exchange: parseFloat(c.exchange),
              };
            })
          : [];

        return { ..._p, costs };
      })
    : [];
  return m;
};
