import React, { useEffect, useState } from "react";
import { IconButton, Input, TagInput, Uploader } from "rsuite";
import Responsive from "../../Components/Responsive";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";

import ImageIcon from "@rsuite/icons/Image";
import { useRecoilState } from "recoil";
import { agencyAtom } from "../../Atoms/agency.atom";
import { BASE_URL_INVOICE } from "../../Config/api.invoice";
import { InvoiceService } from "../../Api/invoice.api";
function Invoice() {
  const [model, _setmodel] = useRecoilState(agencyAtom);
  const [logo, setlogo] = useState(null);
  const [file, setfile] = useState(null);
  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }
  const save = () => {
    let data = new FormData();
    let d = { ...model };
    delete d.logo;
    Object.keys(d).map((el) => {
      data.append(el, d[el]);
    });
    if (file) {
      console.log(file);

      data.append("logo", file);
    }
    if (d.id) {
      InvoiceService()
        .update_agency(data)
        .then((res) => console.log(res));
    } else {
      InvoiceService()
        .create_agency(data)
        .then((res) => console.log(res));
    }
  };
  return (
    <div style={{ padding: "5px", background: "white" }}>
      <h5>Les informations de l'agence pour la facture:</h5>
      <div style={{ width: "100px", margin: "15px" }}>
        <Uploader
          // listType="picture"
          autoUpload={false}
          action="#"
          draggable
          fileListVisible={false}
          // onUpload={}
          onChange={(files) => {
            console.log(files);
            setfile(files[files.length - 1].blobFile);
            previewFile(files[files.length - 1].blobFile, (value) => {
              setlogo(value);
            });
          }}
        >
          <div
            style={{
              background: "rgba(200,200,200,0.1)",
              width: "100px",
              height: "100px",
              padding: "0",
            }}
          >
            {logo ? (
              <img src={logo} width="100" height="100" alt="" />
            ) : model.logo ? (
              <img
                width="100"
                height="100"
                src={
                  BASE_URL_INVOICE + model.logo.substring(7)
                  //   "http://localhost:3003/images/sasasd.png"
                }
                alt=""
              />
            ) : (
              <>
                {" "}
                <div style={{ fontSize: "40px" }}>
                  {" "}
                  <ImageIcon color="#3598ff"></ImageIcon>
                </div>
                <h4 style={{ color: "#bbb", fontWeight: "400" }}>
                  LOGO
                  {/* pour les télécharger */}
                </h4>
              </>
            )}
          </div>
        </Uploader>
      </div>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Nom:</label>
        <Input
          value={model.name}
          onChange={(name) => {
            _setmodel((prev) => {
              console.log(prev);
              return { ...prev, name };
            });
          }}
        />
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Matricule Fiscale :</label>
        <Input
          value={model.MF}
          onChange={(MF) => {
            _setmodel((prev) => {
              return { ...prev, MF };
            });
          }}
        />
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Addresse :</label>
        <Input
          value={model.address}
          onChange={(address) => {
            _setmodel((prev) => {
              return { ...prev, address };
            });
          }}
        />
      </Responsive>
      <Responsive m={6} l={4} xl={5} className="p-10">
        <label>Ville:</label>
        <Input
          value={model.city}
          onChange={(city) => {
            _setmodel((prev) => {
              return { ...prev, city };
            });
          }}
        />
      </Responsive>
      <Responsive m={6} l={2} xl={1} className="p-10">
        <label>Zip Code :</label>
        <Input
          value={model.pincode}
          onChange={(pincode) => {
            _setmodel((prev) => {
              return { ...prev, pincode };
            });
          }}
        />
      </Responsive>

      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Télephones</label>
        <TagInput
          type="number"
          block
          size="md"
          // placeholder="numéros des télephones"
          value={model.phone ? model.phone.split(",") : []}
          onChange={(phone) => {
            let m = { ...model };
            m.phone = phone.join(",");
            _setmodel(m);
          }}
        />
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Fax:</label>
        <TagInput
          type="number"
          block
          size="md"
          //   placeholder="emails "
          value={model.fax ? model.fax.split(",") : []}
          onChange={(fax) => {
            let m = { ...model };

            m.fax = fax.join(",");
            _setmodel(m);
          }}
        />{" "}
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>emails:</label>
        <TagInput
          type="number"
          block
          size="md"
          //   placeholder="emails "
          value={model.emails ? model.emails.split(",") : []}
          onChange={(emails) => {
            let m = { ...model };

            m.emails = emails.join(",");
            _setmodel(m);
          }}
        />{" "}
      </Responsive>
      <div style={{ textAlign: "right" }}>
        <IconButton
          appearance="primary"
          onClick={save}
          color="blue"
          icon={<CreditCardPlusIcon />}
        >
          {model.id ? "modifier" : "Ajouter"}
        </IconButton>
      </div>
    </div>
  );
}

export default Invoice;
