import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { SelectPicker, Tag } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import AddEdit from "./AddEdit.component";

import { ForcastAtom, ForcastVoAtom } from "../../Atoms/forcast.atom";
import ForcastModel, { ForcastVoModel } from "../../Models/ForcastModel";
import { multiDataSet } from "./excel_data";
export default function ForcastVo(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [clients, setclients] = useState([]);
  const [clients2, setclients2] = useState([]);
  const [dataExcel, setdataExcel] = useState([]);

  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    providerId: 0,
    status: 0,
    date: null,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(ForcastVoAtom);
  const [packages, setpackages] = useState([]);
  const [vols, setvols] = useState([]);
  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new ForcastVoModel());
    setError("");
  };
  // API CALLS
  const fetchPackages = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Package, { q }, "")
        .customGet()
        .then((res) => {
          setpackages(res.data.data);
          setvols(
            res.data.data.reduce((a, b) => a.concat(b.disponibilities), [])
          );
        });
    }
  };
  const groupped_by = (arr) => {
    let _data = [];
    let _vols = arr.map((el) => el.disponibility);
    let _arr = arr.map((el) => {
      let re = [];
      el.clients.forEach((c) => {
        let __o = el.prices.map((p) => {
          return {
            disponibilityId: el.disponibilityId,
            disponibility: el.disponibility,
            client: c.client,
            clientId: c.clientId,
            nbr: c.nbr,
            designation:
              p.priceUmrah.hotelMadinahName +
              " - " +
              p.priceUmrah.hotelMakkahName,
          };
        });

        __o = __o.filter((value, index) => {
          const _value = JSON.stringify(value);
          return (
            index ===
            __o.findIndex((obj) => {
              return JSON.stringify(obj) === _value;
            })
          );
        });
        let __o2 = { ...__o[0] };
        __o.forEach((element) => {
          __o2.designation = __o2.designation + " / " + element.designation;
        });
        re.push(__o2);
      });
      return re;
    });
    _arr = _arr.reduce((acc, el) => acc.concat(el), []);
    let eles = new Set(_arr.map((el) => el.disponibilityId));
    let res = [];
    eles.forEach((el) => {
      res.push({
        disponibilityId: el,
        disponibility: _arr.filter((____el) => ____el.disponibilityId == el)[0]
          .disponibility,
        items: _arr.filter((____el) => ____el.disponibilityId == el),
      });
    });
    console.log(res);

    setdataExcel(res);
  };
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) =>
          forFilter ? setclients(res.data) : setclients2(res.data)
        );
    }
  };
  const fetch = () => {
    setstate((prev) => {
      return { ...prev, loading: true };
    });
    APi.createAPIEndpoint(APi.ENDPOINTS.ForcastVo, {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        groupped_by(res.data.data.filter((el) => el.prices.length));
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    // let msg = validate(model, [
    //   { number: "Numéro" },
    //   { status: "Status" },
    //   { amount: "Montant" },
    //   { depositDate: "Date de Dépôt" },
    //   // { date: "Date" },

    //   { checkPurpose: "Type de Chéque" },
    //   // { clientId: "Client" },
    // ]);
    // if (msg) setError(msg);
    // else {
    console.log(model);

    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = {
      ...model,
    };
    m.clients = m.clients.map((c) => {
      let _c = { ...c };
      delete _c.client;
      return _c;
    });
    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.ForcastVo)
        .update(m.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      console.log(m);

      APi.createAPIEndpoint(APi.ENDPOINTS.ForcastVo)
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
    // }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.ForcastVo)
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  //
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.ForcastVo)
      .fetchById(id)
      .then((res) => {
        // if (
        //   res.data.client &&
        //   !clients2.find((c) => c.id == res.data.client.id)
        // ) {
        //   let arr = [...clients2];
        //   arr.unshift({ id: res.data.client.id, name: res.data.client.name });
        //   setclients2(arr);
        // }

        setmodel({
          ...res.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // LIFE CYCLES
  useEffect(() => {
    fetchClients();
    fetchPackages();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  const columns = [
    {
      value: "disponibility",
      name: "Vol",
      render: (c) => (
        <b
          style={{
            display: "flex",
            alignItems: "center",
            padding: "3px",

            borderRadius: "5px",
          }}
        >
          <img
            alt=""
            style={{ width: "30px", height: "30px", margin: "0 6px" }}
            src={c.airline ? c.airline.logo : ""}
          ></img>
          <b>
            {" "}
            <div style={{ color: "#232323" }}>
              {moment(c.date).format("DD MMM YYYY")}
            </div>
            <b style={{ color: "#888", fontWeight: "lighter" }}>
              {" "}
              {c.airline ? c.airline.name : ""} ({" "}
              <i style={{ color: "#999" }}>
                {packages &&
                  packages.find((l) => l.id == c.packageId) &&
                  packages.find((l) => l.id == c.packageId).name}
              </i>{" "}
              )
            </b>
          </b>
          <b className="p-5">
            <span
              style={{
                background: "#121238",
                color: "#fff",
                width: "30px",
                height: "30px",
                textAlign: "center",
                lineHeight: "30px",
                display: "inline-block",
              }}
            >
              {c.onStock}
            </span>
          </b>
        </b>
      ),
    },

    {
      value: "virtualStock",

      name: "Nombre De Prévision",
      render: (v) => <b style={{ color: "#53af50", fontSize: "18px" }}>{v}</b>,
    },
    // {
    //   value: "services",
    //   name: "Services",
    //   render: (v) => (
    //     <>
    //       {serviceTypes
    //         .filter((el) => v.includes(el.value))
    //         .map((s) => (
    //           <Tag color="violet">{s.label}</Tag>
    //         ))}
    //     </>
    //   ),
    // },
    {
      value: "optionDate",
      name: "Date D'option",
      render: (v) => <strong>{moment(v).format("DD MMM YYYY")}</strong>,
    },
    {
      value: "note",
      name: "Notes",
      render: (v) => {
        return (
          <div
            style={{
              maxWidth: "260px",
              padding: "10px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {v}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Filter search={() => fetch()}>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Période: </label>
          {/* <DatePicker
            placement="bottomStart"
            value={filterModel.date}
            onChange={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            onSelect={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            block
          /> 
        </Responsive> */}
        <Responsive className="p-10">
          <label>Nom Voyage: </label>
          <SelectPicker
            // onSearch={fetchPackages}
            data={
              packages && packages
                ? [{ label: "Tout", value: 0 }].concat(
                    packages.map((el) => {
                      return { label: el.name, value: el.id };
                    })
                  )
                : [{ label: "Tout", value: 0 }]
            }
            block
            value={filterModel.packageId}
            onSelect={(packageId) => {
              setfilterModel((prev) => {
                return { ...prev, packageId };
              });
            }}
          />
        </Responsive>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Status: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(checkStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive> */}
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Type de Paiement: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(paymentType)}
            block
            noSearch
            value={filterModel.type}
            onSelect={(type) => {
              setfilterModel((prev) => {
                return { ...prev, type };
              });
            }}
          />
        </Responsive> 
          </Responsive> */}
      </Filter>
      <ExportAdd
        excelData={
          dataExcel && dataExcel.length
            ? multiDataSet(dataExcel)
            : multiDataSet([])
        }
        nameExcel={
          packages && packages.find((el) => filterModel.packageId == el.id)
            ? packages.find((el) => filterModel.packageId == el.id).name
            : "forcast"
        }
        size="lg"
        save={save}
        ActionOnClose={reset}
        AddComponent={
          <AddEdit
            vols={vols}
            packs={packages}
            fetchClients={(q) => fetchClients(q, false)}
            clients={clients2}
            error={error}
          />
        }
      />{" "}
      <Grid
        editAction={(id) => {
          getBYId(id);
          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        noAdvancedActions // for custom advanced actions
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}
